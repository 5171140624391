import React from "react";
import close from "../../../include/images/close-12x12.svg";
import { Modal } from "react-bootstrap";
import NextButton from "../../../common/form/nextButton";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";

const EditAviationDetails = (props) => {
  const customStyles1 = {
    indicatorSeparator: (styles) => ({ display: "none" }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "#47ad1d",
        color: "#F5F5F5",
      },
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      color: state.isSelected ? "#fff" : "black",
      backgroundColor: state.isSelected ? "#47ad1d" : provided.backgroundColor,
      // maxHeight: "84px",
    }),
    control: (base, state) => ({
      ...base,
      minHeight: 42,
      background: state.isDisabled ? "#e9ecef" : "#f5f5f5",
      borderColor: state.isFocused ? "#47AD1D" : "#e4e4e4",
      boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
      border: state.isHovered ? "0" : "0px solid #e4e4e4",
      color: state.isDisabled ? "black" : "#f5f5f5",

      "&:hover": {
        borderColor: state.isFocused ? "#47AD1D" : "0",
        boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
      },
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#6119c0",
      },
    }),
  };

  const booleanOptions = [
    { name: "Yes", value: true },
    { name: "No", value: false },
  ];

  const onChange = (e) => {
    const data = { ...props.data };
    data[e.target.name] = e.target.value;
    props.setData(data);
  };

  //state
  // const [condition]

  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide()}
      className="modal fade salvex-modal2"
      id="aviationParts"
      dialogClassName="modal-dialog  modal-xl modal-dialog-centered"
      contentClassName="modal-content"
    >
      <div class="custom-modal-header d-flex align-items-center justify-content-between">
        <h5 class="mb-0 text-capitalize" style={{ marginLeft: "8px" }}>
          Edit Part Details
        </h5>
        <button
          type="button"
          class="close-btn d-flex align-items-center justify-content-center"
          onClick={() => props.onHide()}
        >
          <img src={close} alt="" />
        </button>
      </div>

      {props?.isAviation ? (
        <Modal.Body className="modal-body aviation-view-body">
          <div class="modal-heading"></div>
          <div>
            <div className="d-flex align-items-center aviation-view-modal">
              <span className="ms-2">Part Number</span>
              <span style={{ marginLeft: "0px" }}>Part Description</span>
            </div>
            <div className="d-flex align-items-center  aviation-view-modal1" style={{ marginRight: "5px" }}>
              <span>
                <input
                  className="form-control"
                  name="partNumber"
                  style={{ width: "275px" }}
                  value={props.data?.partNumber}
                  onChange={(e) => onChange(e)}
                />
              </span>
              <span className="ms-1">
                <input
                  className="form-control"
                  style={{ width: "550px" }}
                  name="partDescription"
                  value={props.data?.partDescription}
                  onChange={(e) => onChange(e)}
                />
              </span>
            </div>
          </div>

          <div>
            <div className="d-flex align-items-center aviation-view-modal">
              <span>Condition</span>
              <span>QTY</span>
              <span>Unit Price</span>
            </div>
            <div className="d-flex align-items-center  aviation-view-modal1">
              <span>
                <Select
                  value={props?.condition?.find((val) => val.value === props.data?.condition)}
                  options={props.condition}
                  className="basic-single country-margin select-background "
                  classNamePrefix="select-search country-margin select-background"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    const data = { ...props.data };
                    data["condition"] = e;
                    props.setData(data);
                  }}
                  name="condition"
                  placeholder="Select Condition"
                  styles={customStyles}
                />
              </span>
              <span>
                <input
                  className="form-control"
                  name="quantity"
                  value={props.data?.quantity}
                  onChange={(e) => onChange(e)}
                />
              </span>
              <span>
                <input
                  className="form-control"
                  type="text"
                  name="unitPrice"
                  value={props.data?.unitPrice}
                  onChange={(e) => onChange(e)}
                />
              </span>

              {/* <span>
              <Select
                value={props?.location?.find((val) => val.value === props.data?.location)}
                options={[...props.location]}
                className="basic-single country-margin select-background "
                classNamePrefix="select-search country-margin select-background"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                onChange={(e) => {
                  const data = { ...props.data };
                  data["location"] = e;
                  props.setData(data);
                }}
                name="location"
                placeholder="Select Location"
                styles={customStyles}
              />
            </span> */}
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center aviation-view-modal">
              <span>Location</span>
              <span>Certificate</span>
              <span>Plane Manufacturer</span>
            </div>
            <div className="d-flex align-items-center  aviation-view-modal1">
              <span>
                {/* <input
                className="form-control"
                type="text"
                name="location"
                value={props.data?.location}
                onChange={(e) => onChange(e)}
              /> */}
                <Select
                  value={props?.locations?.find((val) => val.value === props.data?.location)}
                  options={props?.locations}
                  className="basic-single country-margin select-background "
                  classNamePrefix="select-search country-margin select-background"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    const data = { ...props.data };
                    data["location"] = e.value;
                    props.setData(data);
                  }}
                  name="location"
                  placeholder="Select Location"
                  styles={customStyles}
                />
              </span>

              <span>
                <Select
                  value={booleanOptions?.find((val) => val.value === props.data?.certificate)}
                  options={[...booleanOptions]}
                  className="basic-single country-margin select-background "
                  classNamePrefix="select-search country-margin select-background"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    const data = { ...props.data };
                    data["certificate"] = e;
                    props.setData(data);
                  }}
                  name="certificate"
                  placeholder="Select Insurance"
                  styles={customStyles}
                />
              </span>
              <span>
                {/* <input
                className="form-control"
                type="text"
                name="planeManufacturer"
                value={props.data?.planeManufacturer}
                onChange={(e) => onChange(e)}
              /> */}
                <Select
                  value={props?.mfr?.find((val) => val.value === props.data?.planeManufacturer)}
                  options={props?.mfr}
                  className="basic-single country-margin select-background "
                  classNamePrefix="select-search country-margin select-background"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    const data = { ...props.data };
                    data["planeManufacturer"] = e.value;
                    props.setData(data);
                  }}
                  name="planeManufacturer"
                  placeholder="Select Plane Manufacturer"
                  styles={customStyles}
                />
              </span>
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center aviation-view-modal">
              <span>Part Manufacturer</span>
              <span>Part Type</span> <span>Plane Model</span>
            </div>
            <div className="d-flex align-items-center  aviation-view-modal1">
              <span>
                {/* <input
                className="form-control"
                type="text"
                name="partManufacturer"
                value={props.data?.partManufacturer}
                onChange={(e) => onChange(e)}
              /> */}
                <Select
                  value={props?.partMfr?.find((val) => val.value === props.data?.partManufacturer)}
                  options={props?.partMfr}
                  className="basic-single country-margin select-background "
                  classNamePrefix="select-search country-margin select-background"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    const data = { ...props.data };
                    data["partManufacturer"] = e.value;
                    props.setData(data);
                  }}
                  name="partManufacturer"
                  placeholder="Select Part Manufacturer"
                  styles={customStyles}
                />
              </span>
              <span>
                {/* <input
                className="form-control"
                type="text"
                name="partType"
                value={props.data?.partType}
                onChange={(e) => onChange(e)}
              /> */}
                <Select
                  value={props?.part?.find((val) => val.value === props.data?.partType)}
                  options={props?.part}
                  className="basic-single country-margin select-background "
                  classNamePrefix="select-search country-margin select-background"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    const data = { ...props.data };
                    data["partType"] = e.value;
                    props.setData(data);
                  }}
                  name="partType"
                  placeholder="Select Part Type"
                  styles={customStyles}
                />
              </span>
              <span>
                {/* <input
                className="form-control"
                type="text"
                name="planeModels"
                value={props.data?.planeModels}
                onChange={(e) => onChange(e)}
              /> */}
                <Select
                  // value={props?.model?.find((val) => val.value === props.data?.planeModels)}
                  value={props?.model?.filter((it) => props?.data?.planeModels?.includes(it.value))}
                  options={props?.model}
                  className="basic-single country-margin select-background "
                  classNamePrefix="select-search country-margin select-background"
                  isMulti
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    const data = { ...props.data };
                    data["planeModels"] = e?.map((i) => i.name);
                    props.setData(data);
                  }}
                  name="planeModels"
                  placeholder="Select Plane Models"
                  styles={customStyles1}
                />
              </span>
            </div>
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body className="modal-body aviation-view-body">
          <div class="modal-heading"></div>
          <div>
            <div className="d-flex align-items-center aviation-view-modal">
              <span className="ms-2">Part Number</span>
              <span style={{ marginLeft: "0px" }}>Part Description</span>
            </div>
            <div className="d-flex align-items-center  aviation-view-modal1" style={{ marginRight: "5px" }}>
              <span>
                <input
                  className="form-control"
                  name="partNumber"
                  style={{ width: "275px" }}
                  value={props.data?.partNumber}
                  onChange={(e) => onChange(e)}
                />
              </span>
              <span className="ms-1">
                <input
                  className="form-control"
                  style={{ width: "550px" }}
                  name="partDescription"
                  value={props.data?.partDescription}
                  onChange={(e) => onChange(e)}
                />
              </span>
            </div>
          </div>

          <div>
            <div className="d-flex align-items-center aviation-view-modal">
              <span>Condition</span>
              <span>QTY</span>
              <span>Unit</span>
            </div>
            <div className="d-flex align-items-center  aviation-view-modal1">
              <span>
                <Select
                  value={props?.condition?.find((val) => val.value === props.data?.condition)}
                  options={props.condition}
                  className="basic-single country-margin select-background "
                  classNamePrefix="select-search country-margin select-background"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    const data = { ...props.data };
                    data["condition"] = e;
                    props.setData(data);
                  }}
                  name="condition"
                  placeholder="Select Condition"
                  styles={customStyles}
                />
              </span>
              <span>
                <input
                  className="form-control"
                  name="quantity"
                  value={props.data?.quantity}
                  onChange={(e) => onChange(e)}
                />
              </span>
              <span>
                <input
                  className="form-control"
                  type="text"
                  name="unit"
                  value={props.data?.unit}
                  onChange={(e) => onChange(e)}
                />
              </span>
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center aviation-view-modal">
              <span>Unit Price</span>
              <span>Location</span>
              <span>Part Manufacturer</span>
            </div>
            <div className="d-flex align-items-center  aviation-view-modal1">
              <span>
                <input
                  className="form-control"
                  type="text"
                  name="unitPrice"
                  value={props.data?.unitPrice}
                  onChange={(e) => onChange(e)}
                />
              </span>
              <span>
                <Select
                  value={props?.locations?.find((val) => val.value === props.data?.location)}
                  options={props?.locations}
                  className="basic-single country-margin select-background "
                  classNamePrefix="select-search country-margin select-background"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    const data = { ...props.data };
                    data["location"] = e.value;
                    props.setData(data);
                  }}
                  name="location"
                  placeholder="Select Location"
                  styles={customStyles}
                />
              </span>
              <span>
                <Select
                  value={props?.partMfr?.find((val) => val.value === props.data?.partManufacturer)}
                  options={props?.partMfr}
                  className="basic-single country-margin select-background "
                  classNamePrefix="select-search country-margin select-background"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    const data = { ...props.data };
                    data["partManufacturer"] = e.value;
                    props.setData(data);
                  }}
                  name="partManufacturer"
                  placeholder="Select Part Manufacturer"
                  styles={customStyles}
                />
              </span>
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center aviation-view-modal">
              <span>Part Model</span>
              <span>Grade</span>
              <span>Serial Number</span>
            </div>
            <div className="d-flex align-items-center  aviation-view-modal1">
              <span>
                <Select
                  value={props?.model?.filter((it) => props?.data?.partModels?.includes(it.value))}
                  options={props?.model}
                  className="basic-single country-margin select-background "
                  classNamePrefix="select-search country-margin select-background"
                  isMulti
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    const data = { ...props.data };
                    data["partModels"] = e?.map((i) => i.name);
                    props.setData(data);
                  }}
                  name="partModels"
                  placeholder="Select Part Models"
                  styles={customStyles1}
                />
              </span>
              <span>
                <input className="form-control" name="grade" value={props.data?.grade} onChange={(e) => onChange(e)} />
              </span>
              <span>
                <input
                  className="form-control"
                  name="serialNumber"
                  value={props.data?.serialNumber}
                  onChange={(e) => onChange(e)}
                />
              </span>
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center aviation-view-modal">
              <span>AI Product Description</span>
            </div>
            <div className="d-flex align-items-center  aviation-view-modal1 mb-4">
              <textarea
                className="form-control"
                name="aiProductDescription"
                value={props?.data?.aiProductDescription}
                onChange={(e) => onChange(e)}
                rows="3"
              />
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center aviation-view-modal">
              <span>AI Manufacture Description</span>
            </div>
            <div className="d-flex align-items-center  aviation-view-modal1 mb-4">
              <textarea
                className="form-control"
                name="aiManufactureDescription"
                value={props?.data?.aiManufactureDescription}
                onChange={(e) => onChange(e)}
                rows="3"
              />
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center aviation-view-modal">
              <span>Notes 1</span>
            </div>
            <div className="d-flex align-items-center  aviation-view-modal1 mb-4">
              <textarea
                className="form-control"
                name="notes1"
                value={props?.data?.notes1}
                onChange={(e) => onChange(e)}
                rows="3"
              />
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center aviation-view-modal">
              <span>Notes 2</span>
            </div>
            <div className="d-flex align-items-center  aviation-view-modal1 mb-4">
              <textarea
                className="form-control"
                name="notes2"
                value={props?.data?.notes2}
                onChange={(e) => onChange(e)}
                rows="3"
              />
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center aviation-view-modal">
              <span>Notes 3</span>
            </div>
            <div className="d-flex align-items-center  aviation-view-modal1 mb-4">
              <textarea
                className="form-control"
                name="notes3"
                value={props?.data?.notes3}
                onChange={(e) => onChange(e)}
                rows="3"
              />
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center aviation-view-modal">
              <span>Notes 4</span>
            </div>
            <div className="d-flex align-items-center  aviation-view-modal1 mb-4">
              <textarea
                className="form-control"
                name="notes4"
                value={props?.data?.notes4}
                onChange={(e) => onChange(e)}
                rows="3"
              />
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center aviation-view-modal">
              <span>Notes 5</span>
            </div>
            <div className="d-flex align-items-center  aviation-view-modal1 mb-4">
              <textarea
                className="form-control"
                name="notes5"
                value={props?.data?.notes5}
                onChange={(e) => onChange(e)}
                rows="3"
              />
            </div>
          </div>
        </Modal.Body>
      )}
      <div class="custom-modal-dividor"></div>
      <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
        <button class="btn btn-link me-md-2 global-cancel-button" type="button" onClick={() => props.onHide()}>
          Cancel
        </button>
        <NextButton
          classData="btn btn-default"
          handleSubmit={() => props.handleEdit()}
          label={"Save"}
          loading={props.loadingEdit}
        />
      </div>
    </Modal>
  );
};

export default EditAviationDetails;
