import React, { Component, useEffect, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import Header from "../layouts/header";
import searchIcon from "../include/images/sw-search.svg";
import sendIcon from "../include/images/send.svg";
import useWebSocket, { ReadyState } from "react-use-websocket";

import { addChat, getChats, loadChatList, loadChatMassages, updateUnreadCount } from "../store/chats";
import { connect } from "react-redux";
import moment from "moment";
import toggleIcon from "../include/images/v-toggle-icon.svg";
import { getProfile } from "../utils.js/localStorageServices";
import Skeleton from "react-loading-skeleton";
import { getStates } from "../store/productLocation";
import Sidebar from "../layouts/sidebar";
import paperClip from "../include/images/paperclip.svg";
import { Amplify, Storage } from "aws-amplify";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import AlertError from "../common/alerts/alertError";
import pdfIcon from "../include/images/pdf-file-icon.svg";
import csvFile from "../include/images/csv-latest.svg";
import XLSX from "../include/images/XLSX.svg";
import XLS from "../include/images/XLS.svg";
import mp4 from "../include/images/mp4.svg";
import downloadIcon from "../include/images/download-circle-icon.svg";
import fileIcon from "../include/images/file.svg";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET,
    region: "us-east-1",
  },
});

const Chats = (props) => {
  const ref = React.createRef();
  const [loading, setLoading] = React.useState(false);
  const [chat, setChat] = React.useState({});
  const [message, setMessage] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [messageLoading, setMessageLoading] = React.useState(false);
  const [media, setMedia] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [loadingMessage, setLoadingMessage] = React.useState(false);

  //For SideNav
  const [showMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState("");

  const member = getProfile();

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `${process.env.REACT_APP_WS_URL}?token=${btoa(`${member.user_id}:admin`)}`,
    { shouldReconnect: (closeEvent) => true },
  );

  // ...
  const pasteImg = async () => {
    try {
      const clipboardItems = await navigator.clipboard.read();
      if (clipboardItems[0]) {
        const blobOutput = await clipboardItems[0].getType("image/png");
        // const data = URL.createObjectURL(blobOutput);
        var file = new File([blobOutput], "image.png", { type: "image/png" });
        const a = [...media, file];
        uploadChatMedia([file]);
        setMedia(a);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);
      if (data.type === "message") {
        if (data.data.chatId === chat.chatId) {
          props.addChat(data.data);
        } else {
          props.updateUnreadCount(data.data);
        }
      } else {
        props.loadChat(member.user_id, (res) => { });
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    if (chat.chatId) {
      setMessageLoading(true);
      props.loadMassages(chat.userId, chat.auctionId, (res) => {
        setMessageLoading(false);
      });
    }
  }, [chat]);

  useEffect(() => {
    setTimeout(() => {
      const objDiv = document.getElementById("chatPannelBody");
      if (objDiv) objDiv.scrollTop = objDiv.scrollHeight + 1000;
    }, 100);
  }, [props.getChats.chatMassages]);

  useEffect(() => {
    props.getStates((res) => null);

    setLoading(true);
    const userId = member.user_id;
    // Load chat list
    props.loadChat(userId, (res) => {
      setLoading(false);
      if (res.data && res.data.length > 0) setChat(res.data[0]);
    });
  }, []);

  const uploadChatMedia = (med) => {
    if (med) {
      const a = [];
      for (let i = 0; i < med.length; i++) {
        ref?.current?.continuousStart();
        setLoadingMessage(true);
        const file = med[i];

        const fSize = Math.round(file.size / 1048576);
        const fType = file.type;
        const ext = file.name.split(".").pop();
        if (fSize > 250) {
          return (
            toast(<AlertError message="Media size exceeds maximum allowable size. Maximum allowable size is 250MB." />),
            setLoadingMessage(false),
            ref?.current?.complete()
          );
        } else if (
          ![
            "image/jpeg",
            "image/jpg",
            "image/png",
            "video/MP4",
            "video/mp4",
            "video/webm",
            "video/mpeg",
            "application/pdf",
            "text/csv",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ].includes(fType)
        ) {
          return (
            toast(
              <AlertError message="Media is not of correct format and hence cannot be send. Valid image formats are jpeg, jpg, png, mp4, webm, mpeg, pdf, xlsx, xls and csv." />,
            ),
            setLoadingMessage(false),
            ref?.current?.complete()
          );
        } else {
          const fileName = `chat/${chat.userId}/${member.user_id}/` + uuidv4() + `/${file?.name}`;

          a.push(
            Storage.put(fileName, file, {
              completeCallback: (event) => { },
              progressCallback: (progress) => { },
              errorCallback: (err) => { },
            }),
          );
        }
      }
      const allPromise = Promise.all([...a]);
      ref?.current?.complete();
      allPromise.then((result) => {
        setLoadingMessage(false);
        setSelectedMedia([...selectedMedia, ...result]);
      });
    }
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <div class="dashboard-insider position-relative d-lg-flex w-100 ">
        <Sidebar
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          menuList={menuList}
          setMenuList={setMenuList}
          page="chats"
        />
        <div className={showMenu ? ` main-menu-expanded ` : "main-menu-compressed"}>
          <div class="chat-outer d-flex flex-wrap">
            <div class="chat-sidebar h-100 d-flex flex-column">
              <div class="tab-content flex-grow-1" id="myTabContent">
                <div class="tab-pane fade show active h-100 flex-column" id="paneA">
                  <div class="search-widget position-relative">
                    <input
                      value={search}
                      class="sw-search"
                      type="search"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                    <div class="sw-icon-box d-flex align-items-center justify-content-center">
                      <img class="w-100 h-100" src={searchIcon} alt="" />
                    </div>
                  </div>
                  <div class="chat-member-widget flex-grow-1">
                    <ul class="chat-member-list">
                      {props.getChats.chats
                        .filter((x) =>
                          search
                            ? x.user.name.toLowerCase().includes(search.toLowerCase()) ||
                            x.auctionTitle.toLowerCase().includes(search.toLowerCase()) ||
                            x.auctionId.toString().includes(search.toLowerCase())
                            : true,
                        )
                        .map((list) => (
                          <li
                            onClick={(e) => {
                              e.preventDefault();
                              setChat(list);
                            }}
                            class="chat-member-item"
                          >
                            <div
                              class={
                                chat.chatId === list.chatId
                                  ? "chat-member-box d-flex flex-wrap w-100 h-100 chat-pending-notify active"
                                  : "chat-member-box d-flex flex-wrap w-100 h-100 chat-pending-notify "
                              }
                            >
                              <div class="custom-profile" style={{ backgroundColor: "#FFD1D1" }}>
                                <p>
                                  {list.user.name.split(" ")[0] && list.user.name.split(" ")[0][0]}
                                  {list.user.name.split(" ")[1] && list.user.name.split(" ")[1][0]}
                                </p>
                              </div>
                              <div class="chat-member-detail">
                                <h5>
                                  {list.user.name}

                                  {list.user.isOnline ? (
                                    <div class="online-member"></div>
                                  ) : (
                                    <div
                                      class="online-member"
                                      style={{
                                        background: "gray",
                                      }}
                                    ></div>
                                  )}
                                </h5>

                                <div class="chat-member-txt">
                                  <p>
                                    {list?.auctionId} - {list.auctionTitle}
                                  </p>
                                  {list.adminUnreadCount ? (
                                    <span
                                      class="d-flex align-items-center justify-content-center"
                                      style={{ background: "red" }}
                                    >
                                      {list.adminUnreadCount}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div class="tab-pane fade flex-grow-1 h-100 flex-column" id="paneB">
                  <div class="search-widget position-relative">
                    <input class="sw-search" type="search" placeholder="Search" />
                    <div class="sw-icon-box d-flex align-items-center justify-content-center">
                      <img class="w-100 h-100" src={searchIcon} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {chat.chatId && (
              <div class="chat-main-container h-100 d-flex flex-column">
                <div class="chat-pannel-header d-flex align-items-center justify-content-between">
                  <div class="cp-member  d-flex align-items-center flex-wrap">
                    <div class="chat-menu-icon d-lg-none" id="chatCollapseBtn">
                      <i class="fas fa-comments"></i>
                    </div>
                    <div class="custom-profile" style={{ backgroundColor: "#FFD1D1" }}>
                      <p>
                        {chat.user.name.split(" ")[0] && chat.user.name.split(" ")[0][0]}
                        {chat.user.name.split(" ")[1] && chat.user.name.split(" ")[1][0]}
                      </p>
                    </div>
                    <div class="cp-member-details ">
                      <span className=" position-relative" style={{ fontWeight: "500" }}>
                        {chat.user.name}
                        {chat.user && chat.user.isOnline ? (
                          <div class="online-member"></div>
                        ) : (
                          <div class="online-member" style={{ background: "gray" }}></div>
                        )}
                      </span>

                      <span className="d-block gray-color-text" style={{ fontWeight: "400" }}>
                        {chat?.user?.email}
                        {chat?.user?.phone ? `, ${chat?.user?.phone}` : ""}
                      </span>
                    </div>
                  </div>

                  <div class="list-detail-pannel d-flex flex-wrap align-items-center">
                    <div
                      className="d-none d-md-block"
                      target="_blank"
                      style={{
                        marginLeft: "10px",
                        textDecoration: "underline",
                        color: "#47ad1d",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        //open in new tab
                        window.open(`/listing-detail?id=${chat.auctionId}`, "_blank");
                      }}
                    >
                      {chat.auctionId} - {chat.auctionTitle}
                    </div>
                  </div>
                </div>

                <div class="chat-pannel-body flex-grow-1 d-flex flex-column" id="chatPannelBody">
                  {!messageLoading ? (
                    props.getChats.chatMassages.map((chatMassage) => (
                      <>
                        {chatMassage.senderId == member.user_id ? (
                          chatMassage?.fileType ? (
                            <a
                              href={process.env.REACT_APP_CHAT_MEDIA + "public/" + chatMassage?.message}
                              target="_blank"
                              class="message d-flex flex-column align-items-start position-relative send pb-3"
                            >
                              {["jpeg", "jpg", "png"].includes(chatMassage.fileType) ? (
                                <img
                                  style={{ height: "60px", width: "60px" }}
                                  src={process.env.REACT_APP_CHAT_MEDIA + "public/" + chatMassage?.message}
                                  alt=""
                                />
                              ) : ["mp4", "MP4", "mpeg", "webm"].includes(chatMassage.fileType) ? (
                                <div class="media-widget-box position-relative">
                                  <div class="media-widget-file-icon d-flex align-items-center justify-content-center">
                                    <img src={mp4} alt="" />
                                  </div>
                                  <div class="media-widget-content">
                                    <div class="media-intro">
                                      <div class="media-into d-flex flex-nowrap">
                                        <span>{chatMassage?.message?.split("/")?.[4]?.substring(0, 5) + "..."}</span>
                                      </div>
                                    </div>
                                    <div class="file-size">{chatMassage.fileType}</div>
                                  </div>
                                </div>
                              ) : ["csv", "xlsx", "xls", "pdf"].includes(chatMassage.fileType) ? (
                                <div class="media-widget-box position-relative">
                                  <div class="media-widget-file-icon d-flex align-items-center justify-content-center">
                                    <img
                                      src={
                                        chatMassage.fileType === "csv"
                                          ? csvFile
                                          : chatMassage.fileType === "xlsx"
                                            ? XLSX
                                            : chatMassage.fileType === "xls"
                                              ? XLS
                                              : pdfIcon
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div class="media-widget-content">
                                    <div class="media-intro">
                                      <div class="media-into d-flex flex-nowrap">
                                        <span>{chatMassage?.message?.split("/")?.[4]?.substring(0, 5) + "..."}</span>
                                      </div>
                                    </div>
                                    <div class="file-size">{chatMassage.fileType}</div>
                                  </div>
                                </div>
                              ) : (
                                <div class="media-widget-box position-relative">
                                  <div class="media-widget-file-icon d-flex align-items-center justify-content-center">
                                    <img src={fileIcon} alt="" />
                                  </div>
                                  <div class="media-widget-content">
                                    <div class="media-intro">
                                      <div class="media-into d-flex flex-nowrap">
                                        <span>{chatMassage?.message?.split("/")?.[4]?.substring(0, 15) + "..."}</span>
                                      </div>
                                    </div>
                                    <div class="file-size">{chatMassage?.fileType}</div>
                                  </div>
                                </div>
                              )}

                              <div class="m-text-xs d-flex align-items-center">
                                {moment(chatMassage.createdAt).format("MMMM D, H:mm")}{" "}
                              </div>
                            </a>
                          ) : (
                            <div class="message d-flex flex-column align-items-start position-relative send pb-3">
                              <div class="m-content">
                                <p>{chatMassage.message}</p>
                              </div>

                              <div class="m-text-xs d-flex align-items-center">
                                {moment(chatMassage.createdAt).format("MMMM D, H:mm")}{" "}
                              </div>
                            </div>
                          )
                        ) : chatMassage?.fileType ? (
                          <a
                            class="message d-flex flex-column align-items-start position-relative received"
                            href={process.env.REACT_APP_CHAT_MEDIA + "public/" + chatMassage?.message}
                            target="_blank"
                          >
                            {["jpeg", "jpg", "png"].includes(chatMassage.fileType) ? (
                              <img
                                style={{ height: "60px", width: "60px" }}
                                src={process.env.REACT_APP_CHAT_MEDIA + "public/" + chatMassage?.message}
                                alt=""
                              />
                            ) : (
                              <div class="media-widget-box position-relative">
                                <div class="media-widget-file-icon d-flex align-items-center justify-content-center">
                                  <img src={fileIcon} alt="" />
                                </div>
                                <div class="media-widget-content">
                                  <div class="media-intro">
                                    <div class="media-into d-flex flex-nowrap">
                                      <span>{chatMassage?.message?.split("/")?.[4]?.substring(0, 5) + "..."}</span>
                                    </div>
                                  </div>
                                  <div class="file-size">{chatMassage?.fileType}</div>
                                </div>
                              </div>
                            )}

                            <div class="custom-profile" style={{ backgroundColor: "#FFD1D1" }}>
                              <p>
                                {" "}
                                {chatMassage.senderName &&
                                  chatMassage.senderName.split(" ")[0] &&
                                  chatMassage.senderName.split(" ")[0][0]}
                                {chatMassage.senderName &&
                                  chatMassage.senderName.split(" ")[1] &&
                                  chatMassage.senderName.split(" ")[1][0]}
                              </p>
                            </div>
                            <div class="m-text-xs d-flex align-items-center">
                              {moment(chatMassage.createdAt).format("MMMM D, H:mm")}{" "}
                            </div>
                          </a>
                        ) : (
                          <div class="message d-flex flex-column align-items-start position-relative received pb-3">
                            <div class="custom-profile" style={{ backgroundColor: "#FFD1D1" }}>
                              <p>
                                {chatMassage.senderName &&
                                  chatMassage.senderName.split(" ")[0] &&
                                  chatMassage.senderName.split(" ")[0][0]}
                                {chatMassage.senderName &&
                                  chatMassage.senderName.split(" ")[1] &&
                                  chatMassage.senderName.split(" ")[1][0]}
                              </p>
                            </div>
                            <div class="m-content">
                              <p>{chatMassage.message}</p>
                            </div>
                            <div class="m-text-xs d-flex align-items-center">
                              {chatMassage.senderName.split(" ")[0]},{" "}
                              {moment(chatMassage.createdAt).format("MMMM D, H:mm")}{" "}
                            </div>
                          </div>
                        )}
                      </>
                    ))
                  ) : (
                    <>
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                    </>
                  )}
                </div>
                {media?.length > 0 ? (
                  <div className="chat-media-item">
                    <div className="chat-media-list">
                      {Array.from(media)?.length > 0 &&
                        Array.from(media)?.map((item) => {
                          const ext = item?.name?.split(".")?.pop();

                          return (
                            <>
                              {ext == "jpg" || ext == "jpeg" || ext == "png" ? (
                                <img
                                  className="chat-images"
                                  style={{ height: "40px" }}
                                  src={`${URL.createObjectURL(item)}`}
                                />
                              ) : ext == "mp4" || ext == "MP4" || ext == "mpeg" || ext == "webm" ? (
                                <img className="chat-images" style={{ height: "40px" }} src={mp4} />
                              ) : (
                                <img
                                  className="chat-images"
                                  style={{ height: "40px" }}
                                  src={ext === "csv" ? csvFile : ext === "xlsx" ? XLSX : ext === "xls" ? XLS : pdfIcon}
                                />
                              )}
                            </>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div class="chat-pannel-footer">
                  <div class="custom-input-group w-100 d-flex align-items-center cursor-pointer">
                    <div class="input-group-prepend position-relative">
                      <input
                        type="file"
                        id="inputGroupFile02dk"
                        multiple
                        accept=".jpg,.jpeg,.png,.csv,.xls,.xlsx,.pdf,.mp4,.mpeg,.webm,.MP4"
                        onChange={(e) => {
                          const a = [...media, ...Array.from(e.target.files)];
                          setMedia(a);
                          uploadChatMedia(Array.from(e.target.files));
                        }}
                      />

                      <a href="#!" class="input-group-text">
                        <img src={paperClip} alt="" />
                      </a>
                    </div>
                    <input
                      type="text"
                      class="custom-send-input flex-grow-1 rounded-pill h-100"
                      placeholder="Type a message"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      onPaste={() => pasteImg()}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (loadingMessage || (message == "" && selectedMedia?.length == 0)) return;
                          if (selectedMedia?.length == 0 && message != "") {
                            sendMessage(
                              JSON.stringify({
                                type: "message",
                                auctionId: chat.auctionId,
                                message: message,
                                userId: chat.userId,
                              }),
                            );
                            props.addChat({
                              message,
                              sendTime: new Date().valueOf(),
                              senderId: member.user_id,
                              senderName: member.name01,
                            });
                          } else if (selectedMedia?.length > 0 && message == "") {
                            selectedMedia?.map((ip) => {
                              sendMessage(
                                JSON.stringify({
                                  type: "message",
                                  messageType: "file",
                                  fileType: `${ip.key.split(".").pop()}`,
                                  auctionId: chat.auctionId,
                                  message: ip?.key,
                                  userId: chat.userId,
                                }),
                              );
                              props.addChat({
                                message: ip?.key,
                                fileType: `${ip.key.split(".").pop()}`,
                                sendTime: new Date().valueOf(),
                                senderId: member.user_id,
                                senderName: member.name01,
                              });
                            });
                          } else {
                            selectedMedia?.map((ip) => {
                              sendMessage(
                                JSON.stringify({
                                  type: "message",
                                  messageType: "file",
                                  fileType: `${ip.key.split(".").pop()}`,
                                  auctionId: chat.auctionId,
                                  message: ip?.key,
                                  userId: chat.userId,
                                }),
                              );
                              props.addChat({
                                message: ip?.key,
                                fileType: `${ip.key.split(".").pop()}`,
                                sendTime: new Date().valueOf(),
                                senderId: member.user_id,
                                senderName: member.name01,
                              });
                            });
                            sendMessage(
                              JSON.stringify({
                                type: "message",
                                auctionId: chat.auctionId,
                                message: message,
                                userId: chat.userId,
                              }),
                            );
                            props.addChat({
                              message,
                              sendTime: new Date().valueOf(),
                              senderId: member.user_id,
                              senderName: member.name01,
                            });
                          }

                          setMessage("");
                          setMedia([]);
                          setSelectedMedia([]);
                        }
                      }}
                    />
                    <div class="input-group-append rounded-circle">
                      <a
                        onClick={(e) => {
                          if (loadingMessage || (message == "" && selectedMedia?.length == 0)) return;
                          if (selectedMedia?.length == 0) {
                            sendMessage(
                              JSON.stringify({
                                type: "message",
                                auctionId: chat.auctionId,
                                message: message,
                                userId: chat.userId,
                              }),
                            );
                            props.addChat({
                              message,
                              sendTime: new Date().valueOf(),
                              senderId: member.user_id,
                              senderName: member.name01,
                            });
                          } else if (selectedMedia?.length > 0 && message == "") {
                            selectedMedia?.map((ip) => {
                              sendMessage(
                                JSON.stringify({
                                  type: "message",
                                  messageType: "file",
                                  fileType: `${ip.key.split(".").pop()}`,
                                  auctionId: chat.auctionId,
                                  message: ip?.key,
                                  userId: chat.userId,
                                }),
                              );
                              props.addChat({
                                message: ip?.key,
                                fileType: `${ip.key.split(".").pop()}`,
                                sendTime: new Date().valueOf(),
                                senderId: member.user_id,
                                senderName: member.name01,
                              });
                            });
                          } else {
                            selectedMedia?.map((ip) => {
                              sendMessage(
                                JSON.stringify({
                                  type: "message",
                                  messageType: "file",
                                  fileType: `${ip.key.split(".").pop()}`,
                                  auctionId: chat.auctionId,
                                  message: ip?.key,
                                  userId: chat.userId,
                                }),
                              );
                              props.addChat({
                                message: ip?.key,
                                fileType: `${ip.key.split(".").pop()}`,
                                sendTime: new Date().valueOf(),
                                senderId: member.user_id,
                                senderName: member.name01,
                              });
                            });
                            sendMessage(
                              JSON.stringify({
                                type: "message",
                                auctionId: chat.auctionId,
                                message: message,
                                userId: chat.userId,
                              }),
                            );
                            props.addChat({
                              message,
                              sendTime: new Date().valueOf(),
                              senderId: member.user_id,
                              senderName: member.name01,
                            });
                          }

                          setMessage("");
                          setMedia([]);
                          setSelectedMedia([]);
                        }}
                        href="javascript:void(0)"
                        class={`w-100 h-100 rounded-circle d-flex align-items-center justify-content-center ${loadingMessage ? "btn-disabled" : ""
                          }`}
                      >
                        <img src={sendIcon} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadChat: (userId, callback) => dispatch(loadChatList(userId, callback)),
  loadMassages: (userId, auctionId, callback) => dispatch(loadChatMassages(userId, auctionId, callback)),
  addChat: (data) => dispatch(addChat(data)),
  updateUnreadCount: (data) => dispatch(updateUnreadCount(data)),
  getStates: (callback) => dispatch(getStates(callback)),
});

const mapStateToProps = (state) => ({
  getChats: getChats(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Chats));
