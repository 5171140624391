import moment from "moment/moment";
import React, { useEffect, useImperativeHandle, useState } from "react";
import { connect, useDispatch } from "react-redux";
import DateFormat from "../../../common/DateFormat/dateFormat";
import MoreDetailsModal from "../../../layouts/modals/moreDetailsModal";
import {
  getListingProductDetail,
  getMoreDetails,
  moreDetailsReceived,
  updateMoreDetails,
} from "../../../store/listingProduct";
import { getAuctionStatus, getAuctionType, getMisc, getProjects } from "../../../store/misc";
import _ from "lodash";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import Skeleton from "react-loading-skeleton";
import helpCircle from "../../../include/images/help-circle.svg";
import { getModals, setMoreDetailsModal } from "../../../store/modal";
import { detailListing, getListings, getSellerMotivation } from "../../../store/listings";
import RuleActiveModal from "../../../layouts/modals/ruleActiveModal";
import { getListingMedia } from "../../../store/listingsMedia";
import RuleArchiveModal from "../../../layouts/modals/ruleArchiveModal";
import { getCommentsAndNotes } from "../../../store/commentsAndNotes";

const MoreDetails = React.forwardRef((props, ref) => {
  // const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [auctionEndDate, setAcutionEndDate] = useState("");
  const [dueDateSeller, setDueDateSeller] = useState(null);
  const [auctionStatus, setAuctionStatus] = useState(null);
  const [auctionType, setAuctionType] = useState(null);
  const [holdDate, setHoldDate] = useState(null);
  const [error, setError] = useState(false);
  const [ruleModal, setRuleModal] = useState(false);
  const [reason, setReason] = useState(false);
  const [ruleArchiveModal, setRuleArchiveModal] = useState(false);
  const [detailData, setDetailData] = useState({
    at_id: "",
    date: "",
    date_scheduler: "",
    override_scheduler: false,
    end_date_1: "",
    end_date_2: "",
    end_date_3: "",
    end_date_4: "AM",
    archive_reason: "",
    status_id: "",
    proc_proj_id: "",
    due_date_1: "",
    due_date_2: "",
    due_date_3: "",
    due_date_4: "AM",
    vessel_name: "",
    file_number: "",
    bigdeal: false,
    mega: false,
    ready_to_close: false,
    loc_sec: "",
    private: false,
    proxibid: null,
    proxibid_archive: false,
    proxibid_hidereport: false,
    hotlist: false,
    featured: false,
    pushready: null,
    exclusive: false,
    b_to_s: false,
    chk_hold: false,
    hold_end_date: "",
    available_view: false,
    motivation_post: false,
    motivation: "",
  });
  const showDetailsModal = props?.moreDetailsModal;
  const category = props?.getListings?.category?.data?.cat_des;
  const imgUploaded = props?.getMediaData?.listingImages?.pics?.length ? true : false;
  const seller =
    props?.getListings?.detail?.detail?.seller_id === 0 && props?.getListings?.detail?.detail?.s_member_id === 0
      ? false
      : true;
  const title = props?.getListings?.detail?.detail?.title ? true : false;
  const description = props?.getListings?.detail?.detail?.details ? true : false;
  const condition = props?.getListingProductDetail?.condition?.detail?.conditionType ? true : false;
  const quantity = props?.getListings?.detail?.detail?.quantity ? true : false;
  const unit = props?.getListings?.detail?.detail?.unit ? true : false;

  useImperativeHandle(ref, () => ({
    toggleShowDetailsModal() {
      toggleShowDetailsModal();
    },
  }));

  const toggleRuleModal = () => {
    setRuleModal(!ruleModal);
  };
  const toggleRuleArchiveModal = () => {
    setRuleArchiveModal(!ruleArchiveModal);
  };
  const toggleShowDetailsModal = () => {
    props?.setShowDetailsModal(!showDetailsModal);
  };

  useEffect(() => {
    if (showDetailsModal) {
      props.getAuctionStatus((res) => {
        if (res && res.status === 200) {
          const data =
            res.data &&
            res.data.data.length > 0 &&
            res.data.data.map((item) => ({
              name: item.description,
              value: item.as_id,
            }));
          setAuctionStatus(data);
        }
      });
      props.getAuctionType((res) => {
        if (res && res.status === 200) {
          const data =
            res.data &&
            res.data.data.length > 0 &&
            res.data.data.map((item) => ({
              name: item.description,
              value: item.at_id,
            }));
          setAuctionType(data);
        }
      });
      props.getProjects(props.auctionId, (res) => {
        if (res.status === 200) {
        }
      });
    }
    setReason(false);
  }, [showDetailsModal]);

  const details =
    (props.getListingProductDetail &&
      props.getListingProductDetail.moreDetails &&
      props.getListingProductDetail.moreDetails.data) ||
    {};

  const loactionSecurity = (data) => {
    var locn = {};
    if (data === 0) {
      locn = { name: "Continent", value: 0 };
      return locn;
    } else if (data === 1) {
      locn = { name: "Region", value: 1 };
      return locn;
    } else if (data === 2) {
      locn = { name: "City, State/Country", value: 2 };
      return locn;
    } else if (data === 3) {
      locn = { name: "Exact", value: 3 };
      return locn;
    }
  };

  const dateTime = (w) => {
    const d = new Date();
    let diff = d.getTimezoneOffset();

    const date = moment(w).add({ minute: diff }).format("L");
    const hour = moment(w).add({ minute: diff }).format("H");
    const min = moment(w).add({ minute: diff }).format("m");
    const type = moment(w).add({ minute: diff }).format("A");

    const data = {
      date,
      hour,
      min,
      type,
    };

    return data;
  };

  const handleSubmit = () => {
    if (detailData.proc_proj_id) {
      if (detailData?.status_id?.value === 3) {
        if (category && imgUploaded && seller && title && description && condition && quantity && unit) {
          setLoading(true);
          props.refStart();
          const id = props.auctionId;
          var aEndDate;
          var dEndDate;
          var hEndDate;
          if (auctionEndDate) {
            aEndDate = dateTime(auctionEndDate);
          }
          if (dueDateSeller) {
            dEndDate = dateTime(dueDateSeller);
          }
          if (holdDate) {
            hEndDate = dateTime(holdDate);
          }
          const payload = {
            at_id: !_.isEmpty(detailData.at_id) && detailData.at_id.value ? detailData.at_id.value : "",
            date_scheduler: "",
            override_scheduler: false,
            end_date_1: auctionEndDate && aEndDate ? aEndDate.date : "",
            end_date_2: auctionEndDate && aEndDate ? aEndDate.hour : "",
            end_date_3: auctionEndDate && aEndDate ? aEndDate.min : "",
            end_date_4: auctionEndDate && aEndDate ? aEndDate.type : "",
            archive_reason: detailData?.archive_reason ? detailData?.archive_reason : "",
            status_id: !_.isEmpty(detailData.status_id) && detailData.status_id.value ? detailData.status_id.value : 0,
            proc_proj_id:
              !_.isEmpty(detailData.proc_proj_id) && detailData.proc_proj_id.value ? detailData.proc_proj_id.value : 0,
            due_date_1: dueDateSeller && dEndDate ? dEndDate.date : "",
            due_date_2: dueDateSeller && dEndDate ? dEndDate.hour : "",
            due_date_3: dueDateSeller && dEndDate ? dEndDate.min : "",
            due_date_4: dueDateSeller && dEndDate ? dEndDate.type : "",
            vessel_name: detailData.vessel_name ? detailData.vessel_name : "",
            file_number: detailData.file_number ? detailData.file_number : "",
            bigdeal: detailData.bigdeal ? true : false,
            mega: detailData.mega ? true : false,
            ready_to_close: detailData.ready_to_close ? true : false,
            loc_sec: !_.isEmpty(detailData.loc_sec) && detailData.loc_sec.value ? detailData.loc_sec.value : 0,
            private: detailData.private ? detailData.private : false,
            proxibid: detailData.proxibid ? detailData.proxibid : 0,
            proxibid_archive: detailData.proxibid_archive ? detailData.proxibid_archive : false,
            proxibid_hidereport: detailData.proxibid_hidereport ? detailData.proxibid_hidereport : false,
            hotlist: detailData.hotlist ? detailData.hotlist : false,
            featured: detailData.featured ? detailData.featured : false,
            pushready: detailData.pushready ? 1 : 0,
            exclusive: detailData.pushready ? detailData.exclusive : false,
            b_to_s: detailData.b_to_s ? detailData.b_to_s : false,
            chk_hold: detailData.chk_hold ? detailData.chk_hold : false,
            hold_end_date: detailData.chk_hold && hEndDate ? hEndDate.date : "",
            available_view: detailData.available_view ? detailData.available_view : false,
            motivation_post: detailData.motivation && detailData.motivation.length > 0 ? true : false,
            motivation: detailData.motivation && detailData.motivation.length > 0 ? detailData.motivation : "",
          };
          props.updateMoreDetails(id, payload, (res) => {
            if (res && res.status === 200) {
              props.getMoreDetails(id, (res) => {
                props.getSellerMotivation(id, (res) => {});
                props?.detailListing(id, (res) => {});
                if (res.status === 200) {
                  setLoading(false);
                  toggleShowDetailsModal();
                  props.refStop();
                  toast(<AlertSuccess message={"Information Saved"} />);
                } else {
                  setLoading(false);
                  props.refStop();
                  toast(
                    <AlertError
                      message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                    />,
                  );
                }
              });
            } else {
              setLoading(false);
              props.refStop();
              toast(
                <AlertError
                  message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                />,
              );
            }
          });
        } else {
          toggleShowDetailsModal();
          toggleRuleModal();
        }
      } else if (detailData?.status_id?.value === 5) {
        if (!reason) {
          setLoading(true);
          props.refStart();
          const id = props.auctionId;
          var aEndDate;
          var dEndDate;
          var hEndDate;
          if (auctionEndDate) {
            aEndDate = dateTime(auctionEndDate);
          }
          if (dueDateSeller) {
            dEndDate = dateTime(dueDateSeller);
          }
          if (holdDate) {
            hEndDate = dateTime(holdDate);
          }
          const payload = {
            at_id: !_.isEmpty(detailData.at_id) && detailData.at_id.value ? detailData.at_id.value : "",
            date_scheduler: "",
            override_scheduler: false,
            end_date_1: auctionEndDate && aEndDate ? aEndDate.date : "",
            end_date_2: auctionEndDate && aEndDate ? aEndDate.hour : "",
            end_date_3: auctionEndDate && aEndDate ? aEndDate.min : "",
            end_date_4: auctionEndDate && aEndDate ? aEndDate.type : "",
            archive_reason: detailData?.archive_reason ? detailData?.archive_reason : "",
            status_id: !_.isEmpty(detailData.status_id) && detailData.status_id.value ? detailData.status_id.value : 0,
            proc_proj_id:
              !_.isEmpty(detailData.proc_proj_id) && detailData.proc_proj_id.value ? detailData.proc_proj_id.value : 0,
            due_date_1: dueDateSeller && dEndDate ? dEndDate.date : "",
            due_date_2: dueDateSeller && dEndDate ? dEndDate.hour : "",
            due_date_3: dueDateSeller && dEndDate ? dEndDate.min : "",
            due_date_4: dueDateSeller && dEndDate ? dEndDate.type : "",
            vessel_name: detailData.vessel_name ? detailData.vessel_name : "",
            file_number: detailData.file_number ? detailData.file_number : "",
            bigdeal: detailData.bigdeal ? true : false,
            mega: detailData.mega ? true : false,
            ready_to_close: detailData.ready_to_close ? true : false,
            loc_sec: !_.isEmpty(detailData.loc_sec) && detailData.loc_sec.value ? detailData.loc_sec.value : 0,
            private: detailData.private ? detailData.private : false,
            proxibid: detailData.proxibid ? detailData.proxibid : 0,
            proxibid_archive: detailData.proxibid_archive ? detailData.proxibid_archive : false,
            proxibid_hidereport: detailData.proxibid_hidereport ? detailData.proxibid_hidereport : false,
            hotlist: detailData.hotlist ? detailData.hotlist : false,
            featured: detailData.featured ? detailData.featured : false,
            pushready: detailData.pushready ? 1 : 0,
            exclusive: detailData.pushready ? detailData.exclusive : false,
            b_to_s: detailData.b_to_s ? detailData.b_to_s : false,
            chk_hold: detailData.chk_hold ? detailData.chk_hold : false,
            hold_end_date: detailData.chk_hold && hEndDate ? hEndDate.date : "",
            available_view: detailData.available_view ? detailData.available_view : false,
            motivation_post: detailData.motivation && detailData.motivation.length > 0 ? true : false,
            motivation: detailData.motivation && detailData.motivation.length > 0 ? detailData.motivation : "",
          };
          props.updateMoreDetails(id, payload, (res) => {
            if (res && res.status === 200) {
              props.getMoreDetails(id, (res) => {
                props.getSellerMotivation(id, (res) => {});
                props?.detailListing(id, (res) => {});
                props?.getCommentsAndNotes(id, (res) => {});
                if (res.status === 200) {
                  setLoading(false);
                  toggleShowDetailsModal();
                  props.refStop();
                  toast(<AlertSuccess message={"Information Saved"} />);
                } else {
                  setLoading(false);
                  props.refStop();
                  toast(
                    <AlertError
                      message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                    />,
                  );
                }
              });
            } else {
              setLoading(false);
              props.refStop();
              toast(
                <AlertError
                  message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                />,
              );
            }
          });
        } else {
          if (detailData?.archive_reason) {
            setLoading(true);
            props.refStart();
            const id = props.auctionId;
            var aEndDate;
            var dEndDate;
            var hEndDate;
            if (auctionEndDate) {
              aEndDate = dateTime(auctionEndDate);
            }
            if (dueDateSeller) {
              dEndDate = dateTime(dueDateSeller);
            }
            if (holdDate) {
              hEndDate = dateTime(holdDate);
            }
            const payload = {
              at_id: !_.isEmpty(detailData.at_id) && detailData.at_id.value ? detailData.at_id.value : "",
              date_scheduler: "",
              override_scheduler: false,
              end_date_1: auctionEndDate && aEndDate ? aEndDate.date : "",
              end_date_2: auctionEndDate && aEndDate ? aEndDate.hour : "",
              end_date_3: auctionEndDate && aEndDate ? aEndDate.min : "",
              end_date_4: auctionEndDate && aEndDate ? aEndDate.type : "",
              archive_reason: detailData?.archive_reason ? detailData?.archive_reason : "",
              status_id:
                !_.isEmpty(detailData.status_id) && detailData.status_id.value ? detailData.status_id.value : 0,
              proc_proj_id:
                !_.isEmpty(detailData.proc_proj_id) && detailData.proc_proj_id.value
                  ? detailData.proc_proj_id.value
                  : 0,
              due_date_1: dueDateSeller && dEndDate ? dEndDate.date : "",
              due_date_2: dueDateSeller && dEndDate ? dEndDate.hour : "",
              due_date_3: dueDateSeller && dEndDate ? dEndDate.min : "",
              due_date_4: dueDateSeller && dEndDate ? dEndDate.type : "",
              vessel_name: detailData.vessel_name ? detailData.vessel_name : "",
              file_number: detailData.file_number ? detailData.file_number : "",
              bigdeal: detailData.bigdeal ? true : false,
              mega: detailData.mega ? true : false,
              ready_to_close: detailData.ready_to_close ? true : false,
              loc_sec: !_.isEmpty(detailData.loc_sec) && detailData.loc_sec.value ? detailData.loc_sec.value : 0,
              private: detailData.private ? detailData.private : false,
              proxibid: detailData.proxibid ? detailData.proxibid : 0,
              proxibid_archive: detailData.proxibid_archive ? detailData.proxibid_archive : false,
              proxibid_hidereport: detailData.proxibid_hidereport ? detailData.proxibid_hidereport : false,
              hotlist: detailData.hotlist ? detailData.hotlist : false,
              featured: detailData.featured ? detailData.featured : false,
              pushready: detailData.pushready ? 1 : 0,
              exclusive: detailData.pushready ? detailData.exclusive : false,
              b_to_s: detailData.b_to_s ? detailData.b_to_s : false,
              chk_hold: detailData.chk_hold ? detailData.chk_hold : false,
              hold_end_date: detailData.chk_hold && hEndDate ? hEndDate.date : "",
              available_view: detailData.available_view ? detailData.available_view : false,
              motivation_post: detailData.motivation && detailData.motivation.length > 0 ? true : false,
              motivation: detailData.motivation && detailData.motivation.length > 0 ? detailData.motivation : "",
            };
            props.updateMoreDetails(id, payload, (res) => {
              if (res && res.status === 200) {
                props.getMoreDetails(id, (res) => {
                  props.getSellerMotivation(id, (res) => {});
                  props?.detailListing(id, (res) => {});
                  props?.getCommentsAndNotes(id, (res) => {});
                  if (res.status === 200) {
                    setLoading(false);
                    toggleShowDetailsModal();
                    props.refStop();
                    toast(<AlertSuccess message={"Information Saved"} />);
                  } else {
                    setLoading(false);
                    props.refStop();
                    toast(
                      <AlertError
                        message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                      />,
                    );
                  }
                });
              } else {
                setLoading(false);
                props.refStop();
                toast(
                  <AlertError
                    message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                  />,
                );
              }
            });
          } else {
            toggleShowDetailsModal();
            toggleRuleArchiveModal();
          }
        }
      } else {
        setLoading(true);
        props.refStart();
        const id = props.auctionId;
        var aEndDate;
        var dEndDate;
        var hEndDate;
        if (auctionEndDate) {
          aEndDate = dateTime(auctionEndDate);
        }
        if (dueDateSeller) {
          dEndDate = dateTime(dueDateSeller);
        }
        if (holdDate) {
          hEndDate = dateTime(holdDate);
        }
        const payload = {
          at_id: !_.isEmpty(detailData.at_id) && detailData.at_id.value ? detailData.at_id.value : "",
          date_scheduler: "",
          override_scheduler: false,
          end_date_1: auctionEndDate && aEndDate ? aEndDate.date : "",
          end_date_2: auctionEndDate && aEndDate ? aEndDate.hour : "",
          end_date_3: auctionEndDate && aEndDate ? aEndDate.min : "",
          end_date_4: auctionEndDate && aEndDate ? aEndDate.type : "",
          archive_reason: detailData?.archive_reason ? detailData?.archive_reason : "",
          status_id: !_.isEmpty(detailData.status_id) && detailData.status_id.value ? detailData.status_id.value : 0,
          proc_proj_id:
            !_.isEmpty(detailData.proc_proj_id) && detailData.proc_proj_id.value ? detailData.proc_proj_id.value : 0,
          due_date_1: dueDateSeller && dEndDate ? dEndDate.date : "",
          due_date_2: dueDateSeller && dEndDate ? dEndDate.hour : "",
          due_date_3: dueDateSeller && dEndDate ? dEndDate.min : "",
          due_date_4: dueDateSeller && dEndDate ? dEndDate.type : "",
          vessel_name: detailData.vessel_name ? detailData.vessel_name : "",
          file_number: detailData.file_number ? detailData.file_number : "",
          bigdeal: detailData.bigdeal ? true : false,
          mega: detailData.mega ? true : false,
          ready_to_close: detailData.ready_to_close ? true : false,
          loc_sec: !_.isEmpty(detailData.loc_sec) && detailData.loc_sec.value ? detailData.loc_sec.value : 0,
          private: detailData.private ? detailData.private : false,
          proxibid: detailData.proxibid ? detailData.proxibid : 0,
          proxibid_archive: detailData.proxibid_archive ? detailData.proxibid_archive : false,
          proxibid_hidereport: detailData.proxibid_hidereport ? detailData.proxibid_hidereport : false,
          hotlist: detailData.hotlist ? detailData.hotlist : false,
          featured: detailData.featured ? detailData.featured : false,
          pushready: detailData.pushready ? 1 : 0,
          exclusive: detailData.pushready ? detailData.exclusive : false,
          b_to_s: detailData.b_to_s ? detailData.b_to_s : false,
          chk_hold: detailData.chk_hold ? detailData.chk_hold : false,
          hold_end_date: detailData.chk_hold && hEndDate ? hEndDate.date : "",
          available_view: detailData.available_view ? detailData.available_view : false,
          motivation_post: detailData.motivation && detailData.motivation.length > 0 ? true : false,
          motivation: detailData.motivation && detailData.motivation.length > 0 ? detailData.motivation : "",
        };
        props.updateMoreDetails(id, payload, (res) => {
          if (res && res.status === 200) {
            props.getMoreDetails(id, (res) => {
              props.getSellerMotivation(id, (res) => {});
              props?.detailListing(id, (res) => {});
              if (res.status === 200) {
                setLoading(false);
                toggleShowDetailsModal();
                props.refStop();
                toast(<AlertSuccess message={"Information Saved"} />);
              } else {
                setLoading(false);
                props.refStop();
                toast(
                  <AlertError
                    message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                  />,
                );
              }
            });
          } else {
            setLoading(false);
            props.refStop();
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      }
    } else {
      toast(<AlertError message="Please Select Any Project to Continue" />);
    }
  };

  useEffect(() => {
    if (showDetailsModal) {
      const detail =
        (props.getListingProductDetail &&
          props.getListingProductDetail.moreDetails &&
          props.getListingProductDetail.moreDetails.data) ||
        {};
      const loc = detail.loc_sec ? loactionSecurity(detail.loc_sec) : "";
      const initialData = {
        at_id: {
          name: detail.at_description ? detail.at_description : "",
          value: detail.at_id ? detail.at_id : "",
        },
        date: "",
        date_scheduler: "",
        override_scheduler: false,
        end_date_1: "",
        end_date_2: "",
        end_date_3: "",
        end_date_4: "AM",
        archive_reason: "",
        status_id: {
          name: detail.status_description ? detail.status_description : "",
          value: detail.status_id ? detail.status_id : "",
        },
        proc_proj_id: {
          name: detail.project && detail.project.title ? detail.project.title : "",
          value: detail.project && detail.project.project_id ? detail.project.project_id : "",
        },
        due_date_1: "",
        due_date_2: "",
        due_date_3: "",
        due_date_4: "AM",
        vessel_name: detail.vessel_name ? detail.vessel_name : "",
        file_number: detail.file_number ? detail.file_number : "",
        bigdeal: detail.bigdeal ? detail.bigdeal : false,
        mega: detail.mega ? detail.mega : false,
        ready_to_close: detail.ready_to_close ? detail.ready_to_close : false,
        loc_sec: {
          name: loc ? loc.name : "",
          value: loc ? loc.value : "",
        },
        private: detail.private ? detail.private : false,
        proxibid: detail.proxibid ? detail.proxibid : 0,
        proxibid_archive: detail.proxibid_archive ? detail.proxibid_archive : false,
        proxibid_hidereport: detail.proxibid_hidereport ? detail.proxibid_hidereport : false,
        hotlist: detail.hotlist ? detail.hotlist : false,
        featured: detail.featured ? detail.featured : false,
        pushready: detail.pushready ? 1 : 0,
        exclusive: detail.exclusive ? detail.exclusive : false,
        b_to_s: detail.b_to_s ? detail.b_to_s : false,
        chk_hold: detail.hold_end_date ? true : false,
        hold_end_date: "",
        available_view: detail.available_view ? detail.available_view : false,
        motivation_post: detail.motivation_post ? detail.motivation_post : false,
        motivation: detail.motivation ? detail.motivation : false,
      };

      setDetailData(initialData);
      setAcutionEndDate(detail.end_date ? detail.end_date : "");
      setDueDateSeller(detail.due_date ? detail.due_date : "");

      setHoldDate(detail.hold_end_date ? detail.hold_end_date : "");
    }
  }, [showDetailsModal]);
  const projects =
    (props.getMisc &&
      props.getMisc.project &&
      props.getMisc.project.data &&
      props.getMisc.project.data.length > 0 &&
      props.getMisc.project.data.map((item) => ({
        name: item.title,
        value: item.project_id,
      }))) ||
    [];

  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div
            class="page-widget-label text-capitalize cursor-pointer"
            onClick={() => !props.loadingMain && toggleShowDetailsModal()}
          >
            Listing Settings: Status & Deadlines
          </div>
          <div style={{ display: "none" }} id="popover">
            <div class="popover-inner" role="tooltip">
              <p>
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                took a galley
              </p>
            </div>
          </div>
        </div>
        {/* <div class="btn-block">
          {props.loadingMain ? (
            <Skeleton height="30px" width="90px" />
          ) : (
            <a href="javascript:void(0)" class="btn btn-primary btn-sm" onClick={() => toggleShowDetailsModal()}>
              Edit
            </a>
          )}
        </div> */}
      </div>
      <div class="page-widget-body mt-3">
        <div class="table-responsive">
          <table class="table mb-0  product-table">
            <colgroup>
              <col style={{ width: "35%" }} />
              <col />
            </colgroup>
            <tbody>
              {props.loadingMain ? (
                <>
                  <tr>
                    <td>
                      {" "}
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Skeleton />
                    </td>
                    <td>
                      {" "}
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Skeleton />
                    </td>
                    <td>
                      {" "}
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Skeleton />
                    </td>
                    <td>
                      {" "}
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr>
                    <td>Sale Type</td>
                    <td>{details.at_description ? details.at_description : " N/A"}</td>
                  </tr>
                  <tr>
                    <td>Auction End Date</td>
                    <td>
                      {" "}
                      {auctionEndDate ? <DateFormat date={auctionEndDate} /> : <DateFormat date={details.end_date} />}
                    </td>
                  </tr>
                  <tr>
                    <td>Public Status</td>
                    <td>{details.status_description ? details.status_description : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Project</td>
                    <td>{details.project && details.project.title ? details.project.title : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Date Created</td>
                    <td>{details.date_created ? <DateFormat date={details.date_created} /> : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Last Updated</td>
                    <td>{details.last_updated ? <DateFormat date={details.last_updated} /> : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Due Date to Seller / Bid Deadline</td>
                    <td>
                      {details.due_date ? (
                        <span className="font-weight-600" style={{ color: "red" }}>
                          <DateFormat date={details.due_date} />
                        </span>
                      ) : (
                        <span>N/A</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Vessel Name</td>
                    <td>{details.vessel_name ? details.vessel_name : ""}</td>
                  </tr>
                  <tr>
                    <td>File Number</td>
                    <td>{details.file_number ? details.file_number : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Big Deal?</td>
                    <td>{details.bigdeal ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <td>Exclusive Contract?</td>
                    <td>{details.mega ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <td>Ready to Close?</td>
                    <td>{details.ready_to_close ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <td>Location Security</td>
                    <td>
                      {details.loc_sec
                        ? loactionSecurity(details.loc_sec)?.name
                        : details.loc_sec === 0
                        ? loactionSecurity(details.loc_sec)?.name
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>Private</td>
                    <td>{details.private ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <td>Main Project Listing?</td>
                    <td>{details.hotlist ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <td>Featured</td>
                    <td>{details.featured ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <td>Push Ready</td>
                    <td>{details.pushready ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <td>Hold?</td>
                    <td> {details.hold_end_date ? <DateFormat date={details.hold_end_date} /> : "No"}</td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <MoreDetailsModal
        show={showDetailsModal}
        onHide={toggleShowDetailsModal}
        setDetailData={setDetailData}
        detailData={detailData}
        setAcutionEndDate={setAcutionEndDate}
        auctionEndDate={auctionEndDate}
        dueDateSeller={dueDateSeller}
        holdDate={holdDate}
        setDueDateSeller={setDueDateSeller}
        setHoldDate={setHoldDate}
        loading={loading}
        auctionType={auctionType}
        auctionStatus={auctionStatus}
        handleSubmit={handleSubmit}
        projects={projects}
        reason={reason}
        setReason={setReason}
      />
      <RuleActiveModal
        show={ruleModal}
        onHide={toggleRuleModal}
        category={category}
        imgUploaded={imgUploaded}
        seller={seller}
        title={title}
        description={description}
        condition={condition}
        quantity={quantity}
        unit={unit}
      />
      <RuleArchiveModal show={ruleArchiveModal} onHide={toggleRuleArchiveModal} />
    </div>
  );
});

const mapDispatchToProps = (dispatch) => ({
  //global state for storing api value
  updateMoreDetails: (params, data, callback) => dispatch(updateMoreDetails(params, data, callback)),
  getAuctionStatus: (callback) => dispatch(getAuctionStatus(callback)),
  getAuctionType: (callback) => dispatch(getAuctionType(callback)),
  getProjects: (data, callback) => dispatch(getProjects(data, callback)),
  getMoreDetails: (data, callback) => dispatch(getMoreDetails(data, callback)),
  getSellerMotivation: (id, callback) => dispatch(getSellerMotivation(id, callback)),
  detailListing: (data, callback) => dispatch(detailListing(data, callback)),
  getCommentsAndNotes: (id, callback) => dispatch(getCommentsAndNotes(id, callback)),
  //global state managed
  moreDetailsReceived: (data) => dispatch(moreDetailsReceived(data)),
  setShowDetailsModal: (data) => dispatch(setMoreDetailsModal(data)),
});

const mapStateToProps = (state) => ({
  getListingProductDetail: getListingProductDetail(state),
  getListings: getListings(state),
  getMisc: getMisc(state),
  moreDetailsModal: getModals(state)?.moreDetailsModal,
  getMediaData: getListingMedia(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(MoreDetails));
