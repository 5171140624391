import { createSelector, createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "otherListings",
  initialState: {
    loading: false,
    otherListings: [],
    count: "",
    observers: {}
  },
  reducers: {
    otherListingsFetching: (state, action) => {
      state.loading = true;
    },
    otherListingsFetched: (state, action) => {
      state.otherListings = action.payload;
      state.loading = false;
    },
    otherListingsFailed: (state, action) => {
      state.loading = false;
    },
    countFetching: (state, action) => {
      state.loading = true;
    },
    countFetched: (state, action) => {
      state.count = action.payload;
      state.loading = false;
    },
    countFailed: (state, action) => {
      state.loading = false;
    },
    observersFetching: (state, action) => {
      state.loading = true;
    },
    observersFetched: (state, action) => {
      state.observers = action.payload;
      state.loading = false;
    },
    observersFailed: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  otherListingsFetching,
  otherListingsFetched,
  otherListingsFailed,
  countFetching,
  countFetched,
  countFailed,
  observersFetching,
  observersFetched,
  observersFailed,
} = slice.actions;
export default slice.reducer;

//Action Creators

const baseUrl = "auctionOther/other/list";
const countUrl = "auctionOther/other/count";

//Get Other Listings
export const getOtherListings =
  (params, catId, page, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${baseUrl}/${params}?page=${page}&limit=10&category_id=${catId}`,
        method: "get",
        callback,
        onStart: otherListingsFetching.type,
        onSuccess: otherListingsFetched.type,
        onError: otherListingsFailed.type,
      })
    );
  };

//Get other Listing Count
export const getOtherListingCount = (params, catId, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${countUrl}/${params}?category_id=${catId}`,
      method: "get",
      callback,
      onStart: countFetching.type,
      onSuccess: countFetched.type,
      onError: countFailed.type,
    })
  );
};


//Get Observers
export const getObservers = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionOther/other/observer/${id}`,
      method: 'get',
      callback,
      onStart: observersFetching.type,
      onSuccess: observersFetched.type,
      onError: observersFailed.type,
    })
  )
}

export const otherListingsDetails = createSelector(
  (state) => state.entities.otherListings,
  (otherListings) => otherListings
);
