import React, { useState } from "react";
import close from "../../include/images/close-icon.svg";
import emptyFolder from "../../include/images/folder.svg";
import Skeleton from "react-loading-skeleton";
import More from "../../include/images/more-icon.svg";
import Sidebar from "../../layouts/sidebar";
import UploadParts from "../../layouts/modals/productDescription/uploadParts";
import { twoDecimals } from "../../utils.js/conversion";
import ImageLargeModal from "../../layouts/modals/imageGalleryModal";
import imageFill from "../../include/images/image.svg";
import imageOutLine from "../../include/images/Image-outline.svg";
import _ from "lodash";
import AviationFilter from "../listingDetail/aviationParts/aviationFilter";
const PartsTable = (props) => {
  //For SideNav
  const [showMenu, setShowMenu] = useState(false);
  const [imageGalleryModal, setImageGalleryModal] = useState(false);
  const [selectedImage, setSelecetedImage] = useState([]);
  const [menuList, setMenuList] = useState("");

  const [id, setId] = useState("");
  let pages = _.range(
    props?.page - 5 < 0 ? 0 : props?.page - 5,
    props?.totalPages > 10 ? props?.page + 5 : props?.totalPages,
  );
  const toggleImageGalleryModal = () => {
    setImageGalleryModal(!imageGalleryModal);
  };

  const checkIfImageExists = (url, callback) => {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };

      img.onerror = () => {
        callback(false);
      };
    }
  };

  return (
    <>
      <div class="dashboard-insider position-relative d-lg-flex w-100 ">
        <Sidebar
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          menuList={menuList}
          setMenuList={setMenuList}
          page="parts"
          pageCat="genericParts"
        />
        <div className={showMenu ? ` main-menu-expanded ` : "main-menu-compressed"}>
          <div class="filter-container d-flex align-items-center sticky-top">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 d-flex align-items-center flex-wrap justify-content-between">
                  <div class="filter-title fw-medium text-capitalize">Parts</div>
                  <div class="filter-widget-right d-flex align-items-center flex-wrap">
                    <div class="fw-item">
                      {props.loading ? (
                        <Skeleton width="50px" height="30px" />
                      ) : (
                        <div className="d-flex align-items-center">
                          <div class="filter-row d-flex align-items-center" onClick={() => props.toggleFilter()}>
                            <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M2.39767 16.5C2.07751 16.5 1.81796 16.2357 1.81796 15.9097V1.09032C1.81796 0.764295 2.07751 0.5 2.39767 0.5C2.71784 0.5 2.97738 0.764295 2.97738 1.09032V15.9097C2.97738 16.2357 2.71784 16.5 2.39767 16.5Z"
                                fill="currentColor"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M13.0226 15.9097C13.0226 16.2357 13.2822 16.5 13.6023 16.5C13.9225 16.5 14.182 16.2357 14.182 15.9097V1.09032C14.182 0.764295 13.9225 0.5 13.6023 0.5C13.2822 0.5 13.0226 0.764295 13.0226 1.09032V15.9097ZM0.57971 13.18H4.21565C4.53582 13.18 4.79536 12.9158 4.79536 12.5897C4.79536 12.2637 4.53582 11.9994 4.21565 11.9994H0.57971C0.259545 11.9994 0 12.2637 0 12.5897C0 12.9158 0.259545 13.18 0.57971 13.18Z"
                                fill="currentColor"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.42029 15.9097C7.42029 16.2357 7.67983 16.5 8 16.5C8.32016 16.5 8.57971 16.2357 8.57971 15.9097V1.09032C8.57971 0.764295 8.32016 0.5 8 0.5C7.67983 0.5 7.42029 0.764295 7.42029 1.09032V15.9097ZM11.7843 13.18H15.4203C15.7405 13.18 16 12.9158 16 12.5897C16 12.2637 15.7405 11.9994 15.4203 11.9994H11.7843C11.4642 11.9994 11.2046 12.2637 11.2046 12.5897C11.2046 12.9158 11.4642 13.18 11.7843 13.18Z"
                                fill="currentColor"
                              />
                              <path
                                d="M9.81798 5.00058H6.18204C5.86187 5.00058 5.60233 4.73629 5.60233 4.41027C5.60233 4.08424 5.86187 3.81995 6.18204 3.81995H9.81798C10.1381 3.81995 10.3977 4.08424 10.3977 4.41027C10.3977 4.73629 10.1381 5.00058 9.81798 5.00058Z"
                                fill="currentColor"
                              />
                            </svg>
                            <span>Filters</span>
                          </div>
                          {/* <div style={{ marginLeft: "20px" }}>
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={() => props.toggleUploadPartModal()}
                            >
                              Upload
                            </button>
                          </div> */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-container">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="tb-filter-row d-flex align-items-start justify-content-between">
                    <div class="filter-tags d-flex align-items-start">
                      {!props.loading && props.applyFilter && props?.response && (
                        <>
                          <div class="filter-tag-title">Filters Applied :</div>

                          <div class="tags">
                            {props.response && props.response?.auctionId && (
                              <span class="badge">
                                Auction Id : <span>{props.response && props.response.auctionId}</span>
                                <img
                                  class="tag-close-icon"
                                  src={close}
                                  alt=""
                                  onClick={() => {
                                    const data = {
                                      ...props.response,
                                    };
                                    data.auctionId = "";
                                    data.res = false;
                                    props?.setPage(1);
                                    props.setResponse(data);
                                  }}
                                />
                              </span>
                            )}
                            {props.response && props.response?.partNumber && (
                              <span class="badge">
                                Part Number : <span>{props.response && props.response.partNumber}</span>
                                <img
                                  class="tag-close-icon"
                                  src={close}
                                  alt=""
                                  onClick={() => {
                                    const data = {
                                      ...props.response,
                                    };
                                    data.partNumber = "";
                                    data.res = false;
                                    props?.setPage(1);
                                    props.setResponse(data);
                                  }}
                                />
                              </span>
                            )}
                            {props.response &&
                              props.response?.condition &&
                              props.response?.condition.length > 0 &&
                              props.response?.condition.map((item) => (
                                <span class="badge">
                                  Condition : <span>{item.name}</span>
                                  <img
                                    class="tag-close-icon"
                                    src={close}
                                    alt=""
                                    onClick={() => {
                                      const data = {
                                        ...props.response,
                                      };
                                      const a = props.response?.condition.filter((it) => it.name !== item.name);
                                      data.condition = a;
                                      data.res = false;
                                      props?.setPage(1);
                                      props.setResponse(data);
                                    }}
                                  />
                                </span>
                              ))}
                          </div>
                        </>
                      )}{" "}
                      {props.loading && (
                        <div class="filter-tags d-flex align-items-start">
                          <div class="filter-tag-title">
                            <div class="tags d-flex align-items-start">
                              <span class="badge">
                                <Skeleton />
                              </span>
                              <span class="badge">
                                <Skeleton />
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div class="tb-filter-right d-flex align-items-center justify-content-end">
                      <div class="showing-result-text">
                        {!props.loading && props.totalCount < 20 ? (
                          `Showing ${props.totalCount} of ${props.totalCount} total results`
                        ) : !props.loading && props.page == props.totalPages ? (
                          `Showing ${props.totalCount} of ${props.totalCount} total results`
                        ) : !props.loading && props?.page && props?.filteredCount ? (
                          `Showing ${20 * (props.page - 1) + props.filteredCount} of ${props.totalCount} total results`
                        ) : (
                          <Skeleton width="300px" height="25px" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="selected-table-row d-flex justify-content-between"></div>
                  <div
                    class={`table-responsive position-relative ${
                      props.partsData && props.partsData.length > 0 ? "min-user-table-height" : ""
                    }`}
                  >
                    <table class="table align-middle table-collapse-table">
                      <thead>
                        <tr>
                          <th className="table-header" style={{ width: "7%" }}>
                            Actions
                          </th>{" "}
                          <th className="table-header" style={{ width: "13%", minWidth: "133px" }}>
                            <a className={`sort-by-custom ${props.sortBy === "partNumber" ? "active-column" : ""}`}>
                              {" "}
                              Part Number{" "}
                              <div
                                class={`ascending-icon cursor-pointer ${
                                  props.sort == "asc" && props.sortBy == "partNumber" ? "sort-active" : ""
                                }`}
                                onClick={() => {
                                  props.setSortBy("partNumber");
                                  props.setSort("asc");
                                }}
                              ></div>
                              <div
                                class={`descending-icon cursor-pointer ${
                                  props.sort == "desc" && props.sortBy == "partNumber" ? "sort-active1" : ""
                                }`}
                                onClick={() => {
                                  props.setSortBy("partNumber");
                                  props.setSort("desc");
                                }}
                              ></div>
                            </a>{" "}
                          </th>
                          <th className="table-header" style={{ width: "15%", minWidth: "180px" }}>
                            <a
                              className={`sort-by-custom ${props.sortBy === "partDescription" ? "active-column" : ""}`}
                            >
                              {" "}
                              Part Description{" "}
                              <div
                                class={`ascending-icon cursor-pointer ${
                                  props.sort == "asc" && props.sortBy == "partDescription" ? "sort-active" : ""
                                }`}
                                onClick={() => {
                                  props.setSortBy("partDescription");
                                  props.setSort("asc");
                                }}
                              ></div>
                              <div
                                class={`descending-icon cursor-pointer ${
                                  props.sort == "desc" && props.sortBy == "partDescription" ? "sort-active1" : ""
                                }`}
                                onClick={() => {
                                  props.setSortBy("partDescription");
                                  props.setSort("desc");
                                }}
                              ></div>
                            </a>{" "}
                          </th>
                          <th className="table-header" style={{ width: "10%" }}>
                            <a className={`sort-by-custom ${props.sortBy === "condition" ? "active-column" : ""}`}>
                              Condition{" "}
                              <div
                                class={`ascending-icon cursor-pointer ${
                                  props.sort == "asc" && props.sortBy == "condition" ? "sort-active" : ""
                                }`}
                                onClick={() => {
                                  props.setSortBy("condition");
                                  props.setSort("asc");
                                }}
                              ></div>
                              <div
                                class={`descending-icon cursor-pointer ${
                                  props.sort == "desc" && props.sortBy == "condition" ? "sort-active1" : ""
                                }`}
                                onClick={() => {
                                  props.setSortBy("condition");
                                  props.setSort("desc");
                                }}
                              ></div>
                            </a>{" "}
                          </th>
                          <th className="table-header" style={{ width: "6%" }} align="right">
                            <a className={`sort-by-custom ${props.sortBy === "quantity" ? "active-column" : ""}`}>
                              QTY
                              <div
                                class={`ascending-icon cursor-pointer ${
                                  props.sort == "asc" && props.sortBy == "quantity" ? "sort-active" : ""
                                }`}
                                onClick={() => {
                                  props.setSortBy("quantity");
                                  props.setSort("asc");
                                }}
                              ></div>
                              <div
                                class={`descending-icon cursor-pointer ${
                                  props.sort == "desc" && props.sortBy == "quantity" ? "sort-active1" : ""
                                }`}
                                onClick={() => {
                                  props.setSortBy("quantity");
                                  props.setSort("desc");
                                }}
                              ></div>
                            </a>
                          </th>
                          <th className="table-header" style={{ width: "10%", minWidth: "100px", textAlign: "right" }}>
                            <span>Unit</span>
                          </th>
                          <th className="table-header" style={{ width: "10%", minWidth: "130px", textAlign: "right" }}>
                            <span>Unit Price</span>
                          </th>
                          <th className="table-header" style={{ width: "12%" }}>
                            <a className={`sort-by-custom ${props.sortBy === "location" ? "active-column" : ""}`}>
                              Location{" "}
                              <div
                                class={`ascending-icon cursor-pointer ${
                                  props.sort == "asc" && props.sortBy == "location" ? "sort-active" : ""
                                }`}
                                onClick={() => {
                                  props.setSortBy("location");
                                  props.setSort("asc");
                                }}
                              ></div>
                              <div
                                class={`descending-icon cursor-pointer ${
                                  props.sort == "desc" && props.sortBy == "location" ? "sort-active1" : ""
                                }`}
                                onClick={() => {
                                  props.setSortBy("location");
                                  props.setSort("desc");
                                }}
                              ></div>
                            </a>
                          </th>
                          <th className="table-header" style={{ width: "12%", minWidth: "115px" }}>
                            <a className="sort-by-custom"> Part Manufacturer </a>{" "}
                          </th>
                          <th className="table-header" style={{ width: "10%", minWidth: "90px" }}>
                            <a className="sort-by-custom"> Part Model </a>{" "}
                          </th>
                          <th className="table-header" style={{ width: "10%", minWidth: "90px" }}>
                            <a className="sort-by-custom"> Grade </a>{" "}
                          </th>
                          <th className="table-header" style={{ width: "10%", minWidth: "90px" }}>
                            <a className="sort-by-custom"> Serial Number </a>{" "}
                          </th>
                          <th className="table-header" style={{ width: "6%", maxWidth: "70px" }}>
                            <a className="sort-by-custom"> Images </a>{" "}
                          </th>
                          <th className="table-header" style={{ width: "10%", minWidth: "115px" }}>
                            <a className="sort-by-custom"> Listing ID </a>{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.loading
                          ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4].map((item) => (
                              <tr>
                                <td style={{ width: "5%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "9%", minWidth: "133px" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "9%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "9%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "9%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "9%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "9%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "9%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "9%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "9%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "9%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "9%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "9%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "9%" }}>
                                  <Skeleton />
                                </td>
                              </tr>
                            ))
                          : props.partsData &&
                            props.partsData.length > 0 &&
                            props.partsData.map((item) => {
                              return (
                                <tr>
                                  <td style={{ width: "7%" }}>
                                    <div class="td-action-btn-group d-flex align-items-center justify-content-between">
                                      <div class="dropdown">
                                        <a
                                          class="td-a-icon dropdown-toggle"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <img src={More} alt="" />
                                        </a>
                                        <ul class="dropdown-menu ms-1">
                                          {/* <li
                                            className="menu-item"
                                            data-bs-toggle="offcanvas"
                                            data-bs-target="#offcanvasRight"
                                            aria-controls="offcanvasRight"
                                          >
                                            <a
                                              class="dropdown-item"
                                              href="javascript:void(0)"
                                              onClick={() => {
                                                props.setSelectedValues(item);
                                                props.toggleViewModal();
                                              }}
                                            >
                                              View Details
                                            </a>
                                          </li> */}

                                          <li className="menu-item">
                                            <a
                                              class="dropdown-item"
                                              href="javascript:void(0)"
                                              onClick={() => {
                                                props.setSelectedValues(item);
                                                props.toggleEdit();
                                              }}
                                            >
                                              Edit Part
                                            </a>
                                          </li>
                                          <li className="menu-item">
                                            <a
                                              class="dropdown-item"
                                              href="javascript:void(0)"
                                              onClick={() => {
                                                props.setSelectedValues(item);
                                                props.toggleAiDescription();
                                              }}
                                            >
                                              AI Description
                                            </a>
                                          </li>
                                          <li className="menu-item">
                                            <a
                                              class="dropdown-item"
                                              href="javascript:void(0)"
                                              onClick={() => {
                                                props.setSelectedValues(item);
                                                props.toggleDelete();
                                              }}
                                            >
                                              Delete Part
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </td>
                                  <td style={{ width: "13%", minWidth: "130px" }}>
                                    <span
                                      className="aviation-auction link-item text-wrapper"
                                      onClick={() => {
                                        props.setSelectedValues(item);
                                        props.toggleEdit();
                                      }}
                                    >
                                      {" "}
                                      {item.partNumber ? item.partNumber : ""}
                                    </span>
                                  </td>
                                  <td style={{ width: "15%" }}>
                                    {" "}
                                    <span className="text-wrapper">
                                      {item.partDescription ? item.partDescription : ""}
                                    </span>
                                  </td>
                                  <td style={{ width: "10%" }}>
                                    {" "}
                                    <span className="text-wrapper">
                                      {item.condition
                                        ? `(${item.condition}) ${item.conditionTitle ? item?.conditionTitle : ""}`
                                        : ""}
                                    </span>
                                  </td>
                                  <td align="right" style={{ width: "6%" }}>
                                    {" "}
                                    <span className="text-wrapper">{item.quantity ? item.quantity : ""}</span>
                                  </td>
                                  <td align="right" style={{ width: "10%" }}>
                                    {" "}
                                    <span className="text-wrapper">{item.unit ? item.unit : ""}</span>
                                  </td>
                                  <td align="right" style={{ width: "10%" }}>
                                    {" "}
                                    <span className="text-wrapper">
                                      {item.unitPrice ? twoDecimals(item.unitPrice) : ""}
                                    </span>
                                  </td>
                                  <td style={{ width: "12%" }}>
                                    {" "}
                                    <span className="text-wrapper">{item.location ? item.location : ""}</span>
                                  </td>
                                  <td style={{ width: "10%" }}>
                                    {" "}
                                    <span className="text-wrapper">
                                      {item.partManufacturer ? item.partManufacturer : ""}
                                    </span>
                                  </td>
                                  <td style={{ width: "12%" }}>
                                    <span className="text-wrapper">
                                      {item?.partModels?.length > 0 ? item.partModels?.map((is) => is).join(", ") : ""}
                                    </span>
                                  </td>

                                  <td style={{ width: "12%" }}>
                                    {" "}
                                    <span className="text-wrapper">{item.grade ? item.grade : ""}</span>
                                  </td>
                                  <td style={{ width: "12%" }}>
                                    {" "}
                                    <span className="text-wrapper">{item.serialNumber ? item.serialNumber : ""}</span>
                                  </td>
                                  <td
                                    align="center"
                                    style={{ width: "6%" }}
                                    className={`aviation-auction ${item?.images?.length > 0 ? "link-item" : ""} `}
                                    onClick={() => {
                                      if (item?.images?.length > 0) {
                                        const a = [];
                                        item?.images?.map((de) => {
                                          checkIfImageExists(
                                            `https://s3.amazonaws.com/uploads-dev.salvex.com/public/parts/images/${item?.auctionId}/${de}`,
                                            (exists) => {
                                              if (exists) {
                                                a.push({
                                                  original: `https://s3.amazonaws.com/uploads-dev.salvex.com/public/parts/images/${item?.auctionId}/${de}`,
                                                  thumbnail: `https://s3.amazonaws.com/uploads-dev.salvex.com/public/parts/images/${item?.auctionId}/${de}`,
                                                });
                                              } else {
                                                a.push({
                                                  original:
                                                    "https://media.salvex.com/auction/p/1830012/183001141_641791_mp.jpg",
                                                  thumbnail:
                                                    "https://media.salvex.com/auction/p/1830012/183001141_641791_mp.jpg",
                                                });
                                              }
                                            },
                                          );
                                        });
                                        setSelecetedImage(a);
                                        setTimeout(() => {
                                          toggleImageGalleryModal();
                                        }, 650);
                                      }
                                    }}
                                  >
                                    {item?.images?.length > 0 ? (
                                      <img src={imageFill} alt="Image Exists" />
                                    ) : (
                                      <img src={imageOutLine} alt="Image not exists" />
                                    )}
                                  </td>
                                  <td style={{ width: "10%" }}>
                                    {" "}
                                    {item.auctionId ? (
                                      <a
                                        className="aviation-auction link-item"
                                        href={`/listing-detail?id=${item.auctionId}`}
                                        target="_blank"
                                      >
                                        {item?.auctionId}
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </table>
                  </div>
                  {!props.loading && props.partsData && props.partsData.length === 0 && (
                    <>
                      <div class="table-empty">
                        <div class="table-empty-inner">
                          <img src={emptyFolder} alt="" />
                          <p>No Items Found</p>
                        </div>
                      </div>
                    </>
                  )}

                  <div class="table-pagination d-flex justify-content-between">
                    <div class="showing-result-text d-flex align-items-center">
                      {!props.loading && props?.page && props?.filteredCount ? (
                        `Showing ${(props?.page - 1) * 200 + props?.filteredCount} of ${
                          props?.totalCount
                        } total results`
                      ) : (
                        <Skeleton width="300px" height="25px" />
                      )}
                    </div>
                    <ul class="pagination">
                      <li class="page-item">
                        <a
                          class={`page-link ${props.page === 1 ? "disabled" : ""}`}
                          href="#"
                          aria-label="Previous"
                          onClick={() => {
                            if (props.page !== 1) {
                              props.setPage(props.page - 1);
                            }
                          }}
                        >
                          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </li>
                      {props?.page + 1 > 6 && (
                        <li class={props?.page == 1 ? "page-item active" : "page-item"}>
                          <a class="page-link" href="javascript:void(0)" onClick={() => props.setPage(1)}>
                            1
                          </a>
                        </li>
                      )}
                      {props?.page + 1 > 7 && (
                        <li class={props?.page == 2 ? "page-item active" : "page-item"}>
                          <a class="page-link" href="javascript:void(0)" onClick={() => props.setPage(2)}>
                            2
                          </a>
                        </li>
                      )}
                      {props?.page + 1 > 8 && (
                        <li class={props?.page == 3 ? "page-item active" : "page-item"}>
                          <a class="page-link" href="javascript:void(0)" onClick={() => props.setPage(3)}>
                            3
                          </a>
                        </li>
                      )}
                      {props?.page > 5 ? <li className={"page-item"}>...</li> : ""}
                      {pages?.map(
                        (i) =>
                          i + 1 <= props?.totalPages && (
                            <li class={props?.page == i + 1 ? "page-item active" : "page-item"}>
                              <a class="page-link" href="javascript:void(0)" onClick={() => props.setPage(i + 1)}>
                                {i + 1}
                              </a>
                            </li>
                          ),
                      )}
                      {props?.page < props?.totalPages - 5 && props?.totalPages > 10 ? (
                        <li className={"page-item"}>...</li>
                      ) : (
                        ""
                      )}
                      {props?.page < props?.totalPages - 7 && props?.totalPages > 10 && (
                        <li class={props?.page == props?.totalPages - 2 ? "page-item active" : "page-item"}>
                          <a
                            class="page-link"
                            href="javascript:void(0)"
                            onClick={() => props.setPage(props?.totalPages - 2)}
                          >
                            {props?.totalPages - 2}
                          </a>
                        </li>
                      )}
                      {props?.page < props?.totalPages - 6 && props?.totalPages > 10 && (
                        <li class={props?.page == props?.totalPages - 1 ? "page-item active" : "page-item"}>
                          <a
                            class="page-link"
                            href="javascript:void(0)"
                            onClick={() => props.setPage(props?.totalPages - 1)}
                          >
                            {props?.totalPages - 1}
                          </a>
                        </li>
                      )}
                      {props?.page + 5 < props?.totalPages && props?.totalPages > 10 && (
                        <li class={props?.page == props?.totalPages ? "page-item active" : "page-item"}>
                          <a
                            class="page-link"
                            href="javascript:void(0)"
                            onClick={() => props.setPage(props?.totalPages)}
                          >
                            {props?.totalPages}
                          </a>
                        </li>
                      )}
                      <li class="page-item">
                        <a
                          class={`page-link ${props.page >= props.totalPages ? "disabled" : ""}`}
                          href="#"
                          aria-label="Next"
                          onClick={() => props.setPage(props.page + 1)}
                        >
                          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <UploadParts
                show={props.showUploadPartModal}
                onHide={props.toggleUploadPartModal}
                loadData={props.loadData}
              /> */}
              {/* {props.condition && props.mfr && props.part && props.model && ( */}
              <AviationFilter
                onHide={props.toggleFilter}
                show={props.showFilter}
                setShowFilter={props.setShowFilter}
                filterValues={props.filterValues}
                setFilterValues={props.setFilterValues}
                handleFilter={props.handleFilter}
                id={id}
                setId={setId}
                condition={props.condition}
                mfr={props.mfr}
                part={props.part}
                model={props.model}
                location={props.location}
                selectedFilter={props.selectedFilter}
                setSelectedFilter={props.setSelectedFilter}
                loading={props.loading}
                setPage={props?.setPage}
                response={props?.response}
                setResponse={props?.setResponse}
              />
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
      <ImageLargeModal
        show={imageGalleryModal}
        onHide={toggleImageGalleryModal}
        items={selectedImage}
        startIndex={0}
        title={""}
      />
    </>
  );
};

export default React.memo(PartsTable);
