import React, { useEffect, useRef, useState } from "react";
import "../../include/css/invoice.css";
import logo from "../../include/images/logo.svg";
import { connect } from "react-redux";
import queryString from "query-string";
import { addInvoiceData, getInvoice, getInvoiceData } from "../../store/invoices";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import moment from "moment";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alerts/alertSuccess";
import Editor from "../../common/editor/editor";
import { getBidDetail } from "../../store/bids";
import { twoDecimals } from "../../utils.js/conversion";
import print from "../../include/images/print.svg";
import send from "../../include/images/send-btn.svg";
import HelmetComponent from "../../common/helmetComponent/helmetComponent";

const CreateInvoice = (props) => {
  const history = useHistory();
  const location = useLocation();
  const ref = useRef(null);
  const categoryId = props?.getInvoice?.invoiceData?.data?.category_id;
  const bidId = queryString.parse(location.search).b_id;
  const [loading, setLoading] = useState(false);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [editorConditionData, setEditorConditionData] = useState("");
  const [editorSellerData, setEditorSellerData] = useState("");
  const [editorBuyerData, setEditorBuyerData] = useState("");
  const [editorPaymentData, setEditorPaymentData] = useState("");
  const [inspection, setInspection] = useState(false);
  const [values, setValues] = useState({
    b_id: 0,
    deposit: false,
    member_id: 0,
    buyer_id: 0,

    invoice_number: "",
    to_name: "",
    to_email: "",
    mm_name: "",
    mm_email: "",

    company_name: "",
    todate: "",
    contact: ", P: F:",
    fob: "",

    po: "",
    rep: "",

    quantity: "",
    unit_bid: "",
    description: "",
    total_bid: 0,

    credit: 0,
    inspection_deposit: 0,
    sales_tax: 0,
    total_due: 0,

    condition: "",
    seller_terms: "",
    buyer_terms: "",
    payment_terms: "",

    printMode: false,
    SalesTaxRate: 0,
  });

  useEffect(() => {
    document.body.classList.add("p-0");

    return () => document.body.classList.remove("p-0");
  }, []);

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  const onChangeInpection = () => {
    const data = { ...values };
    data.inspection_deposit = "0.00";
    data.sales_tax = "0.00";
    data.SalesTaxRate = "0.00";
    setValues(data);
    setInspection(!inspection);
  };

  //Get Invoice payment Text
  const getInvoicePaymentText = () => {
    switch (categoryId) {
      case 1008:
        return (
          <div className="three-column-row">
            <div className="three-column-col">
              <div className="t-c-box">
                <address>
                  Salvex Metals, Inc. <br />
                  440 Louisiana St. Ste. 550,
                  <br /> Houston, TX 77002 <br />
                  Account: 1883189324
                </address>
              </div>
            </div>
            <div className="three-column-col">
              <div className="t-c-box">
                <div className="t-c-row">
                  <div className="t-c-label">ACH : </div>
                  <div className="t-c-number">111000753</div>
                </div>
                <div className="t-c-row">
                  <div className="t-c-label">ABA Wires : </div>
                  <div className="t-c-number">111000753</div>
                </div>
                <div className="t-c-row">
                  <div className="t-c-label">Swift Code :</div>
                  <div className="t-c-number"> MNBDUS33</div>
                </div>
              </div>
            </div>
            <div className="three-column-col">
              <div className="t-c-box">
                <div className="t-c-row">
                  <address>
                    Comerica Bank, NA 5757 Memorial Dr.
                    <br /> Houston, TX 77007
                  </address>
                </div>
              </div>
            </div>
          </div>
        );
      case 1176:
        return (
          <div className="three-column-row">
            <div className="three-column-col">
              <div className="t-c-box">
                <address>
                  Salvex Aviation
                  <br />
                  440 Louisiana St. Ste. 550
                  <br />
                  Houston, TX 77002
                  <br />
                  Account: 3684202645
                </address>
              </div>
            </div>
            <div className="three-column-col">
              <div className="t-c-box">
                <div className="t-c-row">
                  <div className="t-c-label">ACH : </div>
                  <div className="t-c-number">111900659</div>
                </div>
                <div className="t-c-row">
                  <div className="t-c-label">ABA Wires : </div>
                  <div className="t-c-number">121000248</div>
                </div>
                <div className="t-c-row">
                  <div className="t-c-label">Swift Code :</div>
                  <div className="t-c-number">WFBIUS6S</div>
                </div>
              </div>
            </div>
            <div className="three-column-col">
              <div className="t-c-box">
                <address>
                  Wells Fargo Bank, N.A.
                  <br />
                  1500 Waugh Dr.
                  <br />
                  1st Floor Houston, TX 77019
                </address>
              </div>
            </div>
          </div>
        );
      case 1395:
        return (
          <div className="three-column-row">
            <div className="three-column-col">
              <div className="t-c-box">
                <address>
                  Salvex Solar, Inc. <br />
                  440 Louisiana St. Ste. 550,
                  <br /> Houston, TX 77002 <br />
                  Account: 5289608977
                </address>
              </div>
            </div>
            <div className="three-column-col">
              <div className="t-c-box">
                <div className="t-c-row">
                  <div className="t-c-label">ACH : </div>
                  <div className="t-c-number">111900659</div>
                </div>
                <div className="t-c-row">
                  <div className="t-c-label">ABA Wires : </div>
                  <div className="t-c-number">121000248</div>
                </div>
                <div className="t-c-row">
                  <div className="t-c-label">Swift Code :</div>
                  <div className="t-c-number">WFBIUS6S</div>
                </div>
              </div>
            </div>
            <div className="three-column-col">
              <div className="t-c-box">
                <address>
                  Wells Fargo Bank, N.A.
                  <br />
                  1500 Waugh Dr.
                  <br />
                  1st Floor Houston, TX 77019
                </address>
              </div>
            </div>
          </div>
        );
      case 1294:
      case 1384:
        return (
          <div className="three-column-row">
            <div className="three-column-col">
              <div className="t-c-box">
                <address>
                  Salvex Energy, Inc. <br />
                  440 Louisiana St. Ste. 550,
                  <br /> Houston, TX 77002 <br />
                  Account: 8424754425
                </address>
              </div>
            </div>
            <div className="three-column-col">
              <div className="t-c-box">
                <div className="t-c-row">
                  <div className="t-c-label">ACH : </div>
                  <div className="t-c-number">111900659</div>
                </div>
                <div className="t-c-row">
                  <div className="t-c-label">ABA Wires : </div>
                  <div className="t-c-number">121000248</div>
                </div>
                <div className="t-c-row">
                  <div className="t-c-label">Swift Code :</div>
                  <div className="t-c-number">WFBIUS6S</div>
                </div>
              </div>
            </div>
            <div className="three-column-col">
              <div className="t-c-box">
                <address>
                  Wells Fargo Bank, N.A.
                  <br />
                  1500 Waugh Dr.
                  <br />
                  1st Floor Houston, TX 77019
                </address>
              </div>
            </div>
          </div>
        );
      case 1002:
        return (
          <div className="three-column-row">
            <div className="three-column-col">
              <div className="t-c-box">
                <address>
                  Salvex Food, Inc. <br />
                  440 Louisiana St. Ste. 550,
                  <br /> Houston, TX 77002 <br />
                  Account: 5798583505
                </address>
              </div>
            </div>
            <div className="three-column-col">
              <div className="t-c-box">
                <div className="t-c-row">
                  <div className="t-c-label">ACH/ABA Routing : </div>
                  <div className="t-c-number">113011258</div>
                </div>
                <div className="t-c-row">
                  <div className="t-c-label">Swift Code :</div>
                  <div className="t-c-number">ZFNBUS55</div>
                </div>
              </div>
            </div>
            <div className="three-column-col">
              <div className="t-c-box">
                <address>
                  Amegy Ban, N.A.
                  <br />
                  1711 West Loop S.
                  <br />
                  Houston, TX 77027
                </address>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="three-column-row">
            <div className="three-column-col">
              <div className="t-c-box">
                <address>
                  Salvex Commodities, Inc. <br />
                  440 Louisiana St. Ste. 550,
                  <br /> Houston, TX 77002 <br />
                  Account: 1883189308
                </address>
              </div>
            </div>
            <div className="three-column-col">
              <div className="t-c-box">
                <div className="t-c-row">
                  <div className="t-c-label">ACH : </div>
                  <div className="t-c-number">111000753</div>
                </div>
                <div className="t-c-row">
                  <div className="t-c-label">ABA Wires : </div>
                  <div className="t-c-number">111000753</div>
                </div>
                <div className="t-c-row">
                  <div className="t-c-label">Swift Code :</div>
                  <div className="t-c-number">MNBDUS33</div>
                </div>
              </div>
            </div>
            <div className="three-column-col">
              <div className="t-c-box">
                <div className="t-c-row">
                  <address>
                    Comerica Bank, NA 5757 Memorial Dr.
                    <br /> Houston, TX 77007
                  </address>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  useEffect(() => {
    ref && ref.current && ref.current.continuousStart();
    const id = queryString.parse(location.search).id;
    props.getInvoiceData(id, bidId, (res) => {
      if (res && res.status == 200) {
        const response = res.data?.data;
        const data = { ...values };
        data.mm_name = response.mm_name ? response.mm_name : "";
        data.mm_email = response.mm_email ? response.mm_email : "";
        data.company_name = response.company_name ? response.company_name : "";

        data.todate = moment().format("L");
        data.quantity = response.quantity
          ? response.quantity + " " + (response.unit ? response?.unit : "")
          : 0 + " " + (response.unit ? response?.unit : "");
        data.contact = response.address_line_1 ? response.address_line_1 : ", P: F:";
        data.rep = response.mm_name ? response.mm_name : "";
        data.unit_bid = response.unit_bid ? response.unit_bid : "";
        data.description = response.title ? response.title : "";
        data.total_bid = response.total_bid
          ? new Intl.NumberFormat("en-US", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(response.total_bid)
          : "0.00";
        data.credit = response.credit
          ? new Intl.NumberFormat("en-US", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(response.credit)
          : "0.00";
        data.inspection_deposit = response.inspection_deposit
          ? new Intl.NumberFormat("en-US", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(response.inspection_deposit)
          : "0.00";
        data.sales_tax = response.sales_tax ? response.sales_tax : 0;
        data.condition = response.condition ? response.condition : "";
        data.payment_terms = "Payment of this invoice is due within 48 hours via electronic wire transfer.";
        data.invoice_number = response.auction_id ? response.auction_id + "-" + response.b_id + response.icount : "";
        data.buyer_id = response.buyer_id ? response.buyer_id : 0;
        data.member_id = response?.member_id?.length > 0 ? response?.member_id[0] : 0;
        data.SalesTaxRate = response.SalesTaxRate ? response.SalesTaxRate : 0;
        if (bidId) {
          props.getBidDetail(id, bidId, (res) => {
            if (res.status == 200) {
              ref && ref.current && ref.current.complete();
              const detail = res.data.data;
              data.b_id = detail.b_id ? detail.b_id : 0;
              data.to_name = detail.name ? detail.name : "";
              data.to_email = detail.email ? detail.email : "";
              data.company_name = detail.company_name ? detail.company_name : "";
              data.contact =
                (detail.name ? detail.name : "") +
                (detail.address_line_1 ? detail.address_line_1 : "") +
                (detail.address_line_2 ? detail.address_line_2 : "") +
                (detail.city ? detail.city + ", " : "") +
                (response.b_country ? response.b_country + " " : "") +
                (detail.zip ? detail.zip : "") +
                " P: " +
                (detail.phone ? detail.phone : "") +
                " F: " +
                (detail.fax ? detail.fax : "");
              data.unit_bid = detail.unit_bid ? detail.unit_bid : "";
              data.total_bid = detail.total_bid
                ? new Intl.NumberFormat("en-US", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(detail.total_bid)
                : "0.00";
              data.buyer_terms = detail.terms ? detail.terms : "";
              setValues(data);
            }
          });
        }
      }
    });
  }, []);

  const getTotal = () => {
    const total_bid = String(values?.total_bid)?.replace(/[^0-9.]/g, "");
    const sales_tax = String(values?.sales_tax)?.replace(/[^0-9.]/g, "");
    const inspection_deposit = String(values?.inspection_deposit)?.replace(/[^0-9.]/g, "");
    const credit = String(values?.credit)?.replace(/[^0-9.]/g, "");

    return +total_bid + +sales_tax - +inspection_deposit - +credit;
  };

  const onChange = (e) => {
    const data = { ...values };
    data[e.target.name] = e.target.value;
    setValues(data);
  };

  const onChangeNum = (e) => {
    const data = { ...values };
    data[e.target.name] = e.target.value.replace(/[^0-9.]/g, "");
    setValues(data);
  };
  const onBlur = (e) => {
    const data = { ...values };
    data[e.target.name] = new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(data[e.target.name].replace(/[^0-9.]/g, ""));
    setValues(data);
  };

  const sendHandler = (print = false) => {
    ref && ref.current && ref.current.continuousStart();
    setLoading(true);

    const id = queryString.parse(location.search).id;
    const payload = {
      b_id: bidId ? bidId : 0,
      deposit: false,
      member_id: values?.member_id ? values.member_id : 0,
      buyer_id: values?.buyer_id ? values.buyer_id : 0,

      invoice_number: values?.invoice_number,
      to_name: values?.to_name ? values.to_name : "",
      to_email: values?.to_email ? values.to_email : "",
      mm_name: values?.mm_name ? values.mm_name : "",
      mm_email: values?.mm_email ? values.mm_email : "",

      company_name: values?.company_name ? values.company_name : "",
      todate: values?.todate ? values.todate : "",
      contact: values?.contact ? values.contact.replace(/\n/g, "<br>") : "",
      fob: values?.fob ? values.fob : "",

      po: values?.po ? values.po : "",
      rep: values?.rep ? values.rep : "",

      quantity: values?.quantity ? values.quantity : "0.00",
      unit_bid: values?.unit_bid
        ? new Intl.NumberFormat("en-US", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(values.unit_bid)
        : "0.00",
      description: values.description ? values.description : "",
      total_bid: values?.total_bid ? `${values.total_bid}` : "0.00",

      credit: values?.credit ? values.credit : "0.00",
      inspection_deposit: values?.inspection_deposit ? values.inspection_deposit : "0.00",
      sales_tax: values?.sales_tax
        ? new Intl.NumberFormat("en-US", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(values.sales_tax)
        : "0.00",
      total_due: `${new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(getTotal())}`,

      condition: values?.condition ? values?.condition.replace(/\n/g, "<br>") : "",
      seller_terms: values?.seller_terms ? values?.seller_terms.replace(/\n/g, "<br>") : "",
      buyer_terms: values?.buyer_terms ? values?.buyer_terms.replace(/\n/g, "<br>") : "",
      payment_terms: values?.payment_terms ? values?.payment_terms.replace(/\n/g, "<br>") : "",
      is_inspection_deposit: inspection,
      printMode: print,
    };
    props.addInvoiceData(id, payload, (res) => {
      if (res && res.status == 200) {
        if (print) {
          clickToPrint(res.data?.data);
        } else {
          history.replace(`/listing-detail?id=${id}`);
          toast(<AlertSuccess message={"Information Saved"} />);
        }
        ref && ref.current && ref.current.complete();
        setLoading(false);
      }
    });
  };

  const clickToPrint = (data) => {
    let pri =
      document.getElementById("ifmcontentstoprint") && document.getElementById("ifmcontentstoprint").contentWindow;
    if (pri) {
      pri.document.open();
      pri.document.write(data);
      pri.document.close();
      pri.focus();
      setTimeout(() => pri.print(), 100);
    }
  };

  const printHandler = () => {
    sendHandler(true);
  };

  const invoiceData = props.getInvoice && props.getInvoice?.invoiceData && props.getInvoice?.invoiceData.data;

  function getImageViaCategory() {
    let invoice_image = "https://s3.amazonaws.com/media-dev.salvex.com/emails/salvex2x.png";

    switch (categoryId) {
      case 1008:
        invoice_image = `https://s3.amazonaws.com/media-dev.salvex.com/emails/salvex2x.png`;
        break;
      case 1395:
        invoice_image = `https://s3.amazonaws.com/media-dev.salvex.com/emails/Salvex+Solar.png`;
        break;
      case 1294:
      case 1384:
        invoice_image = `https://s3.amazonaws.com/media-dev.salvex.com/emails/Salvex+Energy.png`;
        break;
      case 1002:
        invoice_image = `https://s3.amazonaws.com/media-dev.salvex.com/emails/Salvex+Food+Inc.png`;
        break;
      case 1176:
        invoice_image = `https://s3.amazonaws.com/media-dev.salvex.com/emails/Salvex+Aviation.png`;
        break;
      default:
        invoice_image = `https://s3.amazonaws.com/media-dev.salvex.com/emails/salvex2x.png`;
        break;
    }

    return invoice_image;
  }

  return (
    <div className="create-invoice">
      <iframe id="ifmcontentstoprint" style={{ height: "0p", width: "0px", position: "absolute" }}></iframe>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <HelmetComponent
        title={values?.invoice_number ? `Invoice ${values?.invoice_number}` : "Invoice"}
        desription={values?.invoice_number ? `Invoice ${values?.invoice_number}` : "Invoice"}
      />
      <html lang="en">
        <head>
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>Salvex</title>
          <link rel="icon" href="include/images/favicon.png" sizes="32x32" />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
            rel="stylesheet"
          />
          <link href="style.css" rel="stylesheet" type="text/css" />
        </head>
        <body>
          <div class="wep-page-wrapper">
            <div class="wp-inside-wrapper">
              <div class="page-head-wrap">
                <div class="ph-left">
                  <div class="ph-btn-group">
                    <div class="ph-btn-item">
                      <button type="button" class="btn btn-default" onClick={() => printHandler()}>
                        <img class="asc-icon" src={print} alt="" />
                        <span className="ms-3">Print</span>
                      </button>
                    </div>
                    <div class="ph-btn-item">
                      <button type="button" class="btn btn-primary" onClick={() => sendHandler()}>
                        <img class="asc-icon" src={send} alt="" />
                        <span className="ms-3">Send</span>
                      </button>
                    </div>
                  </div>
                  <h4>INVOICE</h4>
                  <div class="ph-invoice-text">
                    Invoice # : <span>{values?.invoice_number}</span>
                  </div>
                </div>
                <div class="ph-right">
                  <div class="ph-logo">
                    <img src={getImageViaCategory()} alt="" />
                  </div>
                  <div class="ph-address">
                    440 Louisiana St. Ste. 550 <br /> Houston, TX 77002
                  </div>
                  <div class="ph-link-box d-flex justify-content-end">
                    <div>
                      <strong>Phone :</strong> 713 229 9000
                    </div>
                  </div>
                  <div class="ph-checkbox-holder">
                    <div class="form-check tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="checkOne"
                        checked={inspection}
                        name="inspection"
                        onChange={() => {
                          const data = { ...values };
                          values.inspection_deposit = 0;
                          setValues(data);
                          onChangeInpection();
                        }}
                      />
                      <label class="form-check-label" for="checkOne">
                        Inspection Invoice
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="page-divider"></div>
              <div class="input-group-row">
                <div class="igr-column">
                  <div class="igr-box">
                    <label for="">To</label>
                    <div class="igr-input-group">
                      <div class="igr-input">
                        <input
                          type="text"
                          placeholder="Name"
                          class="form-control"
                          name="to_name"
                          value={values?.to_name}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                      <div class="igr-input">
                        <input
                          type="text"
                          placeholder="Email"
                          class="form-control"
                          name="to_email"
                          value={values?.to_email}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="igr-column">
                  <div class="igr-box">
                    <label for="">From</label>
                    <div class="igr-input-group">
                      <div class="igr-input">
                        <input
                          type="text"
                          placeholder="Name"
                          class="form-control"
                          name="mm_name"
                          value={values?.mm_name}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                      <div class="igr-input">
                        <input
                          type="text"
                          placeholder="Email"
                          class="form-control"
                          name="mm_email"
                          value={values?.mm_email}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-two-group-row">
                <div class="input-two-column">
                  <div class="input-two-wrap">
                    <div class="input-two-col">
                      <label for="">Sold To</label>
                      <input
                        type="text"
                        placeholder=""
                        class="form-control"
                        name="company_name"
                        value={values?.company_name}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                    <div class="input-two-col">
                      <label for="">Date</label>
                      <input
                        type="text"
                        placeholder=""
                        class="form-control"
                        name="todate"
                        value={values?.todate}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div class="input-two-column">
                  <div class="input-two-wrap">
                    <div class="input-two-col">
                      <label for="">PO #</label>
                      <input
                        type="text"
                        placeholder=""
                        class="form-control"
                        name="po"
                        value={values?.po}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                    <div class="input-two-col">
                      <label for="">Rep</label>
                      <input
                        type="text"
                        placeholder=""
                        class="form-control"
                        name="rep"
                        value={values?.rep}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-group-row">
                <div class="igr-full-column">
                  <div class="igr-box">
                    <label for="">Contact</label>
                    <div class="igr-input-group">
                      <div class="igr-input">
                        <textarea
                          class="form-control"
                          id=""
                          placeholder=""
                          name="contact"
                          value={values?.contact}
                          onChange={(e) => onChange(e)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-wapper">
                <table class="table">
                  <thead>
                    <tr>
                      <th style={{ width: "15%" }}>Quantity</th>
                      <th style={{ width: "16%" }}>Unit Price</th>
                      <th>Description</th>
                      <th style={{ width: "20%" }}>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="text"
                          className="text-input"
                          name="quantity"
                          value={values?.quantity}
                          onChange={(e) => onChange(e)}
                        />
                      </td>
                      <td align="right">
                        <div class="input-group">
                          <span class="input-group-text" id="basic-addon1">
                            $
                          </span>
                          <input type="text" name="unit_bid" value={values?.unit_bid} onChange={(e) => onChange(e)} />
                        </div>
                      </td>
                      <td>
                        <textarea
                          type="text"
                          className="text-input-area"
                          name="description"
                          value={values?.description}
                          onChange={(e) => onChange(e)}
                        />
                      </td>
                      <td align="right">
                        <div class="input-group">
                          <span class="input-group-text" id="basic-addon1">
                            $
                          </span>
                          <input
                            name="total_bid"
                            value={values?.total_bid}
                            type="text"
                            onChange={(e) => onChangeNum(e)}
                            onBlur={(e) => onBlur(e)}
                          />
                        </div>
                      </td>
                    </tr>
                    {inspection ? (
                      <>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>
                            <strong>Total Amount</strong>
                          </td>
                          <td align="right">
                            <div class="input-group">
                              <span class="input-group-text" id="basic-addon1">
                                $
                              </span>
                              <input
                                name="total_bid"
                                value={values?.total_bid}
                                type="text"
                                onChange={(e) => onChangeNum(e)}
                                onBlur={(e) => onBlur(e)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>
                            <strong>(Credit)</strong>
                          </td>
                          <td align="right">
                            <div class="input-group">
                              <span class="input-group-text" id="basic-addon1">
                                $
                              </span>
                              <input
                                name="credit"
                                value={values?.credit}
                                type="text"
                                onChange={(e) => onChangeNum(e)}
                                onBlur={(e) => onBlur(e)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>
                            <strong>Deposit Due Now</strong>
                          </td>
                          <td align="right">
                            <div class="input-group">
                              <span class="input-group-text" id="basic-addon1">
                                $
                              </span>
                              <input
                                name="inspection_deposit"
                                value={values?.inspection_deposit}
                                type="text"
                                onChange={(e) => onChangeNum(e)}
                                onBlur={(e) => onBlur(e)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>
                            <strong>Balance Due Later</strong>
                          </td>
                          <td align="right">
                            <div class="input-group">
                              <span class="input-group-text" id="basic-addon1">
                                $
                              </span>
                              <input
                                type="text"
                                disabled
                                name="total_due"
                                value={new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(getTotal())}
                                onChange={(e) => onChange(e)}
                              />
                            </div>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>
                            <strong>(Credit)</strong>
                          </td>
                          <td align="right">
                            <div class="input-group">
                              <span class="input-group-text" id="basic-addon1">
                                $
                              </span>
                              <input
                                name="credit"
                                value={values?.credit}
                                type="text"
                                onChange={(e) => onChangeNum(e)}
                                onBlur={(e) => onBlur(e)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>
                            <strong>
                              {" "}
                              Sales Tax<sup>*</sup>
                            </strong>
                          </td>
                          <td align="right">
                            <div className="d-flex justify-content-end">
                              <div class="input-group-st me-2">
                                <span class="input-group-text" id="basic-addon1">
                                  %
                                </span>
                                <input
                                  type="number"
                                  className="sales-tax-input"
                                  name="SalesTaxRate"
                                  style={{ width: "80%" }}
                                  value={values?.SalesTaxRate}
                                  onChange={(e) => onChange(e)}
                                  onBlur={(e) => {
                                    const data = { ...values };
                                    data.SalesTaxRate = Number(data.SalesTaxRate).toFixed(2);
                                    data.sales_tax =
                                      String(data.total_bid)?.replace(/[^0-9.]/g, "") * (data?.SalesTaxRate / 100);
                                    setValues(data);
                                  }}
                                />
                              </div>
                              <div class="input-group">
                                <span class="input-group-text" id="basic-addon1">
                                  $
                                </span>
                                <input
                                  type="text"
                                  name="sales_tax"
                                  disabled={true}
                                  value={new Intl.NumberFormat("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(values?.sales_tax)}
                                  onChange={(e) => onChange(e)}
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>
                            <strong>Total Due</strong>
                          </td>
                          <td align="right">
                            <div class="input-group">
                              <span class="input-group-text" id="basic-addon1">
                                $
                              </span>
                              <input
                                type="text"
                                disabled
                                name="total_due"
                                value={new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(getTotal())}
                                onChange={(e) => onChange(e)}
                              />
                            </div>
                          </td>
                        </tr>
                      </>
                    )}
                    <tr>
                      <td>
                        <strong>Item Condition</strong>
                      </td>
                      <td colspan="4">
                        <textarea
                          className="text-area"
                          name="condition"
                          value={values?.condition}
                          onChange={(e) => onChange(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Seller Terms</strong>
                      </td>
                      <td colspan="4">
                        <textarea
                          className="text-area"
                          name="seller_terms"
                          value={values?.seller_terms}
                          onChange={(e) => onChange(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Buyer Terms</strong>
                      </td>
                      <td colspan="4">
                        <textarea
                          className="text-area"
                          name="buyer_terms"
                          value={values?.buyer_terms}
                          onChange={(e) => onChange(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Payment Terms</strong>
                      </td>
                      <td colspan="4">
                        <textarea
                          className="text-area"
                          name="payment_terms"
                          value={values?.payment_terms}
                          onChange={(e) => onChange(e)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <>{getInvoicePaymentText()}</>
              <div class="page-divider"></div>
              <div class="page-bottom-row">
                <p>* Sales tax calculated against contracted amount to seller not seen on this invoice</p>
                <p>
                  This product has been purchased "AS IS, WHERE IS." Seller does not offer any warranties or
                  manufacturer warranties. Buyer is responsible for all taxes, duties, loading and freight charges. Upon
                  receipt of cleared funds, Seller will provide Buyer a Release Order for item pick up. Title transfers
                  from Seller to Buyer upon issuance of the Release Order. Buyer understands that these products are
                  sold as salvage and do not fall under normal trade practices of various industries.
                </p>
                <p>
                  Salvex does not take title to the goods at any time. Please be sure to take precautions to understand
                  the condition and usability of the product you have purchased. Salvex is not responsible for
                  shortages, quality of merchandise, marketability or condition. It is Buyer's responsibility to verify
                  quantities and condition at the time of pick up. Once the product has been removed from Seller's
                  location, there will be no adjustments based on quantity or condition. Unless other arrangements have
                  been made with the Seller, any product not picked up within 5 days of the Release Order shall be
                  deemed abandoned and title will revert to the Seller. Buyers forfeit any right to a refund of the
                  purchase price for abandoned goods.
                </p>
              </div>
            </div>
          </div>
        </body>
      </html>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getInvoiceData: (params, bidId, callback) => dispatch(getInvoiceData(params, bidId, callback)),
  getBidDetail: (params, bidId, callback) => dispatch(getBidDetail(params, bidId, callback)),
  addInvoiceData: (params, data, callback) => dispatch(addInvoiceData(params, data, callback)),
});

const mapStateToProps = (state) => ({
  getInvoice: getInvoice(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CreateInvoice));
