import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "users",
  initialState: {
    profile: {},
    paymentInfo: {},
    paymentInform: {},
    verificationDoc: {},
    profileOptions: {},
    pipeLog: {},
    industries: [],
    countries: [],
    states: [],
    categories: [],
    userReport: [],
    countryReport: {},
    browserReport: {},
    osReport: {},
    overallReport: {},
    monthReport: {},
    dayReport: {},
    categoryPreference: [],
    manufacturer: [],
    manufacturePreference: [],
  },
  reducers: {
    profileRequested: (misc, action) => {
      misc.loading = true;
    },
    profileReceived: (misc, action) => {
      misc.profile = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    profileRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    paymentInfoRequested: (misc, action) => {
      misc.loading = true;
    },
    paymentInfoReceived: (misc, action) => {
      misc.paymentInfo = action.payload || {};
      misc.loading = false;
    },
    paymentInfoRequestFailed: (misc, action) => {
      misc.paymentInfo = {};
      misc.loading = false;
    },
    paymentInformRequested: (misc, action) => {
      misc.loading = true;
    },
    paymentInformReceived: (misc, action) => {
      misc.paymentInform = action.payload || {};
      misc.loading = false;
    },
    paymentInformRequestFailed: (misc, action) => {
      misc.paymentInform = {};
      misc.loading = false;
    },
    verificationDocRequested: (misc, action) => {
      misc.loading = true;
    },
    verificationDocRecieved: (misc, action) => {
      misc.verificationDoc = action.payload;
      misc.loading = false;
    },
    verificationDocFailed: (misc, action) => {
      misc.loading = false;
    },
    profileOptionsRequested: (misc, action) => {
      misc.loading = true;
    },
    profileOptionsRecieved: (misc, action) => {
      misc.profileOptions = action.payload;
      misc.loading = false;
    },
    profileOptionsFailed: (misc, action) => {
      misc.loading = false;
    },

    pipeLogRequested: (misc, action) => {
      misc.loading = true;
    },
    pipeLogRecieved: (misc, action) => {
      misc.pipeLog = action.payload;
      misc.loading = false;
    },
    pipeLogFailed: (misc, action) => {
      misc.loading = false;
    },
    industryRequested: (misc, action) => {
      misc.loading = true;
    },
    industryRecieved: (misc, action) => {
      misc.industries = action.payload;
      misc.loading = false;
    },
    industryFailed: (misc, action) => {
      misc.loading = false;
    },
    countryRequested: (misc, action) => {
      misc.loading = true;
    },
    countryRecieved: (misc, action) => {
      misc.countries = action.payload;
      misc.loading = false;
    },
    countryFailed: (misc, action) => {
      misc.loading = false;
    },
    stateRequested: (misc, action) => {
      misc.loading = true;
    },
    stateRecieved: (misc, action) => {
      misc.states = action.payload;
      misc.loading = false;
    },
    stateFailed: (misc, action) => {
      misc.loading = false;
    },
    categoryRequested: (misc, action) => {
      misc.loading = true;
    },
    categoryRecieved: (misc, action) => {
      misc.categories = action.payload;
      misc.loading = false;
    },
    categoryFailed: (misc, action) => {
      misc.loading = false;
    },
    userReportRequested: (misc, action) => {
      misc.loading = true;
    },
    userReportRecieved: (misc, action) => {
      misc.userReport = action.payload;
      misc.loading = false;
    },
    userReportFailed: (misc, action) => {
      misc.loading = false;
    },
    countryReportRequested: (misc, action) => {
      misc.loading = true;
    },
    countryReportRecieved: (misc, action) => {
      misc.countryReport = action.payload;
      misc.loading = false;
    },
    countryReportFailed: (misc, action) => {
      misc.loading = false;
    },
    browserReportRequested: (misc, action) => {
      misc.loading = true;
    },
    browserReportRecieved: (misc, action) => {
      misc.browserReport = action.payload;
      misc.loading = false;
    },
    browserReportFailed: (misc, action) => {
      misc.loading = false;
    },
    osReportRequested: (misc, action) => {
      misc.loading = true;
    },
    osReportRecieved: (misc, action) => {
      misc.osReport = action.payload;
      misc.loading = false;
    },
    osReportFailed: (misc, action) => {
      misc.loading = false;
    },
    overallReportRequested: (misc, action) => {
      misc.loading = true;
    },
    overallReportRecieved: (misc, action) => {
      misc.overallReport = action.payload;
      misc.loading = false;
    },
    overallReportFailed: (misc, action) => {
      misc.loading = false;
    },
    monthReportRequested: (misc, action) => {
      misc.loading = true;
    },
    monthReportRecieved: (misc, action) => {
      misc.monthReport = action.payload;
      misc.loading = false;
    },
    monthReportFailed: (misc, action) => {
      misc.loading = false;
    },
    dayReportRequested: (misc, action) => {
      misc.loading = true;
    },
    dayReportRecieved: (misc, action) => {
      misc.dayReport = action.payload;
      misc.loading = false;
    },
    dayReportFailed: (misc, action) => {
      misc.loading = false;
    },
    categoryPreferenceRequested: (misc, action) => {
      misc.loading = true;
    },
    categoryPreferenceRecieved: (misc, action) => {
      misc.categoryPreference = action.payload;
      misc.loading = false;
    },
    categoryPreferenceFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  profileRequestFailed,
  profileReceived,
  profileRequested,
  paymentInfoRequested,
  paymentInfoReceived,
  paymentInfoRequestFailed,
  paymentInformRequested,
  paymentInformReceived,
  paymentInformRequestFailed,
  verificationDocRequested,
  verificationDocRecieved,
  verificationDocFailed,
  profileOptionsRequested,
  profileOptionsRecieved,
  profileOptionsFailed,
  pipeLogRequested,
  pipeLogRecieved,
  pipeLogFailed,
  industryRequested,
  industryRecieved,
  industryFailed,
  countryRequested,
  countryRecieved,
  countryFailed,
  stateRequested,
  stateRecieved,
  stateFailed,
  categoryRequested,
  categoryRecieved,
  categoryFailed,
  userReportRequested,
  userReportRecieved,
  userReportFailed,
  countryReportRequested,
  countryReportRecieved,
  countryReportFailed,
  browserReportRequested,
  browserReportRecieved,
  browserReportFailed,
  osReportRequested,
  osReportRecieved,
  osReportFailed,
  overallReportRequested,
  overallReportRecieved,
  overallReportFailed,
  monthReportRequested,
  monthReportRecieved,
  monthReportFailed,
  dayReportRequested,
  dayReportRecieved,
  dayReportFailed,
  categoryPreferenceRequested,
  categoryPreferenceRecieved,
  categoryPreferenceFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const authUrl = "auth/admin/login";
const paymentInfo = "user/paymentInfo";
const verificationDoc = "user/document";
const profileOptions = "user/profile";
const verifypaymentInfo = "user/verify";
const pipeLog = "user/pipeLog";
const onBoardingUrl = "onboarding";
const commonUrl = "common";
const userReportUrl = "user/report";
const loginReportUrl = "loginReport";
const categoryPreferenceUrl = "onboarding/categoryPreference";

export const signIn = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: authUrl,
      method: "POST",
      data,
      callback,
      onStart: profileRequested.type,
      onSuccess: profileReceived.type,
      onError: profileRequestFailed.type,
    }),
  );
};

export const getPaymentIfo = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: paymentInfo + "/" + data,
      method: "get",
      data,
      callback,
      onStart: paymentInfoRequested.type,
      onSuccess: paymentInfoReceived.type,
      onError: paymentInfoRequestFailed.type,
    }),
  );
};
export const getPaymentIform = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: paymentInfo + "/" + data,
      method: "get",
      data,
      callback,
      onStart: paymentInformRequested.type,
      onSuccess: paymentInformReceived.type,
      onError: paymentInformRequestFailed.type,
    }),
  );
};
export const savePaymentIfo = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: paymentInfo + "/" + params,
      method: "post",
      data,
      callback,
    }),
  );
};

//get verification document
export const getVerificationDoc = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${verificationDoc}/${params}`,
      method: "get",
      callback,
      onStart: verificationDocRequested.type,
      onSuccess: verificationDocFailed.type,
      onError: verificationDocFailed.type,
    }),
  );
};

//Get Profile
export const getProfileOptions = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${profileOptions}/${params}`,
      method: "get",
      callback,
      onStart: profileOptionsRequested.type,
      onSuccess: profileOptionsRecieved.type,
      onError: profileOptionsFailed.type,
    }),
  );
};

export const verifyPaymentIfo = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: verifypaymentInfo + "/" + params,
      method: "put",
      callback,
    }),
  );
};

export const getPipeLog = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: pipeLog,
      params,
      method: "get",
      callback,
      onStart: pipeLogRequested.type,
      onSuccess: pipeLogRecieved.type,
      onError: pipeLogFailed.type,
    }),
  );
};

//Get Industry
export const getIndustry = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${onBoardingUrl}/getIndustry`,
      method: "get",
      callback,
      onStart: industryRequested.type,
      onSuccess: industryRecieved.type,
      onError: industryFailed.type,
    }),
  );
};

//Get Country
export const getCountry = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${onBoardingUrl}/getCountry`,
      method: "get",
      callback,
      onStart: countryRequested.type,
      onSuccess: countryRecieved.type,
      onError: countryFailed.type,
    }),
  );
};

//Get States
export const getState = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${onBoardingUrl}/getState`,
      method: "get",
      callback,
      onStart: stateRequested.type,
      onSuccess: stateRecieved.type,
      onError: stateFailed.type,
    }),
  );
};

//Add User
export const addUser = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${onBoardingUrl}/addUser`,
      method: "post",
      data,
      callback,
    }),
  );
};

//Edit User

export const editUser = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/user/profile/${id}`,
      method: "put",
      data,
      callback,
    }),
  );
};

//Get Categories
export const getCategories = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${commonUrl}/category`,
      method: "get",
      callback,
      onStart: categoryRequested.type,
      onSuccess: categoryRecieved.type,
      onErro: categoryFailed.type,
    }),
  );
};

//Get User Report
export const getUserReport = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${userReportUrl}?${params}`,
      method: "get",
      callback,
      onStart: userReportRequested.type,
      onSuccess: userReportRecieved.type,
      onError: userReportFailed.type,
    }),
  );
};

//Get Country Report
export const getCountryReport = (days, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${loginReportUrl}/country?pastDays=${days}`,
      method: "get",
      callback,
      onStart: countryReportRequested.type,
      onSuccess: countryReportRecieved.type,
      onError: countryReportFailed.type,
    }),
  );
};

//Get Browser Report
export const getBrowserReport = (days, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${loginReportUrl}/browser?pastDays=${days}`,
      method: "get",
      callback,
      onStart: browserReportRequested.type,
      onSuccess: browserReportRecieved.type,
      onError: browserReportFailed.type,
    }),
  );
};

//Get OS Report
export const getOsReport = (days, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${loginReportUrl}/os?pastDays=${days}`,
      method: "get",
      callback,
      onStart: osReportRequested.type,
      onSuccess: osReportRecieved.type,
      onError: osReportFailed.type,
    }),
  );
};

//Get overall Report
export const getOverallReport = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${loginReportUrl}/overall`,
      method: "get",
      callback,
      onStart: overallReportRequested.type,
      onSuccess: overallReportRecieved.type,
      onError: overallReportFailed.type,
    }),
  );
};

//Get Month Report
export const getMonthReport = (fromYear, toYear, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${loginReportUrl}/month?fromYear=${fromYear}&toYear=${toYear}`,
      method: "get",
      callback,
      onStart: monthReportRequested.type,
      onSuccess: monthReportRecieved.type,
      onError: monthReportFailed.type,
    }),
  );
};

//Get Day Report
export const getDayReport = (day, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${loginReportUrl}/day?day=${day}`,
      method: "get",
      callback,
      onStart: dayReportRequested.type,
      onSuccess: dayReportRecieved.type,
      onError: dayReportFailed.type,
    }),
  );
};

//Get CategoryPreference
export const getCategoryPreference = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${categoryPreferenceUrl}/${params}`,
      method: "get",
      callback,
      onStart: categoryPreferenceRequested.type,
      onSuccess: categoryPreferenceRecieved.type,
      onError: categoryPreferenceFailed.type,
    }),
  );
};

//Add CategoryPreference
export const addCategoryPreference = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${categoryPreferenceUrl}/${params}`,
      method: "post",
      data,
      callback,
    }),
  );
};

export const uploadVerificationDocs = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `user/document/${id}`,
      method: "post",
      data,
      callback,
    }),
  );
};

export const getUser = createSelector(
  (state) => state.entities.users,
  (users) => users,
);
