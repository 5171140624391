import { createSelector, createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { formControlClasses } from "@mui/material";

const slice = createSlice({
    name: "bids",
    initialState: {
        loading: false,
        bids: [],
        bidDetail: {},
        feedback: {},
        rating: {},
        buyers: {},
        addBid: {},
    },
    reducers: {
        bidsRequested: (state, action) => {
            state.loading = true;
        },
        bidsRecieved: (state, action) => {
            state.bids = action.payload;
            state.loading = false;
        },
        bidsFailed: (state, action) => {
            state.loading = false;
        },
        bidDetailRequested: (state, action) => {
            state.loading = true;
        },
        bidDetailRecieved: (state, action) => {
            state.bidDetail = action.payload;
            state.loading = false;
        },
        bidDetailFailed: (state, action) => {
            state.loading = false;
        },
        feedbackRequested: (state, action) => {
            state.loading = true;
        },
        feedbackRecieved: (state, action) => {
            state.feedback = action.payload;
            state.loading = false;
        },
        feedbackFailed: (state, action) => {
            state.loading = false;
        },
        ratingRequested: (state, action) => {
            state.loading = true;
        },
        ratingRecieved: (state, action) => {
            state.rating = action.payload;
            state.loading = false;
        },
        ratingFailed: (state, action) => {
            state.loading = false;
        },
        buyersRequested: (state, action) => {
            state.loading = true;
        },
        buyersRecieved: (state, action) => {
            state.buyers = action.payload;
            state.loading = false;
        },
        buyersFailed: (state, action) => {
            state.loading = false;
        },
        addBidRequested: (state, action) => {
            state.loading = true;
        },
        addBidRecieved: (state, action) => {
            state.addBid = action.payload;
            state.loading = false;
        },
        addBidFailed: (state, action) => {
            state.loading = false;
        },
    }
});

export const {
    bidsRequested,
    bidsRecieved,
    bidsFailed,
    bidDetailRequested,
    bidDetailRecieved,
    bidDetailFailed,
    feedbackRequested,
    feedbackRecieved,
    feedbackFailed,
    ratingRequested,
    ratingRecieved,
    ratingFailed,
    buyersRequested,
    buyersRecieved,
    buyersFailed,
    addBidRequested,
    addBidRecieved,
    addBidFailed,
} = slice.actions;
export default slice.reducer;

//Action Creator
const baseUrl = 'auctionAccounting/bid';

//Get Bid Lists
export const getBids = (params, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/list/${params}`,
            method: 'get',
            callback,
            onStart: bidsRequested.type,
            onSuccess: bidsRecieved.type,
            onError: bidsFailed.type,
        })
    )
}

//Get Bid Detail
export const getBidDetail = (params, bId, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/detail/${params}?b_id=${bId}`,
            method: 'get',
            callback,
            onStart: bidDetailRequested.type,
            onSuccess: bidDetailRecieved.type,
            onError: bidDetailFailed.type
        })
    )
}

//Add New Bid
export const addNewBid = (params, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/${params}`,
            method: "post",
            data,
            callback,
        })
    )
};

//Edit Bid
export const editBid = (params, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/${params}`,
            method: "put",
            data,
            callback,
        })
    )
}

//Delete Bid 
export const deleteBid = (params, bidId, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/${params}?del=${bidId}`,
            method: "delete",
            callback,
        })
    )
}

//Decline Bid
export const declineBid = (params, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/decline/${params}`,
            method: 'put',
            data,
            callback,
        })
    )
}

//Get Feedback
export const getFeedback = (params, bidId, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/feedback/${params}?b_id=${bidId}`,
            method: 'get',
            callback,
            onStart: feedbackRequested.type,
            onSuccess: feedbackRecieved.type,
            onError: feedbackFailed.type,
        })
    )
}


//Update Status
export const updateStatus = (params, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/updateStatus/${params}`,
            method: "put",
            data,
            callback,
        })
    )
}

//Search Buyer 
export const searchBuyer = (params, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `auctionOther/other/observer/action/${params}`,
            method: "post",
            data,
            callback,
            onStart: buyersRequested.type,
            onSuccess: buyersRecieved.type,
            onError: buyersFailed.type,
        })
    )
};

//Get Rating
export const getRating = (userId, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `auctionAccounting/bid/rating/${userId}`,
            method: 'get',
            callback,
            onStart: ratingRequested.type,
            onSuccess: ratingRecieved.type,
            onError: ratingFailed.type,
        })
    )
};

//Bid Add Note
export const bidAddNote = (params, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/note/${params}`,
            method: "post",
            data,
            callback,
            onStart: addBidRequested.type,
            onSuccess: addBidRecieved.type,
            onError: addBidFailed.type,
        })
    )
}
export const bidsData = createSelector(
    (state) => state.entities.bids,
    (bids) => (bids)
)