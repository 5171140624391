import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import "../../../include/css/weeklyReport.css";
import {
  changeWeeklyReportListingStatus,
  getBuyerArrListings,
  getBuyerInvoicesandPending,
  getBuyerOtherListings,
  getBuyerOtherPreferences,
  primarySellerData,
  sendBuyerEmailReport,
  updateBuyerReportEmailFrequency,
} from "../../../store/primarySeller";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import queryString from "query-string";
import LoadingBar from "react-top-loading-bar";
import { checkToken, setRedirectUrl } from "../../../utils.js/localStorageServices";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import { numberFormat } from "../../../utils.js/conversion";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import ScrollButton from "../../../common/scrollTop/scrollTop";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import WorldMap from "../../../common/worldMap/worldMap";
import HTMLReactParser from "html-react-parser";
const weekFrequencyOptions = [
  { name: "Select Week", value: "-1" },
  { name: "1 Week", value: 1 },
  { name: "2 Weeks", value: 2 },
  { name: "3 Weeks", value: 3 },
  { name: "4 Weeks", value: 4 },
];

const BuyerARR = (props) => {
  const d = new Date();
  let diff = d.getTimezoneOffset();
  const ref = useRef(null);
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [loadingWatch, setLoadingWatch] = useState(true);

  const [email, setEmail] = useState("");
  const [weekFrequency, setWeekFrequency] = useState({
    name: "Select Week",
    value: "",
  });
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [userId, setUserId] = useState("");

  const matchAndTransfer = (mainArray1, mainArray2, otherArray) => {
    const updatedMainArray1 = [...mainArray1];
    const updatedMainArray2 = [...mainArray2];
    const remainingOtherArray = [];
    let found = false;

    otherArray.forEach((item) => {
      if (mainArray1.some((mainItem) => mainItem.auction_id === item.auction_id)) {
        const object = mainArray1.filter((mainItem) => mainItem.auction_id === item.auction_id)?.[0];
        const index = mainArray1.findIndex((mainItem) => mainItem.auction_id === item.auction_id);
        const newObject = Object.assign(
          {
            messages: item?.messages,
            qs_ids: item?.qs_ids,
            answers: item?.answers,
            allAnswers: item?.allAnswers,
            referrals: item?.referrals,
          },
          object,
        );

        updatedMainArray1[index] = newObject;
        found = true;
      } else if (mainArray2.some((mainItem) => mainItem.auction_id === item.auction_id)) {
        const object = mainArray2.filter((mainItem) => mainItem.auction_id === item.auction_id)?.[0];
        const index = mainArray2.findIndex((mainItem) => mainItem.auction_id === item.auction_id);
        const newObject = Object.assign(
          {
            messages: item?.messages,
            qs_ids: item?.qs_ids,
            answers: item?.answers,
            allAnswers: item?.allAnswers,
            referrals: item?.referrals,
          },
          object,
        );

        found = true;
        updatedMainArray2[index] = newObject;
      } else {
        remainingOtherArray.push(item);
      }
    });
    return {
      updatedMainArray1: found ? updatedMainArray1 : mainArray1,
      updatedMainArray2: found ? updatedMainArray2 : mainArray2,
      remainingOtherArray: found ? remainingOtherArray : otherArray,
    };
  };

  const allFilteredData =
    props?.primarySellerData?.invoiceAndPending?.data?.siteUrl && props?.primarySellerData?.otherListings?.data?.email
      ? matchAndTransfer(
          props?.primarySellerData?.invoiceAndPending?.data?.biddingAuctions,
          props?.primarySellerData?.invoiceAndPending?.data?.invoicedAuctions,
          props?.primarySellerData?.otherListings?.data?.all3Sections,
        )
      : {};
  const siteUrl = props?.primarySellerData?.invoiceAndPending?.data?.siteUrl;
  const token = props?.primarySellerData?.invoiceAndPending?.data?.token;
  const updateType = props?.primarySellerData?.invoiceAndPending?.data?.buyerFrequencyUpdatedBy;
  const preferenceAuctionData = props?.primarySellerData?.otherPreferences?.data?.preferenceAuctions;
  const allSectionData = allFilteredData?.remainingOtherArray;
  const data = allFilteredData?.updatedMainArray1 || props?.primarySellerData?.invoiceAndPending?.data?.biddingAuctions;
  const invoiceAuctionData =
    allFilteredData?.updatedMainArray2 || props?.primarySellerData?.invoiceAndPending?.data?.invoicedAuctions;

  const unpaidTotal = props?.primarySellerData?.invoiceAndPending?.data?.unpaidTotal;
  const isUnsubscribed = props?.primarySellerData?.invoiceAndPending?.data?.unsub_reason?.length > 0 ? true : false;

  const isNoContentLoaded =
    siteUrl &&
    props?.primarySellerData?.otherListings?.data?.email &&
    props?.primarySellerData?.otherPreferences?.data?.email
      ? false
      : true;
  const isValidEmail = !isNoContentLoaded
    ? data?.length || invoiceAuctionData?.length || allSectionData?.length
      ? true
      : false
    : true;

  const isSectionAvailable = data?.length || invoiceAuctionData?.length || allSectionData?.length ? true : false;

  useEffect(() => {
    document.body.classList.add("pt-0");
    document.body.classList.remove("pt-80");
    if (!checkToken()) {
      const a = window.location.href;
      setRedirectUrl(a);
      props.history.push("/signin");
    }
    return () => {
      document.body.classList.add("pt-80");
      document.body.classList.remove("pt-0");
    };
  }, []);

  useEffect(() => {
    const a = queryString.parse(location.search)?.id;
    setUserId(a);
    if (a) {
      ref?.current?.continuousStart();

      props?.getBuyerInvoicesandPending(a, (res) => {
        if (res && res.status == 200) {
          ref?.current?.complete();
        }
      });
      props?.getBuyerOtherListings(a, (res) => {
        if (res && res.status == 200) {
          ref?.current?.complete();
          setLoadingWatch(false);
        }
      });
      props?.getBuyerOtherPreferences(a, (res) => {
        if (res && res.status == 200) {
          ref?.current?.complete();
        }
      });
    } else {
      window.location.href = "/500";
    }
  }, []);

  useEffect(() => {
    const email = props?.primarySellerData?.invoiceAndPending?.data?.email;
    setEmail(email);
    const weekData = props?.primarySellerData?.invoiceAndPending?.data?.buyerReportEmailFrequency;
    if (weekData) {
      const a = weekFrequencyOptions?.filter((ye) => ye?.value == weekData)?.[0];
      setWeekFrequency(a);
    } else if (weekData === 0) {
      setWeekFrequency({ name: "Select Week", value: "0" });
    }
  }, [props?.primarySellerData?.invoiceAndPending?.data?.buyerReportEmailFrequency]);

  const handleWeekReportEmail = (value) => {
    const a = queryString.parse(location.search)?.id;
    const payload = {
      buyerReportEmailFrequency: value,
    };
    if (value !== "-1" && value != "0") {
      ref?.current?.continuousStart();
      setLoadingStatus(true);
      props?.updateBuyerReportEmailFrequency(a, payload, (res) => {
        if (res.status === 200) {
          props?.getBuyerArrListings(a, (res) => {
            setLoadingStatus(false);
          });
          ref?.current?.complete();
        } else {
          setLoadingStatus(false);
          ref?.current?.complete();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  };

  const onSendHandler = () => {
    const a = queryString.parse(location.search)?.id;
    ref?.current?.continuousStart();
    setLoading(true);
    props?.sendBuyerEmailReport(a, { email }, (res) => {
      if (res && res.status == 200) {
        setLoading(false);
        ref?.current?.complete();
        toast(<AlertSuccess message={"Email Sent Successfully"} />);
        // history.push("/email-sent-successfully");
      } else {
        setLoading(false);
        ref?.current?.complete();
        window.location.href("/500");
      }
    });
  };

  function splitIntoLines(input) {
    let lines = [];

    for (let i = 0; i < input.length; i += 12) {
      let chunk = input.substring(i, i + 12);
      lines.push(chunk);
    }

    return lines.join("\n");
  }

  return (
    <>
      <ScrollButton />
      <LoadingBar height={5} color="#47AD1D" ref={ref} />

      <table width="100%" cellspacing="0" cellpadding="0" className="w-email-table">
        <tr>
          <td class="xs-p-0" align="center" valign="top" style={{ padding: "5px 0" }}>
            <table class="w-full-mobile" cellspacing="0" cellpadding="0" style={{ width: "800px", background: "#fff" }}>
              <div class="details-page-widget-block">
                <div class="page-widget-head d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center " style={{ width: "74%" }}>
                    <div class="page-widget-label text-capitalize cursor-pointer">Email </div>
                    <input
                      style={{ marginLeft: "50px" }}
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div class="btn-block d-flex align-items-center">
                    <a
                      class={`button-hover ${loading || !isValidEmail ? "btn-disabled" : ""} `}
                      href="javascript:void(0)"
                      style={{
                        display: "block",
                        textAlign: "center",
                        fontSize: "14px",
                        lineHeight: "1.25",
                        fontWeight: "500",
                        backgroundColor: "#47ad1d",
                        color: "#fff",
                        borderRadius: "4px",
                        padding: "6px 15px",
                        textDecoration: "none",
                      }}
                      onClick={onSendHandler}
                    >
                      Send
                    </a>
                  </div>
                </div>
                <div className="d-flex align-items-center  mt-3 flex-wrap">
                  <div class="page-widget-label text-capitalize cursor-pointer">
                    Buyer Asset Recovery Report Frequency :{" "}
                  </div>
                  <div style={{ marginLeft: "50px" }}>
                    {" "}
                    <Select
                      className="email-arr-select"
                      menuPosition="fixed"
                      menuShouldScrollIntoView
                      styles={customStyles}
                      placeholder="Select Week"
                      value={weekFrequency}
                      options={weekFrequencyOptions}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.value}
                      onChange={(e) => {
                        handleWeekReportEmail(e?.value);
                        setWeekFrequency(e);
                      }}
                    />
                  </div>
                </div>
                <div className="d-block">
                  {!loadingStatus && updateType == "Buyer" && weekFrequency?.value == 0 ? (
                    <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                      <span style={{ color: "red" }}> Buyer has decided not to receive these emails. </span>{" "}
                    </span>
                  ) : (
                    ""
                  )}
                  {!loadingStatus && updateType == "Buyer" && weekFrequency?.value === 1 ? (
                    <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                      <span style={{ color: "red" }}> Buyer has decided to receive this email once in a week.</span>{" "}
                    </span>
                  ) : !loadingStatus && updateType == "Buyer" && weekFrequency?.value === 2 ? (
                    <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                      <span style={{ color: "red" }}> Buyer has decided to receive this email once in two weeks.</span>{" "}
                    </span>
                  ) : !loadingStatus && updateType == "Buyer" && weekFrequency?.value === 3 ? (
                    <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                      <span style={{ color: "red" }}>
                        {" "}
                        Buyer has decided to receive this email once in three weeks.
                      </span>{" "}
                    </span>
                  ) : !loadingStatus && updateType == "Buyer" && weekFrequency?.value === 4 ? (
                    <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                      <span style={{ color: "red" }}> Buyer has decided to receive this email once in four weeks.</span>{" "}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </table>
          </td>
        </tr>
        {isValidEmail ? (
          <tr>
            <td class="xs-p-0" align="center" valign="top" style={{ padding: "5px 0" }}>
              <table
                class="w-full-mobile"
                cellspacing="0"
                cellpadding="0"
                style={{ width: "800px", minHeight: "90vh", background: "#fff" }}
              >
                <tbody>
                  <tr>
                    <td align="center" valign="top">
                      <table width="100%" cellspacing="0" cellpadding="0">
                        <tr>
                          <td
                            class="xs-p-15"
                            valign="middle"
                            style={{
                              padding: "15px 32px",
                              borderBottom: "1px solid #e4e4e4",
                            }}
                          >
                            <table width="100%" cellpadding="0" cellspacing="0">
                              <tr>
                                <td align="left">
                                  <img
                                    style={{
                                      display: "block",
                                      width: "140px",
                                      height: "40px",
                                      margin: "0",
                                      padding: "0",
                                      border: "0",
                                    }}
                                    src="https://s3.amazonaws.com/media-dev.salvex.com/emails/salvex2x.png"
                                    alt="logo"
                                  />
                                </td>
                                <td align="right">
                                  <table cellpadding="0" cellspacing="0">
                                    <tr>
                                      <td>
                                        <a
                                          class="button-hover cursor-pointer"
                                          target={siteUrl ? "_blank" : "_self"}
                                          href={siteUrl ? `${siteUrl}members/post-listing` : "javascript:void(0)"}
                                          style={{
                                            maxWidth: "170px",
                                            display: "block",
                                            textAlign: "center",
                                            fontSize: "14px",
                                            lineHeight: "1.25",
                                            fontWeight: "500",
                                            backgroundColor: "#47ad1d",
                                            color: "#fff",
                                            borderRadius: "4px",
                                            padding: "6px 15px",
                                            textDecoration: "none",
                                          }}
                                        >
                                          Post a Listing
                                        </a>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>

                        <tr>
                          <td class="xs-p-15" style={{ padding: "15px 32px" }}>
                            <table width="100%" cellspacing="0" cellpadding="0">
                              <tr>
                                <td
                                  style={{
                                    fontSize: "20px",
                                    lineHeight: "1.6",
                                    fontWeight: "700",
                                  }}
                                >
                                  Buyer Asset Recovery Report
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "1.5",
                                    fontWeight: "400",
                                    paddingTop: "5px",
                                  }}
                                >
                                  {!isNoContentLoaded ? (
                                    <span>
                                      This is a report that outlines your activity on Salvex. Please take a look at the
                                      status of your bid and public asking price changes. All listings are either in an
                                      Timed Bid Process or they are Active Negotiation. In many cases, if the seller
                                      asking price is not achieved, the listing is moved to a Active Negotiation.
                                    </span>
                                  ) : (
                                    [1, 2, 3, 4, 5, 5, 3, 3, 2].map((ue) => <Skeleton height="60px" />)
                                  )}
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        {!isNoContentLoaded ? (
                          <tr>
                            <td style={{ padding: "0px 30px 15px 30px" }}>
                              <table>
                                <tr>
                                  <td>
                                    {" "}
                                    <a
                                      class="button-hover cursor-pointer"
                                      target="_blank"
                                      href={`${siteUrl}my-account/category-preferences/`}
                                      style={{
                                        minWidth: "fit-content",
                                        display: "inline-block",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        lineHeight: "1.5",
                                        backgroundColor: "green",
                                        fontWeight: "500",
                                        color: "#fff",
                                        borderRadius: "4px",
                                        padding: "10px 15px",
                                        textDecoration: "none",
                                      }}
                                    >
                                      Change Category and Region Preferences
                                    </a>{" "}
                                    {isUnsubscribed ? (
                                      <a
                                        class="button-hover cursor-pointer"
                                        target="_blank"
                                        href={`${siteUrl}members/resubscribe/?redirect=${token}`}
                                        style={{
                                          minWidth: "fit-content",
                                          display: "inline-block",
                                          textAlign: "center",
                                          fontSize: "16px",
                                          lineHeight: "1.5",
                                          backgroundColor: "#47ad1d",
                                          fontWeight: "500",
                                          color: "#fff",
                                          borderRadius: "4px",
                                          padding: "10px 15px",
                                          textDecoration: "none",
                                        }}
                                      >
                                        Resubscribe to Daily Notifications
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {/* Invoice Listing Starts */}
                        {invoiceAuctionData?.length > 0 ? (
                          <>
                            <tr>
                              <td
                                class="xs-p-15"
                                style={{
                                  padding: "8px 32px",
                                  backgroundColor: "#47ad1d",
                                  color: "#fff",
                                }}
                              >
                                <table width="100%" cellspacing="0" cellpadding="0">
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "18px",
                                        lineHeight: "1.6",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Current Items - Invoices
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "18px",
                                        lineHeight: "1.6",
                                        fontWeight: "700",
                                        textAlign: "end",
                                      }}
                                    >
                                      Total Due : ${unpaidTotal}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>

                            {invoiceAuctionData?.length > 0 ? (
                              <tr>
                                <td class="xs-p-15" style={{ padding: "20px 32px" }}>
                                  <table cellspacing="0" cellpadding="0" style={{ minWidth: "100%" }}>
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{
                                            fontSize: "16px",
                                            lineHeight: "1.2",
                                            fontWeight: "400",
                                            paddingBottom: "15px",
                                          }}
                                        >
                                          Please pay attention to the entity to be paid on each invoice, and make sure
                                          to wire funds according to each invoice to avoid delays. All invoices that
                                          show the same entity can be paid as 1 wire transfer.
                                        </td>
                                      </tr>
                                      {invoiceAuctionData?.map((item) => {
                                        return (
                                          <tr>
                                            <td style={{ paddingBottom: "15px" }}>
                                              <table style={{ width: "100%" }} cellspacing="0" cellpadding="0">
                                                {" "}
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        padding: "15px",
                                                        border: "1px solid black",
                                                        borderRadius: "15px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <table style={{ width: "100%" }} cellspacing="0" cellpadding="0">
                                                        <tbody>
                                                          <tr>
                                                            <td
                                                              style={{
                                                                fontSize: "16px",
                                                                lineHeight: "1.2",
                                                                fontWeight: "700",
                                                                paddingBottom: "12px",
                                                                display: "flex",
                                                                alignItems: "start",
                                                                justifyContent: "start",
                                                              }}
                                                            >
                                                              <table style={{ width: "100%" }}>
                                                                <tr>
                                                                  <td>
                                                                    <table style={{ width: "100%" }}>
                                                                      <tr>
                                                                        <td style={{ display: "block" }}>
                                                                          <table>
                                                                            <tr>
                                                                              <td
                                                                                style={{
                                                                                  verticalAlign: "top",
                                                                                }}
                                                                              >
                                                                                <table
                                                                                  style={{
                                                                                    width: "100%",
                                                                                  }}
                                                                                  cellspacing="0"
                                                                                  cellpadding="0"
                                                                                >
                                                                                  <tr>
                                                                                    <td
                                                                                      style={{
                                                                                        verticalAlign: "top",
                                                                                      }}
                                                                                    >
                                                                                      {" "}
                                                                                      <a
                                                                                        style={{
                                                                                          display: "inline-block",
                                                                                        }}
                                                                                        class="flex-grow-1 link-item"
                                                                                        href={`/listing-detail?id=${item?.auction_id}`}
                                                                                        target="_blank"
                                                                                      >
                                                                                        {item?.auction_id} -{" "}
                                                                                        {item?.title}
                                                                                      </a>
                                                                                    </td>
                                                                                  </tr>
                                                                                  <tr>
                                                                                    <td
                                                                                      style={{
                                                                                        fontSize: "14px",
                                                                                        paddingTop: "12px",
                                                                                      }}
                                                                                    >
                                                                                      <strong>
                                                                                        Position / Bid Status
                                                                                      </strong>{" "}
                                                                                      :{" "}
                                                                                      <span
                                                                                        style={{
                                                                                          color: "green",
                                                                                          fontSize: "20px",
                                                                                          fontWeight: "600",
                                                                                          marginLeft: "5px",
                                                                                        }}
                                                                                      >
                                                                                        {" "}
                                                                                        ACCEPTED
                                                                                      </span>
                                                                                    </td>
                                                                                  </tr>

                                                                                  <tr>
                                                                                    <td
                                                                                      style={{
                                                                                        fontSize: "14px",
                                                                                        paddingTop: "12px",
                                                                                      }}
                                                                                    >
                                                                                      <strong>Last Bid Amount</strong> :
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: "20px",
                                                                                          fontWeight: "600",
                                                                                          paddingLeft: "6px",
                                                                                        }}
                                                                                      >
                                                                                        {item?.total_bid
                                                                                          ? "$" +
                                                                                            numberFormat(
                                                                                              Math.round(
                                                                                                item?.total_bid,
                                                                                              ),
                                                                                            )
                                                                                          : "$0"}
                                                                                      </span>
                                                                                      {item?.invoice?.id ? (
                                                                                        <a
                                                                                          class="button-hover"
                                                                                          target="_blank"
                                                                                          href={`${siteUrl}my-account/invoice/?id=${`${item?.invoice?.encoded_invoice_id}`}&uid=${
                                                                                            item?.encoded_user_id
                                                                                          }`}
                                                                                          style={{
                                                                                            display: "inline",
                                                                                            textAlign: "center",
                                                                                            fontSize: "12px",
                                                                                            lineHeight: "1",
                                                                                            fontWeight: "500",
                                                                                            backgroundColor: "red",
                                                                                            color: "white",
                                                                                            borderRadius: "4px",
                                                                                            border: "1px solid red",
                                                                                            padding: "3px ",
                                                                                            textDecoration: "none",
                                                                                            marginLeft: "5px",
                                                                                          }}
                                                                                        >
                                                                                          Pay Invoice
                                                                                        </a>
                                                                                      ) : (
                                                                                        ""
                                                                                      )}
                                                                                    </td>
                                                                                  </tr>
                                                                                  {item?.reserve &&
                                                                                  item?.reserve > 0 ? (
                                                                                    <tr>
                                                                                      <td
                                                                                        style={{
                                                                                          fontSize: "14px",
                                                                                          paddingTop: "12px",
                                                                                        }}
                                                                                      >
                                                                                        <strong>
                                                                                          Proposed Asking Price
                                                                                        </strong>{" "}
                                                                                        :
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: "20px",
                                                                                            fontWeight: "600",
                                                                                            paddingLeft: "6px",
                                                                                          }}
                                                                                        >
                                                                                          {item?.reserve_formatted
                                                                                            ? "$" +
                                                                                              item?.reserve_formatted
                                                                                            : "N/A"}
                                                                                        </span>
                                                                                      </td>
                                                                                    </tr>
                                                                                  ) : (
                                                                                    ""
                                                                                  )}
                                                                                  {item?.reduced_reserve &&
                                                                                  item?.reduced_reserve >= 1 ? (
                                                                                    <tr>
                                                                                      <td
                                                                                        style={{
                                                                                          fontSize: "14px",
                                                                                          paddingTop: "12px",
                                                                                        }}
                                                                                      >
                                                                                        <strong>
                                                                                          Price Reduced By
                                                                                        </strong>{" "}
                                                                                        :
                                                                                        <span
                                                                                          style={{
                                                                                            fontWeight: "600",
                                                                                            paddingLeft: "6px",
                                                                                            fontSize: "20px",
                                                                                          }}
                                                                                        >
                                                                                          {item?.reduced_reserve
                                                                                            ? "$" +
                                                                                              numberFormat(
                                                                                                Math.round(
                                                                                                  item?.reduced_reserve,
                                                                                                ),
                                                                                              )
                                                                                            : "$0"}{" "}
                                                                                        </span>
                                                                                        <span
                                                                                          style={{
                                                                                            fontWeight: "400",
                                                                                          }}
                                                                                        >
                                                                                          {item?.reduced_reserve_date
                                                                                            ? `(${item?.reduced_reserve_date})`
                                                                                            : ""}
                                                                                        </span>
                                                                                      </td>
                                                                                    </tr>
                                                                                  ) : (
                                                                                    ""
                                                                                  )}
                                                                                  {item?.wholesale &&
                                                                                  item?.wholesale > 0 ? (
                                                                                    <tr>
                                                                                      <td
                                                                                        style={{
                                                                                          fontSize: "14px",
                                                                                          paddingTop: "12px",
                                                                                        }}
                                                                                      >
                                                                                        <strong>Wholesale Value</strong>{" "}
                                                                                        :
                                                                                        <span
                                                                                          style={{
                                                                                            fontWeight: "600",
                                                                                            paddingLeft: "6px",
                                                                                            fontSize: "20px",
                                                                                          }}
                                                                                        >
                                                                                          ${item?.wholesale_formatted}
                                                                                        </span>
                                                                                      </td>
                                                                                    </tr>
                                                                                  ) : (
                                                                                    ""
                                                                                  )}
                                                                                  <tr>
                                                                                    <td
                                                                                      style={{
                                                                                        fontSize: "14px",
                                                                                        paddingTop: "12px",
                                                                                      }}
                                                                                    >
                                                                                      <strong># of Bids</strong> :{" "}
                                                                                      <span
                                                                                        style={{
                                                                                          fontWeight: "400",
                                                                                        }}
                                                                                      >
                                                                                        {item?.bid_count
                                                                                          ? numberFormat(
                                                                                              item?.bid_count,
                                                                                            )
                                                                                          : 0}{" "}
                                                                                        {item?.bid_count === 0 ||
                                                                                        item?.bid_count === 1 ||
                                                                                        !item?.bid_count
                                                                                          ? "Bid"
                                                                                          : "Bids"}
                                                                                      </span>
                                                                                    </td>
                                                                                  </tr>
                                                                                  {item?.detail?.location ? (
                                                                                    <tr>
                                                                                      <td
                                                                                        style={{
                                                                                          fontSize: "14px",
                                                                                          paddingTop: "12px",
                                                                                        }}
                                                                                      >
                                                                                        <strong>Location</strong> :
                                                                                        <span
                                                                                          style={{
                                                                                            fontWeight: "400",
                                                                                            paddingLeft: "6px",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            dangerouslySetInnerHTML={{
                                                                                              __html:
                                                                                                item?.detail?.location?.toString(),
                                                                                            }}
                                                                                          />
                                                                                        </span>
                                                                                      </td>
                                                                                    </tr>
                                                                                  ) : (
                                                                                    ""
                                                                                  )}
                                                                                  {item?.detail?.conditionType ? (
                                                                                    <tr>
                                                                                      <td
                                                                                        style={{
                                                                                          fontSize: "14px",
                                                                                          paddingTop: "12px",
                                                                                        }}
                                                                                      >
                                                                                        <strong>Condition</strong> :
                                                                                        <span
                                                                                          style={{
                                                                                            fontWeight: "400",
                                                                                            paddingLeft: "6px",
                                                                                          }}
                                                                                        >
                                                                                          {item?.detail?.conditionType}
                                                                                        </span>
                                                                                      </td>
                                                                                    </tr>
                                                                                  ) : (
                                                                                    ""
                                                                                  )}
                                                                                  <tr>
                                                                                    <td
                                                                                      style={{
                                                                                        fontSize: "14px",
                                                                                        paddingTop: "12px",
                                                                                      }}
                                                                                    >
                                                                                      <strong>Seller Industry</strong> :
                                                                                      <span
                                                                                        style={{
                                                                                          fontWeight: "400",
                                                                                          paddingLeft: "6px",
                                                                                        }}
                                                                                      >
                                                                                        {
                                                                                          item?.detail
                                                                                            ?.industry_description
                                                                                        }
                                                                                      </span>
                                                                                    </td>
                                                                                  </tr>
                                                                                  <tr>
                                                                                    <td
                                                                                      style={{
                                                                                        fontSize: "14px",
                                                                                        paddingTop: "12px",
                                                                                      }}
                                                                                    >
                                                                                      <strong>
                                                                                        Sale Type End Date
                                                                                      </strong>{" "}
                                                                                      :
                                                                                      <span
                                                                                        style={{
                                                                                          fontWeight: "400",
                                                                                          paddingLeft: "6px",
                                                                                        }}
                                                                                      >
                                                                                        {!item?.detail?.end_date
                                                                                          ? "Active Negotiation"
                                                                                          : item?.detail?.end_date &&
                                                                                            moment(
                                                                                              item?.detail?.end_date,
                                                                                            ).isBefore(moment())
                                                                                          ? "Active Negotiation"
                                                                                          : moment(
                                                                                              item?.detail?.end_date,
                                                                                            )
                                                                                              .add({ minute: diff })
                                                                                              .format("MMM-D-YYYY")}
                                                                                      </span>
                                                                                    </td>
                                                                                  </tr>
                                                                                  {item?.messages?.length > 0 ? (
                                                                                    <>
                                                                                      <tr>
                                                                                        <td
                                                                                          style={{ paddingTop: "15px" }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontWeight: "400",
                                                                                            }}
                                                                                          >
                                                                                            <div
                                                                                              style={{
                                                                                                display: "inline-block",

                                                                                                verticalAlign:
                                                                                                  "text-top",
                                                                                                fontSize: "14px",
                                                                                              }}
                                                                                            >
                                                                                              {item?.messages?.map(
                                                                                                (dd, y) => (
                                                                                                  <>
                                                                                                    <div
                                                                                                      className="d-block"
                                                                                                      style={{
                                                                                                        fontSize:
                                                                                                          "14px",
                                                                                                        fontWeight:
                                                                                                          "600",
                                                                                                      }}
                                                                                                    >
                                                                                                      Question {y + 1}:
                                                                                                    </div>
                                                                                                    <div
                                                                                                      className="d-block"
                                                                                                      style={{
                                                                                                        fontSize:
                                                                                                          "14px",
                                                                                                        marginBottom:
                                                                                                          "10px",
                                                                                                      }}
                                                                                                    >
                                                                                                      {dd}{" "}
                                                                                                    </div>
                                                                                                    {item?.answers?.[y]
                                                                                                      ?.length > 0 &&
                                                                                                    item?.answers?.[
                                                                                                      y
                                                                                                    ] === "viaEmail" &&
                                                                                                    item?.allAnswers?.[
                                                                                                      y
                                                                                                    ]?.length > 0 ? (
                                                                                                      <>
                                                                                                        <div
                                                                                                          className="d-block"
                                                                                                          style={{
                                                                                                            fontSize:
                                                                                                              "14px",
                                                                                                            fontWeight:
                                                                                                              "600",
                                                                                                          }}
                                                                                                        >
                                                                                                          Answer {y + 1}
                                                                                                          :
                                                                                                        </div>
                                                                                                        <div
                                                                                                          className="d-block"
                                                                                                          style={{
                                                                                                            fontSize:
                                                                                                              "14px",
                                                                                                          }}
                                                                                                        >
                                                                                                          {HTMLReactParser(
                                                                                                            item
                                                                                                              ?.allAnswers?.[
                                                                                                              y
                                                                                                            ],
                                                                                                          )}
                                                                                                        </div>
                                                                                                      </>
                                                                                                    ) : (
                                                                                                      ""
                                                                                                    )}
                                                                                                    {item?.answers?.[
                                                                                                      y
                                                                                                    ] &&
                                                                                                    item?.answers?.[y]
                                                                                                      ?.length > 0 &&
                                                                                                    item?.answers?.[
                                                                                                      y
                                                                                                    ] !== "viaEmail" ? (
                                                                                                      <>
                                                                                                        <div
                                                                                                          className="d-block"
                                                                                                          style={{
                                                                                                            fontSize:
                                                                                                              "14px",
                                                                                                            fontWeight:
                                                                                                              "600",
                                                                                                          }}
                                                                                                        >
                                                                                                          Answer {y + 1}
                                                                                                          :
                                                                                                        </div>
                                                                                                        <div
                                                                                                          className="d-block"
                                                                                                          style={{
                                                                                                            fontSize:
                                                                                                              "14px",
                                                                                                          }}
                                                                                                        >
                                                                                                          {
                                                                                                            item
                                                                                                              ?.answers?.[
                                                                                                              y
                                                                                                            ]
                                                                                                          }
                                                                                                        </div>
                                                                                                      </>
                                                                                                    ) : (
                                                                                                      ""
                                                                                                    )}
                                                                                                    <br />
                                                                                                  </>
                                                                                                ),
                                                                                              )}
                                                                                            </div>
                                                                                          </span>
                                                                                        </td>
                                                                                      </tr>
                                                                                    </>
                                                                                  ) : (
                                                                                    ""
                                                                                  )}

                                                                                  {item?.referrals?.length > 0 ? (
                                                                                    <>
                                                                                      <tr>
                                                                                        <td
                                                                                          style={{
                                                                                            fontSize: "14px",
                                                                                            paddingTop: "12px",
                                                                                          }}
                                                                                        >
                                                                                          <strong>Referrals</strong> :
                                                                                        </td>
                                                                                      </tr>
                                                                                      <tr>
                                                                                        <table
                                                                                          style={{
                                                                                            borderCollapse: "collapse",
                                                                                            captionSide: "bottom",
                                                                                            verticalAlign: "middle",
                                                                                            color: "#000",
                                                                                          }}
                                                                                        >
                                                                                          <tr>
                                                                                            <td
                                                                                              style={{
                                                                                                padding:
                                                                                                  "10px 20px 10px 0px",
                                                                                                minWidth: "280px",
                                                                                                maxWidth: "280px",
                                                                                                fontWeight: "600",
                                                                                                border:
                                                                                                  "1px solid black",
                                                                                                paddingLeft: "10px",
                                                                                                fontWeight: "400",
                                                                                                fontSize: "14px",
                                                                                              }}
                                                                                            >
                                                                                              Name
                                                                                            </td>
                                                                                            <td
                                                                                              style={{
                                                                                                padding:
                                                                                                  "10px 100px 10px 0px",
                                                                                                minWidth: "100px",
                                                                                                maxWidth: "100px",
                                                                                                fontWeight: "600",
                                                                                                border:
                                                                                                  "1px solid black",
                                                                                                paddingLeft: "10px",
                                                                                                fontWeight: "400",
                                                                                                fontSize: "14px",
                                                                                              }}
                                                                                            >
                                                                                              Company
                                                                                            </td>
                                                                                          </tr>
                                                                                          {item?.referrals?.map(
                                                                                            (ue, o) => (
                                                                                              <tr>
                                                                                                <td
                                                                                                  style={{
                                                                                                    padding:
                                                                                                      "10px 20px 10px 0px",
                                                                                                    minWidth: "280px",
                                                                                                    maxWidth: "280px",
                                                                                                    border:
                                                                                                      "1px solid black",
                                                                                                    paddingLeft: "10px",
                                                                                                    fontWeight: "400",
                                                                                                    fontSize: "14px",
                                                                                                  }}
                                                                                                >
                                                                                                  {ue?.buyer_name}
                                                                                                  <br />
                                                                                                  {ue?.buyer_email}
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    padding:
                                                                                                      "10px 100px 10px 0px",
                                                                                                    minWidth: "100px",
                                                                                                    maxWidth: "100px",
                                                                                                    border:
                                                                                                      "1px solid black",
                                                                                                    paddingLeft: "10px",
                                                                                                    fontWeight: "400",
                                                                                                    fontSize: "14px",
                                                                                                  }}
                                                                                                >
                                                                                                  {HTMLReactParser(
                                                                                                    splitIntoLines(
                                                                                                      ue?.company,
                                                                                                    ),
                                                                                                  )}
                                                                                                </td>
                                                                                              </tr>
                                                                                            ),
                                                                                          )}
                                                                                        </table>
                                                                                      </tr>
                                                                                    </>
                                                                                  ) : (
                                                                                    ""
                                                                                  )}
                                                                                </table>
                                                                              </td>
                                                                            </tr>
                                                                          </table>
                                                                        </td>

                                                                        <td
                                                                          style={{
                                                                            width: "30%",
                                                                            textAlign: "-webkit-right",
                                                                            verticalAlign: "top",
                                                                          }}
                                                                        >
                                                                          {" "}
                                                                          <table style={{ width: "100%" }}>
                                                                            <tr>
                                                                              <td
                                                                                style={{
                                                                                  textAlign: "-webkit-right",
                                                                                }}
                                                                              >
                                                                                <table>
                                                                                  <tr>
                                                                                    {item?.detail?.bc_id_name ? (
                                                                                      <td style={{ textAlign: "end" }}>
                                                                                        <a
                                                                                          class="button-hover cursor-pointer"
                                                                                          target="_blank"
                                                                                          href={`${siteUrl}listings/listing_detail.cfm/aucid/${
                                                                                            item?.auction_id
                                                                                          }/?redirect=${btoa(
                                                                                            `page=buyerARR&action=askQuestion`,
                                                                                          )}`}
                                                                                          style={{
                                                                                            whiteSpace: "nowrap",
                                                                                            minWidth: "fit-content",
                                                                                            display: "inline-block",
                                                                                            textAlign: "center",
                                                                                            fontSize: "12px",
                                                                                            lineHeight: "1.5",
                                                                                            backgroundColor: "#47ad1d",
                                                                                            marginLeft: "5px",
                                                                                            fontWeight: "500",
                                                                                            color: "#fff",
                                                                                            borderRadius: "4px",
                                                                                            padding: "3px 3px",
                                                                                            textDecoration: "none",
                                                                                          }}
                                                                                        >
                                                                                          Ask a Question
                                                                                        </a>
                                                                                      </td>
                                                                                    ) : (
                                                                                      ""
                                                                                    )}
                                                                                    <td style={{ textAlign: "end" }}>
                                                                                      <a
                                                                                        class="button-hover cursor-pointer"
                                                                                        target="_blank"
                                                                                        href={`${siteUrl}listings/listing_detail.cfm/aucid/${
                                                                                          item?.auction_id
                                                                                        }/?redirect=${btoa(
                                                                                          `redirect=buyerARR`,
                                                                                        )}`}
                                                                                        style={{
                                                                                          whiteSpace: "nowrap",
                                                                                          minWidth: "fit-content",
                                                                                          display: "inline-block",
                                                                                          textAlign: "center",
                                                                                          fontSize: "12px",
                                                                                          lineHeight: "1.5",
                                                                                          fontWeight: "500",
                                                                                          backgroundColor: "gray",
                                                                                          color: "#fff",
                                                                                          borderRadius: "4px",
                                                                                          padding: "3px 3px",
                                                                                          marginLeft: "5px",

                                                                                          textDecoration: "none",
                                                                                          pointerEvents:
                                                                                            item?.wl_count > 0
                                                                                              ? "none"
                                                                                              : "auto",
                                                                                        }}
                                                                                      >
                                                                                        {(!item.wl_count ||
                                                                                          item.wl_count == 0 ||
                                                                                          item.wl_count == null) &&
                                                                                        !item.wl_id
                                                                                          ? "Watch Listing"
                                                                                          : "Added to watchlist"}
                                                                                      </a>
                                                                                    </td>
                                                                                  </tr>
                                                                                  <tr>
                                                                                    <td
                                                                                      colSpan={"2"}
                                                                                      style={{ textAlign: "end" }}
                                                                                    >
                                                                                      <a
                                                                                        class="button-hover cursor-pointer"
                                                                                        target="_blank"
                                                                                        href={`${siteUrl}listings/listing_detail.cfm/aucid/${
                                                                                          item?.auction_id
                                                                                        }/?redirect=${btoa(
                                                                                          `page=buyerARR&action=share`,
                                                                                        )}`}
                                                                                        style={{
                                                                                          whiteSpace: "nowrap",
                                                                                          minWidth: "fit-content",
                                                                                          display: "inline-block",
                                                                                          textAlign: "center",
                                                                                          fontSize: "12px",
                                                                                          lineHeight: "1.5",
                                                                                          fontWeight: "500",
                                                                                          backgroundColor: "#f2b61c",
                                                                                          color: "#fff",
                                                                                          borderRadius: "4px",
                                                                                          padding: "3px 3px",
                                                                                          marginLeft: "5px",
                                                                                          marginTop: "5px",
                                                                                          textDecoration: "none",
                                                                                        }}
                                                                                      >
                                                                                        Share with Buyers
                                                                                      </a>
                                                                                    </td>
                                                                                  </tr>
                                                                                </table>
                                                                              </td>
                                                                            </tr>
                                                                            <tr>
                                                                              <td>
                                                                                <table
                                                                                  style={{
                                                                                    width: "100%",
                                                                                  }}
                                                                                  cellspacing="0"
                                                                                  cellpadding="0"
                                                                                >
                                                                                  {item?.reserve &&
                                                                                  item?.reserve > 0 ? (
                                                                                    <tr>
                                                                                      <td
                                                                                        class="m-pl-0"
                                                                                        colspan="2"
                                                                                        style={{
                                                                                          paddingTop: "10px",
                                                                                        }}
                                                                                      >
                                                                                        <table
                                                                                          width="100%"
                                                                                          cellspacing="0"
                                                                                          cellpadding="0"
                                                                                        >
                                                                                          <tr>
                                                                                            <td
                                                                                              style={{
                                                                                                fontWeight: "500",
                                                                                                fontSize: "12px",
                                                                                                paddingBottom: "10px",
                                                                                              }}
                                                                                            >
                                                                                              Proposed Asking Price
                                                                                              History
                                                                                            </td>
                                                                                          </tr>
                                                                                          <tr>
                                                                                            <td
                                                                                              className="ps-map"
                                                                                              style={{
                                                                                                border:
                                                                                                  "1px solid black",
                                                                                                borderRadius: "4px",
                                                                                                padding: "16px 12px",
                                                                                              }}
                                                                                            >
                                                                                              <img
                                                                                                src={item?.chartUrl}
                                                                                              />
                                                                                            </td>
                                                                                          </tr>
                                                                                        </table>
                                                                                      </td>
                                                                                    </tr>
                                                                                  ) : (
                                                                                    ""
                                                                                  )}
                                                                                  {item?.detail?.world_map_image &&
                                                                                  (item?.detail?.world_map_image?.startsWith(
                                                                                    "worldMap",
                                                                                  ) ||
                                                                                    item?.detail?.world_map_image ===
                                                                                      "data_not_found_in_GA") ? (
                                                                                    <>
                                                                                      <tr>
                                                                                        <td
                                                                                          style={{
                                                                                            fontWeight: "500",
                                                                                            fontSize: "12px",
                                                                                            paddingTop: "10px",
                                                                                          }}
                                                                                        >
                                                                                          Activity Per Country
                                                                                        </td>
                                                                                      </tr>
                                                                                      <tr>
                                                                                        <td
                                                                                          className="ps-map"
                                                                                          style={{
                                                                                            border: "1px solid black",
                                                                                            borderRadius: "4px",
                                                                                            padding: "16px 12px",
                                                                                          }}
                                                                                        >
                                                                                          {item?.detail
                                                                                            ?.world_map_image ===
                                                                                          "data_not_found_in_GA" ? (
                                                                                            <img
                                                                                              src={
                                                                                                "https://s3.amazonaws.com/uploads-dev.salvex.com/worldMaps/world_map_default.jpeg"
                                                                                              }
                                                                                            />
                                                                                          ) : (
                                                                                            <img
                                                                                              src={
                                                                                                process.env
                                                                                                  .REACT_APP_MODE ===
                                                                                                "development"
                                                                                                  ? "https://s3.amazonaws.com/uploads.salvex.com/" +
                                                                                                    item?.detail
                                                                                                      ?.world_map_image
                                                                                                  : "https://s3.amazonaws.com/uploads.salvex.com/" +
                                                                                                    item?.detail
                                                                                                      ?.world_map_image
                                                                                              }
                                                                                            />
                                                                                          )}
                                                                                          {/* <WorldMap
                                                                                        googleAnalyticData={
                                                                                          item?.googleAnalytic
                                                                                        }
                                                                                        id={item?.auction_id}
                                                                                      /> */}
                                                                                        </td>
                                                                                      </tr>
                                                                                    </>
                                                                                  ) : (
                                                                                    ""
                                                                                  )}
                                                                                </table>
                                                                              </td>
                                                                            </tr>
                                                                          </table>
                                                                        </td>
                                                                      </tr>
                                                                    </table>
                                                                  </td>
                                                                </tr>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {data?.length > 0 ? (
                          <>
                            <tr>
                              <td
                                class="xs-p-15"
                                style={{
                                  padding: "8px 32px",
                                  backgroundColor: "#47ad1d",
                                  color: "#fff",
                                }}
                              >
                                <table width="100%" cellspacing="0" cellpadding="0">
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "18px",
                                        lineHeight: "1.6",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Current Items - Bidding
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>

                            {data?.length > 0 ? (
                              <tr>
                                <td class="xs-p-15" style={{ padding: "20px 32px" }}>
                                  <table cellspacing="0" cellpadding="0" style={{ minWidth: "100%" }}>
                                    <tbody>
                                      {data?.map((item) => {
                                        return (
                                          <tr>
                                            <td style={{ paddingBottom: "15px" }}>
                                              <table style={{ width: "100%" }} cellspacing="0" cellpadding="0">
                                                {" "}
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        padding: "15px",
                                                        border: "1px solid black",
                                                        borderRadius: "15px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <table style={{ width: "100%" }} cellspacing="0" cellpadding="0">
                                                        <tbody>
                                                          <tr>
                                                            <td
                                                              style={{
                                                                fontSize: "16px",
                                                                lineHeight: "1.2",
                                                                fontWeight: "700",
                                                                paddingBottom: "12px",
                                                                display: "flex",
                                                                alignItems: "start",
                                                                justifyContent: "start",
                                                              }}
                                                            >
                                                              <table style={{ width: "100%" }}>
                                                                <tr>
                                                                  <td>
                                                                    <table style={{ width: "100%" }}>
                                                                      <tr>
                                                                        <td style={{ display: "block" }}>
                                                                          <table>
                                                                            <tr>
                                                                              <td
                                                                                style={{
                                                                                  width: "100%",
                                                                                  verticalAlign: "top",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <a
                                                                                  style={{
                                                                                    display: "inline-block",
                                                                                  }}
                                                                                  class="flex-grow-1 link-item"
                                                                                  href={`/listing-detail?id=${item?.auction_id}`}
                                                                                  target="_blank"
                                                                                >
                                                                                  {item?.auction_id} - {item?.title}
                                                                                </a>
                                                                              </td>
                                                                            </tr>
                                                                            <tr>
                                                                              <td
                                                                                style={{
                                                                                  fontSize: "14px",
                                                                                  paddingTop: "12px",
                                                                                }}
                                                                              >
                                                                                <strong>Position / Bid Status</strong> :{" "}
                                                                                {item?.deactive ? (
                                                                                  <span
                                                                                    style={{
                                                                                      color: "red",
                                                                                      fontSize: "20px",
                                                                                      fontWeight: "600",
                                                                                      marginLeft: "5px",
                                                                                    }}
                                                                                  >
                                                                                    {" "}
                                                                                    Deactivated
                                                                                  </span>
                                                                                ) : item?.bs_id === 3 ? (
                                                                                  <span
                                                                                    style={{
                                                                                      color: "green",
                                                                                      fontSize: "20px",
                                                                                      fontWeight: "600",
                                                                                      marginLeft: "5px",
                                                                                    }}
                                                                                  >
                                                                                    {" "}
                                                                                    ACCEPTED
                                                                                  </span>
                                                                                ) : (
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: "20px",
                                                                                      fontWeight: "600",
                                                                                    }}
                                                                                  >
                                                                                    {item?.ct === 1
                                                                                      ? "1st"
                                                                                      : item?.ct === 2
                                                                                      ? "2nd"
                                                                                      : item?.ct === 3
                                                                                      ? "3rd"
                                                                                      : item?.ct + "th"}
                                                                                  </span>
                                                                                )}
                                                                                {item?.date_declined &&
                                                                                item?.date_declined?.toString()
                                                                                  ?.length > 0 &&
                                                                                !item?.deactive ? (
                                                                                  <span
                                                                                    style={{
                                                                                      color: "red",
                                                                                      fontSize: "14px",
                                                                                      fontWeight: "500",
                                                                                      marginLeft: "5px",
                                                                                    }}
                                                                                  >
                                                                                    {" "}
                                                                                    (Declined)
                                                                                  </span>
                                                                                ) : (
                                                                                  ""
                                                                                )}
                                                                              </td>
                                                                            </tr>

                                                                            <tr>
                                                                              <td
                                                                                style={{
                                                                                  fontSize: "14px",
                                                                                  paddingTop: "12px",
                                                                                }}
                                                                              >
                                                                                <strong>Last Bid Amount</strong> :
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: "20px",
                                                                                    fontWeight: "600",
                                                                                    paddingLeft: "6px",
                                                                                  }}
                                                                                >
                                                                                  {item?.total_bid
                                                                                    ? "$" +
                                                                                      numberFormat(
                                                                                        Math.round(item?.total_bid),
                                                                                      )
                                                                                    : "$0"}
                                                                                </span>
                                                                                {item?.bid_count &&
                                                                                item?.bs_id != 3 &&
                                                                                !item?.deactive ? (
                                                                                  <a
                                                                                    class={`button-hover ${
                                                                                      item?.auctionDetail?.accepted
                                                                                        ? "btn-accepted"
                                                                                        : ""
                                                                                    }`}
                                                                                    target="_blank"
                                                                                    href={`${siteUrl}listings/listing_detail.cfm/aucid/${
                                                                                      item?.auction_id
                                                                                    }/?redirect=${btoa(
                                                                                      `page=buyerARR&action=increaseOffer`,
                                                                                    )}`}
                                                                                    style={{
                                                                                      display: "inline",
                                                                                      textAlign: "center",
                                                                                      fontSize: "12px",
                                                                                      lineHeight: "1",
                                                                                      fontWeight: "500",
                                                                                      backgroundColor: "#47ad1d",
                                                                                      color: "#fff",
                                                                                      borderRadius: "4px",
                                                                                      padding: "3px",
                                                                                      textDecoration: "none",
                                                                                      marginLeft: "5px",
                                                                                    }}
                                                                                  >
                                                                                    Increase Offer
                                                                                  </a>
                                                                                ) : (
                                                                                  ""
                                                                                )}
                                                                              </td>
                                                                            </tr>

                                                                            {item?.reserve && item?.reserve > 0 ? (
                                                                              <tr>
                                                                                <td
                                                                                  style={{
                                                                                    fontSize: "14px",
                                                                                    paddingTop: "12px",
                                                                                  }}
                                                                                >
                                                                                  <strong>Proposed Asking Price</strong>{" "}
                                                                                  :
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: "20px",
                                                                                      fontWeight: "600",
                                                                                      paddingLeft: "6px",
                                                                                    }}
                                                                                  >
                                                                                    {item?.reserve_formatted
                                                                                      ? "$" + item?.reserve_formatted
                                                                                      : "N/A"}
                                                                                  </span>
                                                                                  {item?.bid_count &&
                                                                                  item?.bs_id != 3 &&
                                                                                  !item?.deactive ? (
                                                                                    <a
                                                                                      class={`button-hover ${
                                                                                        item?.auctionDetail?.accepted
                                                                                          ? "btn-accepted"
                                                                                          : ""
                                                                                      }`}
                                                                                      target="_blank"
                                                                                      href={`${siteUrl}listings/listing_detail.cfm/aucid/${
                                                                                        item?.auction_id
                                                                                      }/?redirect=${btoa(
                                                                                        `page=buyerARR&action=increaseOffer`,
                                                                                      )}`}
                                                                                      style={{
                                                                                        display: "inline",
                                                                                        textAlign: "center",
                                                                                        fontSize: "12px",
                                                                                        lineHeight: "1",
                                                                                        fontWeight: "500",
                                                                                        backgroundColor: "green",
                                                                                        color: "#fff",
                                                                                        borderRadius: "4px",
                                                                                        padding: "3px",
                                                                                        textDecoration: "none",
                                                                                        marginLeft: "5px",
                                                                                      }}
                                                                                    >
                                                                                      Counter Offer
                                                                                    </a>
                                                                                  ) : (
                                                                                    ""
                                                                                  )}
                                                                                </td>
                                                                              </tr>
                                                                            ) : (
                                                                              ""
                                                                            )}
                                                                            {item?.reduced_reserve &&
                                                                            item?.reduced_reserve >= 1 ? (
                                                                              <tr>
                                                                                <td
                                                                                  style={{
                                                                                    fontSize: "14px",
                                                                                    paddingTop: "12px",
                                                                                  }}
                                                                                >
                                                                                  <strong>Price Reduced By</strong> :
                                                                                  <span
                                                                                    style={{
                                                                                      fontWeight: "600",
                                                                                      paddingLeft: "6px",
                                                                                      fontSize: "20px",
                                                                                    }}
                                                                                  >
                                                                                    {item?.reduced_reserve
                                                                                      ? "$" +
                                                                                        numberFormat(
                                                                                          Math.round(
                                                                                            item?.reduced_reserve,
                                                                                          ),
                                                                                        )
                                                                                      : "$0"}{" "}
                                                                                  </span>
                                                                                  <span
                                                                                    style={{
                                                                                      fontWeight: "400",
                                                                                    }}
                                                                                  >
                                                                                    {item?.reduced_reserve_date
                                                                                      ? `(${item?.reduced_reserve_date})`
                                                                                      : ""}
                                                                                  </span>
                                                                                </td>
                                                                              </tr>
                                                                            ) : (
                                                                              ""
                                                                            )}
                                                                            {item?.wholesale && item?.wholesale > 0 ? (
                                                                              <tr>
                                                                                <td
                                                                                  style={{
                                                                                    fontSize: "14px",
                                                                                    paddingTop: "12px",
                                                                                  }}
                                                                                >
                                                                                  <strong>Wholesale Value</strong> :
                                                                                  <span
                                                                                    style={{
                                                                                      fontWeight: "600",
                                                                                      paddingLeft: "6px",
                                                                                      fontSize: "20px",
                                                                                    }}
                                                                                  >
                                                                                    ${item?.wholesale_formatted}
                                                                                  </span>
                                                                                </td>
                                                                              </tr>
                                                                            ) : (
                                                                              ""
                                                                            )}
                                                                            <tr>
                                                                              <td
                                                                                style={{
                                                                                  fontSize: "14px",
                                                                                  paddingTop: "12px",
                                                                                }}
                                                                              >
                                                                                <strong># of Bids</strong> :{" "}
                                                                                <span
                                                                                  style={{
                                                                                    fontWeight: "400",
                                                                                  }}
                                                                                >
                                                                                  {item?.bid_count
                                                                                    ? numberFormat(item?.bid_count)
                                                                                    : 0}{" "}
                                                                                  {item?.bid_count === 0 ||
                                                                                  item?.bid_count === 1 ||
                                                                                  !item?.bid_count
                                                                                    ? "Bid"
                                                                                    : "Bids"}
                                                                                </span>
                                                                              </td>
                                                                            </tr>
                                                                            {item?.detail?.location ? (
                                                                              <tr>
                                                                                <td
                                                                                  style={{
                                                                                    fontSize: "14px",
                                                                                    paddingTop: "12px",
                                                                                  }}
                                                                                >
                                                                                  <strong>Location</strong> :
                                                                                  <span
                                                                                    style={{
                                                                                      fontWeight: "400",
                                                                                      paddingLeft: "6px",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      dangerouslySetInnerHTML={{
                                                                                        __html:
                                                                                          item?.detail?.location?.toString(),
                                                                                      }}
                                                                                    />
                                                                                  </span>
                                                                                </td>
                                                                              </tr>
                                                                            ) : (
                                                                              ""
                                                                            )}
                                                                            {item?.detail?.conditionType ? (
                                                                              <tr>
                                                                                <td
                                                                                  style={{
                                                                                    fontSize: "14px",
                                                                                    paddingTop: "12px",
                                                                                  }}
                                                                                >
                                                                                  <strong>Condition</strong> :
                                                                                  <span
                                                                                    style={{
                                                                                      fontWeight: "400",
                                                                                      paddingLeft: "6px",
                                                                                    }}
                                                                                  >
                                                                                    {item?.detail?.conditionType}
                                                                                  </span>
                                                                                </td>
                                                                              </tr>
                                                                            ) : (
                                                                              ""
                                                                            )}
                                                                            <tr>
                                                                              <td
                                                                                style={{
                                                                                  fontSize: "14px",
                                                                                  paddingTop: "12px",
                                                                                }}
                                                                              >
                                                                                <strong>Seller Industry</strong> :
                                                                                <span
                                                                                  style={{
                                                                                    fontWeight: "400",
                                                                                    paddingLeft: "6px",
                                                                                  }}
                                                                                >
                                                                                  {item?.detail?.industry_description}
                                                                                </span>
                                                                              </td>
                                                                            </tr>
                                                                            <tr>
                                                                              <td
                                                                                style={{
                                                                                  fontSize: "14px",
                                                                                  paddingTop: "12px",
                                                                                }}
                                                                              >
                                                                                <strong>Sale Type End Date</strong> :
                                                                                <span
                                                                                  style={{
                                                                                    fontWeight: "400",
                                                                                    paddingLeft: "6px",
                                                                                  }}
                                                                                >
                                                                                  {!item?.detail?.end_date
                                                                                    ? "Active Negotiation"
                                                                                    : item?.detail?.end_date &&
                                                                                      moment(
                                                                                        item?.detail?.end_date,
                                                                                      ).isBefore(moment())
                                                                                    ? "Active Negotiation"
                                                                                    : moment(item?.detail?.end_date)
                                                                                        .add({ minute: diff })
                                                                                        .format("MMM-D-YYYY")}
                                                                                </span>
                                                                              </td>
                                                                            </tr>
                                                                            {item?.messages?.length > 0 ? (
                                                                              <>
                                                                                <tr>
                                                                                  <td style={{ paddingTop: "15px" }}>
                                                                                    <span
                                                                                      style={{
                                                                                        fontWeight: "400",
                                                                                      }}
                                                                                    >
                                                                                      {item?.messages?.map((dd, y) => (
                                                                                        <>
                                                                                          <div
                                                                                            className="d-block"
                                                                                            style={{
                                                                                              fontSize: "14px",
                                                                                              fontWeight: "600",
                                                                                            }}
                                                                                          >
                                                                                            Question {y + 1}:
                                                                                          </div>
                                                                                          <div
                                                                                            className="d-block"
                                                                                            style={{
                                                                                              fontSize: "14px",
                                                                                              marginBottom: "10px",
                                                                                            }}
                                                                                          >
                                                                                            {dd}{" "}
                                                                                          </div>
                                                                                          {item?.answers?.[y]?.length >
                                                                                            0 &&
                                                                                          item?.answers?.[y] ===
                                                                                            "viaEmail" &&
                                                                                          item?.allAnswers?.[y]
                                                                                            ?.length > 0 ? (
                                                                                            <>
                                                                                              <div
                                                                                                className="d-block"
                                                                                                style={{
                                                                                                  fontSize: "14px",
                                                                                                  fontWeight: "600",
                                                                                                }}
                                                                                              >
                                                                                                Answer {y + 1}:
                                                                                              </div>
                                                                                              <div
                                                                                                className="d-block"
                                                                                                style={{
                                                                                                  fontSize: "14px",
                                                                                                }}
                                                                                              >
                                                                                                {HTMLReactParser(
                                                                                                  item?.allAnswers?.[y],
                                                                                                )}
                                                                                              </div>
                                                                                            </>
                                                                                          ) : (
                                                                                            ""
                                                                                          )}
                                                                                          {item?.answers?.[y] &&
                                                                                          item?.answers?.[y]?.length >
                                                                                            0 &&
                                                                                          item?.answers?.[y] !==
                                                                                            "viaEmail" ? (
                                                                                            <>
                                                                                              <div
                                                                                                className="d-block"
                                                                                                style={{
                                                                                                  fontSize: "14px",
                                                                                                  fontWeight: "600",
                                                                                                }}
                                                                                              >
                                                                                                Answer {y + 1}:
                                                                                              </div>
                                                                                              <div
                                                                                                className="d-block"
                                                                                                style={{
                                                                                                  fontSize: "14px",
                                                                                                }}
                                                                                              >
                                                                                                {item?.answers?.[y]}
                                                                                              </div>
                                                                                            </>
                                                                                          ) : (
                                                                                            ""
                                                                                          )}
                                                                                          <br />
                                                                                        </>
                                                                                      ))}
                                                                                    </span>
                                                                                  </td>
                                                                                </tr>
                                                                              </>
                                                                            ) : (
                                                                              ""
                                                                            )}
                                                                            {item?.referrals?.length > 0 ? (
                                                                              <>
                                                                                <tr>
                                                                                  <td
                                                                                    style={{
                                                                                      fontSize: "14px",
                                                                                      paddingTop: "12px",
                                                                                    }}
                                                                                  >
                                                                                    <strong>Referrals</strong> :
                                                                                  </td>
                                                                                </tr>
                                                                                <tr>
                                                                                  <table
                                                                                    style={{
                                                                                      borderCollapse: "collapse",
                                                                                      captionSide: "bottom",
                                                                                      verticalAlign: "middle",
                                                                                      color: "#000",
                                                                                    }}
                                                                                  >
                                                                                    <tr>
                                                                                      <td
                                                                                        style={{
                                                                                          padding: "10px 20px 10px 0px",
                                                                                          minWidth: "280px",
                                                                                          maxWidth: "280px",
                                                                                          fontWeight: "600",
                                                                                          border: "1px solid black",
                                                                                          paddingLeft: "10px",
                                                                                          fontWeight: "400",
                                                                                          fontSize: "14px",
                                                                                        }}
                                                                                      >
                                                                                        Name
                                                                                      </td>
                                                                                      <td
                                                                                        style={{
                                                                                          padding:
                                                                                            "10px 100px 10px 0px",
                                                                                          minWidth: "100px",
                                                                                          maxWidth: "100px",
                                                                                          fontWeight: "600",
                                                                                          border: "1px solid black",
                                                                                          paddingLeft: "10px",
                                                                                          fontWeight: "400",
                                                                                          fontSize: "14px",
                                                                                        }}
                                                                                      >
                                                                                        Company
                                                                                      </td>
                                                                                    </tr>
                                                                                    {item?.referrals?.map((ue, o) => (
                                                                                      <tr>
                                                                                        <td
                                                                                          style={{
                                                                                            padding:
                                                                                              "10px 20px 10px 0px",
                                                                                            minWidth: "280px",
                                                                                            maxWidth: "280px",
                                                                                            border: "1px solid black",
                                                                                            paddingLeft: "10px",
                                                                                            fontWeight: "400",
                                                                                            fontSize: "14px",
                                                                                          }}
                                                                                        >
                                                                                          {ue?.buyer_name}
                                                                                          <br />
                                                                                          {ue?.buyer_email}
                                                                                        </td>
                                                                                        <td
                                                                                          style={{
                                                                                            padding:
                                                                                              "10px 100px 10px 0px",
                                                                                            minWidth: "100px",
                                                                                            maxWidth: "100px",
                                                                                            border: "1px solid black",
                                                                                            paddingLeft: "10px",
                                                                                            fontWeight: "400",
                                                                                            fontSize: "14px",
                                                                                          }}
                                                                                        >
                                                                                          {HTMLReactParser(
                                                                                            splitIntoLines(ue?.company),
                                                                                          )}
                                                                                        </td>
                                                                                      </tr>
                                                                                    ))}
                                                                                  </table>
                                                                                </tr>
                                                                              </>
                                                                            ) : (
                                                                              ""
                                                                            )}
                                                                          </table>
                                                                        </td>

                                                                        <td
                                                                          style={{
                                                                            textAlign: "-webkit-right",
                                                                            verticalAlign: "top",
                                                                            width: "30%",
                                                                          }}
                                                                        >
                                                                          <table style={{ width: "100%" }}>
                                                                            <tr>
                                                                              <td
                                                                                style={{
                                                                                  textAlign: "-webkit-right",
                                                                                }}
                                                                              >
                                                                                <table>
                                                                                  <tr>
                                                                                    {item?.detail?.bc_id_name ? (
                                                                                      <td style={{ textAlign: "end" }}>
                                                                                        <a
                                                                                          class="button-hover cursor-pointer"
                                                                                          target="_blank"
                                                                                          href={`${siteUrl}listings/listing_detail.cfm/aucid/${
                                                                                            item?.auction_id
                                                                                          }/?redirect=${btoa(
                                                                                            `page=buyerARR&action=askQuestion`,
                                                                                          )}`}
                                                                                          style={{
                                                                                            whiteSpace: "nowrap",
                                                                                            minWidth: "fit-content",
                                                                                            display: "inline-block",
                                                                                            textAlign: "center",
                                                                                            fontSize: "12px",
                                                                                            lineHeight: "1.5",
                                                                                            backgroundColor: "#47ad1d",
                                                                                            marginLeft: "5px",
                                                                                            fontWeight: "500",
                                                                                            color: "#fff",
                                                                                            borderRadius: "4px",
                                                                                            padding: "3px 3px",
                                                                                            textDecoration: "none",
                                                                                          }}
                                                                                        >
                                                                                          Ask a Question
                                                                                        </a>
                                                                                      </td>
                                                                                    ) : (
                                                                                      ""
                                                                                    )}
                                                                                    <td style={{ textAlign: "end" }}>
                                                                                      <a
                                                                                        class="button-hover cursor-pointer"
                                                                                        target="_blank"
                                                                                        href={`${siteUrl}listings/listing_detail.cfm/aucid/${
                                                                                          item?.auction_id
                                                                                        }/?redirect=${btoa(
                                                                                          `redirect=buyerARR`,
                                                                                        )}`}
                                                                                        style={{
                                                                                          whiteSpace: "nowrap",
                                                                                          minWidth: "fit-content",
                                                                                          display: "inline-block",
                                                                                          textAlign: "center",
                                                                                          fontSize: "12px",
                                                                                          lineHeight: "1.5",
                                                                                          fontWeight: "500",
                                                                                          backgroundColor: "gray",
                                                                                          color: "#fff",
                                                                                          borderRadius: "4px",
                                                                                          padding: "3px 3px",
                                                                                          marginLeft: "5px",

                                                                                          textDecoration: "none",
                                                                                          pointerEvents:
                                                                                            item?.wl_count > 0
                                                                                              ? "none"
                                                                                              : "auto",
                                                                                        }}
                                                                                      >
                                                                                        {(!item.wl_count ||
                                                                                          item.wl_count == 0 ||
                                                                                          item.wl_count == null) &&
                                                                                        !item.wl_id
                                                                                          ? "Watch Listing"
                                                                                          : "Added to watchlist"}
                                                                                      </a>
                                                                                    </td>
                                                                                  </tr>
                                                                                  <tr>
                                                                                    <td
                                                                                      colspan="2"
                                                                                      style={{ textAlign: "end" }}
                                                                                    >
                                                                                      {" "}
                                                                                      <a
                                                                                        class="button-hover cursor-pointer"
                                                                                        target="_blank"
                                                                                        href={`${siteUrl}listings/listing_detail.cfm/aucid/${
                                                                                          item?.auction_id
                                                                                        }/?redirect=${btoa(
                                                                                          `page=buyerARR&action=share`,
                                                                                        )}`}
                                                                                        style={{
                                                                                          whiteSpace: "nowrap",
                                                                                          minWidth: "fit-content",
                                                                                          display: "inline-block",
                                                                                          textAlign: "center",
                                                                                          fontSize: "12px",
                                                                                          lineHeight: "1.5",
                                                                                          fontWeight: "500",
                                                                                          backgroundColor: "#f2b61c",
                                                                                          color: "#fff",
                                                                                          borderRadius: "4px",
                                                                                          padding: "3px 3px",
                                                                                          marginLeft: "5px",
                                                                                          marginTop: "5px",
                                                                                          textDecoration: "none",
                                                                                        }}
                                                                                      >
                                                                                        Share with Buyers
                                                                                      </a>
                                                                                    </td>
                                                                                  </tr>
                                                                                </table>
                                                                              </td>
                                                                            </tr>
                                                                            <tr>
                                                                              <td>
                                                                                <table
                                                                                  style={{
                                                                                    width: "100%",
                                                                                  }}
                                                                                  cellspacing="0"
                                                                                  cellpadding="0"
                                                                                >
                                                                                  {item?.reserve &&
                                                                                  item?.reserve > 0 ? (
                                                                                    <tr>
                                                                                      <td
                                                                                        class="m-pl-0"
                                                                                        colspan="2"
                                                                                        style={{
                                                                                          paddingTop: "10px",
                                                                                        }}
                                                                                      >
                                                                                        <table
                                                                                          width="100%"
                                                                                          cellspacing="0"
                                                                                          cellpadding="0"
                                                                                        >
                                                                                          <tr>
                                                                                            <td
                                                                                              style={{
                                                                                                fontWeight: "500",
                                                                                                fontSize: "12px",
                                                                                              }}
                                                                                            >
                                                                                              Proposed Asking Price
                                                                                              History
                                                                                            </td>
                                                                                          </tr>
                                                                                          <tr>
                                                                                            <td
                                                                                              className="ps-map"
                                                                                              style={{
                                                                                                border:
                                                                                                  "1px solid black",
                                                                                                borderRadius: "4px",
                                                                                                padding: "16px 12px",
                                                                                              }}
                                                                                            >
                                                                                              <img
                                                                                                src={item?.chartUrl}
                                                                                              />
                                                                                            </td>
                                                                                          </tr>
                                                                                        </table>
                                                                                      </td>
                                                                                    </tr>
                                                                                  ) : (
                                                                                    ""
                                                                                  )}
                                                                                  {item?.detail?.world_map_image &&
                                                                                  (item?.detail?.world_map_image?.startsWith(
                                                                                    "worldMap",
                                                                                  ) ||
                                                                                    item?.detail?.world_map_image ===
                                                                                      "data_not_found_in_GA") ? (
                                                                                    <>
                                                                                      <tr>
                                                                                        <td
                                                                                          style={{
                                                                                            fontWeight: "500",
                                                                                            fontSize: "12px",
                                                                                            paddingTop: "10px",
                                                                                          }}
                                                                                        >
                                                                                          Activity Per Country
                                                                                        </td>
                                                                                      </tr>
                                                                                      <tr>
                                                                                        <td
                                                                                          className="ps-map"
                                                                                          style={{
                                                                                            border: "1px solid black",
                                                                                            borderRadius: "4px",
                                                                                            padding: "16px 12px",
                                                                                          }}
                                                                                        >
                                                                                          {item?.detail
                                                                                            ?.world_map_image ===
                                                                                          "data_not_found_in_GA" ? (
                                                                                            <img
                                                                                              src={
                                                                                                "https://s3.amazonaws.com/uploads-dev.salvex.com/worldMaps/world_map_default.jpeg"
                                                                                              }
                                                                                            />
                                                                                          ) : (
                                                                                            <img
                                                                                              src={
                                                                                                process.env
                                                                                                  .REACT_APP_MODE ===
                                                                                                "development"
                                                                                                  ? "https://s3.amazonaws.com/uploads.salvex.com/" +
                                                                                                    item?.detail
                                                                                                      ?.world_map_image
                                                                                                  : "https://s3.amazonaws.com/uploads.salvex.com/" +
                                                                                                    item?.detail
                                                                                                      ?.world_map_image
                                                                                              }
                                                                                            />
                                                                                          )}
                                                                                          {/* <WorldMap
                                                                                        googleAnalyticData={
                                                                                          item?.googleAnalytic
                                                                                        }
                                                                                        id={item?.auction_id}
                                                                                      /> */}
                                                                                        </td>
                                                                                      </tr>
                                                                                    </>
                                                                                  ) : (
                                                                                    ""
                                                                                  )}
                                                                                </table>
                                                                              </td>
                                                                            </tr>
                                                                          </table>{" "}
                                                                        </td>
                                                                      </tr>
                                                                    </table>
                                                                  </td>
                                                                </tr>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}

                        {!isSectionAvailable && !isNoContentLoaded ? (
                          <>
                            {" "}
                            <tr>
                              <td
                                class="xs-p-15"
                                style={{
                                  padding: "8px 32px",
                                  backgroundColor: "#47ad1d",
                                  color: "#fff",
                                }}
                              >
                                <table width="100%" cellspacing="0" cellpadding="0">
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "18px",
                                        lineHeight: "1.6",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Current Items - Bidding
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td class="xs-p-15" style={{ padding: "20px 32px" }}>
                                You currently have no bids on any items.{" "}
                                <a
                                  href={siteUrl}
                                  target="_blank"
                                  style={{ fontWeight: "500", fontSize: "14px", color: "#47ad1d", cursor: "pointer" }}
                                >
                                  {" "}
                                  Click here
                                </a>{" "}
                                to browse listings and place a bid.
                              </td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}

                        {/* Invoice Listing Ends */}

                        {/* All 3 Section Starts here */}
                        {allSectionData?.length > 0 ? (
                          <>
                            <tr>
                              <td
                                class="xs-p-15"
                                style={{
                                  padding: "8px 32px",
                                  backgroundColor: "#47ad1d",
                                  color: "#fff",
                                }}
                              >
                                <table width="100%" cellspacing="0" cellpadding="0">
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "18px",
                                        lineHeight: "1.6",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Current Items - Questions, Watching And Referrals
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>

                            {allSectionData?.length > 0 ? (
                              <tr>
                                <td class="xs-p-15" style={{ padding: "20px 32px" }}>
                                  <table cellspacing="0" cellpadding="0" style={{ minWidth: "100%" }}>
                                    <tbody>
                                      {allSectionData?.map((item) => {
                                        return (
                                          <tr>
                                            <td style={{ paddingBottom: "15px" }}>
                                              <table style={{ width: "100%" }} cellspacing="0" cellpadding="0">
                                                {" "}
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        padding: "15px",
                                                        border: "1px solid black",
                                                        borderRadius: "15px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <table style={{ width: "100%" }} cellspacing="0" cellpadding="0">
                                                        <tbody>
                                                          <tr>
                                                            <td
                                                              style={{
                                                                fontSize: "16px",
                                                                lineHeight: "1.2",
                                                                fontWeight: "700",
                                                                paddingBottom: "12px",
                                                                display: "flex",
                                                                alignItems: "start",
                                                                justifyContent: "start",
                                                              }}
                                                            >
                                                              <table style={{ width: "100%" }}>
                                                                <tr>
                                                                  <td style={{ verticalAlign: "top" }}>
                                                                    <table>
                                                                      <tr>
                                                                        <a
                                                                          style={{
                                                                            display: "inline-block",
                                                                          }}
                                                                          class="flex-grow-1 link-item"
                                                                          href={`/listing-detail?id=${item?.auction_id}`}
                                                                          target="_blank"
                                                                        >
                                                                          {item?.auction_id} - {item?.detail?.title}
                                                                        </a>
                                                                      </tr>
                                                                      {item?.reserve_formatted &&
                                                                      item?.reserve_formatted !== "0.00" ? (
                                                                        <tr>
                                                                          <td
                                                                            style={{
                                                                              fontSize: "14px",
                                                                              paddingTop: "12px",
                                                                            }}
                                                                          >
                                                                            <strong>Proposed Asking Price</strong> :
                                                                            <span
                                                                              style={{
                                                                                fontSize: "20px",
                                                                                fontWeight: "600",
                                                                                paddingLeft: "6px",
                                                                              }}
                                                                            >
                                                                              {item?.reserve_formatted
                                                                                ? "$" + item?.reserve_formatted
                                                                                : "N/A"}
                                                                            </span>
                                                                          </td>
                                                                        </tr>
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                      {item?.reduced_reserve &&
                                                                      item?.reduced_reserve >= 1 ? (
                                                                        <tr>
                                                                          <td
                                                                            style={{
                                                                              fontSize: "14px",
                                                                              paddingTop: "12px",
                                                                            }}
                                                                          >
                                                                            <strong>Price Reduced By</strong> :
                                                                            <span
                                                                              style={{
                                                                                fontWeight: "600",
                                                                                paddingLeft: "6px",
                                                                                fontSize: "20px",
                                                                              }}
                                                                            >
                                                                              {item?.reduced_reserve
                                                                                ? "$" +
                                                                                  numberFormat(
                                                                                    Math.round(item?.reduced_reserve),
                                                                                  )
                                                                                : "$0"}{" "}
                                                                            </span>{" "}
                                                                            <span
                                                                              style={{
                                                                                fontWeight: "400",
                                                                              }}
                                                                            >
                                                                              {item?.reduced_reserve_date
                                                                                ? `(${item?.reduced_reserve_date})`
                                                                                : ""}
                                                                            </span>
                                                                          </td>
                                                                        </tr>
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                      {item?.wholesale_formatted &&
                                                                      item?.wholesale_formatted !== "0.00" ? (
                                                                        <tr>
                                                                          <td
                                                                            style={{
                                                                              fontSize: "14px",
                                                                              paddingTop: "12px",
                                                                            }}
                                                                          >
                                                                            <strong>Wholesale Value</strong> :
                                                                            <span
                                                                              style={{
                                                                                fontWeight: "600",
                                                                                paddingLeft: "6px",
                                                                                fontSize: "20px",
                                                                              }}
                                                                            >
                                                                              ${item?.wholesale_formatted}
                                                                            </span>
                                                                          </td>
                                                                        </tr>
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                      {item?.detail?.location ? (
                                                                        <tr>
                                                                          <td
                                                                            style={{
                                                                              fontSize: "14px",
                                                                              paddingTop: "12px",
                                                                            }}
                                                                          >
                                                                            <strong>Location</strong> :
                                                                            <span
                                                                              style={{
                                                                                fontWeight: "400",
                                                                                paddingLeft: "6px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                dangerouslySetInnerHTML={{
                                                                                  __html:
                                                                                    item?.detail?.location?.toString(),
                                                                                }}
                                                                              />
                                                                            </span>
                                                                          </td>
                                                                        </tr>
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                      {item?.detail?.conditionType ? (
                                                                        <tr>
                                                                          <td
                                                                            style={{
                                                                              fontSize: "14px",
                                                                              paddingTop: "12px",
                                                                            }}
                                                                          >
                                                                            <strong>Condition</strong> :
                                                                            <span
                                                                              style={{
                                                                                fontWeight: "400",
                                                                                paddingLeft: "6px",
                                                                              }}
                                                                            >
                                                                              {item?.detail?.conditionType}
                                                                            </span>
                                                                          </td>
                                                                        </tr>
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            fontSize: "14px",
                                                                            paddingTop: "12px",
                                                                          }}
                                                                        >
                                                                          <strong>Seller Industry</strong> :
                                                                          <span
                                                                            style={{
                                                                              fontWeight: "400",
                                                                              paddingLeft: "6px",
                                                                            }}
                                                                          >
                                                                            {item?.detail?.industry_description}
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            fontSize: "14px",
                                                                            paddingTop: "12px",
                                                                          }}
                                                                        >
                                                                          <strong>Sale Type End Date</strong> :
                                                                          <span
                                                                            style={{
                                                                              fontWeight: "400",
                                                                              paddingLeft: "6px",
                                                                            }}
                                                                          >
                                                                            {!item?.detail?.end_date
                                                                              ? "Active Negotiation"
                                                                              : item?.detail?.end_date &&
                                                                                moment(item?.detail?.end_date).isBefore(
                                                                                  moment(),
                                                                                )
                                                                              ? "Active Negotiation"
                                                                              : moment(item?.detail?.end_date)
                                                                                  .add({ minute: diff })
                                                                                  .format("MMM-D-YYYY")}
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                      {item?.messages?.length > 0 ? (
                                                                        <>
                                                                          <tr>
                                                                            <td style={{ paddingTop: "15px" }}>
                                                                              <span
                                                                                style={{
                                                                                  fontWeight: "400",
                                                                                }}
                                                                              >
                                                                                {item?.messages?.map((dd, y) => (
                                                                                  <>
                                                                                    <div
                                                                                      className="d-block"
                                                                                      style={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: "600",
                                                                                      }}
                                                                                    >
                                                                                      Question {y + 1}:
                                                                                    </div>
                                                                                    <div
                                                                                      className="d-block"
                                                                                      style={{
                                                                                        fontSize: "14px",
                                                                                        marginBottom: "10px",
                                                                                      }}
                                                                                    >
                                                                                      {dd}{" "}
                                                                                    </div>
                                                                                    {item?.answers?.[y]?.length > 0 &&
                                                                                    item?.answers?.[y] === "viaEmail" &&
                                                                                    item?.allAnswers?.[y]?.length >
                                                                                      0 ? (
                                                                                      <>
                                                                                        <div
                                                                                          className="d-block"
                                                                                          style={{
                                                                                            fontSize: "14px",
                                                                                            fontWeight: "600",
                                                                                          }}
                                                                                        >
                                                                                          Answer {y + 1}:
                                                                                        </div>
                                                                                        <div
                                                                                          className="d-block"
                                                                                          style={{
                                                                                            fontSize: "14px",
                                                                                          }}
                                                                                        >
                                                                                          {item?.allAnswers?.[y]
                                                                                            ? HTMLReactParser(
                                                                                                item?.allAnswers?.[y],
                                                                                              )
                                                                                            : ""}
                                                                                        </div>
                                                                                      </>
                                                                                    ) : (
                                                                                      ""
                                                                                    )}
                                                                                    {item?.answers?.[y] &&
                                                                                    item?.answers?.[y]?.length > 0 &&
                                                                                    item?.answers?.[y] !==
                                                                                      "viaEmail" ? (
                                                                                      <>
                                                                                        <div
                                                                                          className="d-block"
                                                                                          style={{
                                                                                            fontSize: "14px",
                                                                                            fontWeight: "600",
                                                                                          }}
                                                                                        >
                                                                                          Answer {y + 1}:
                                                                                        </div>
                                                                                        <div
                                                                                          className="d-block"
                                                                                          style={{
                                                                                            fontSize: "14px",
                                                                                          }}
                                                                                        >
                                                                                          {item?.answers?.[y]}
                                                                                        </div>
                                                                                      </>
                                                                                    ) : (
                                                                                      ""
                                                                                    )}
                                                                                    <br />
                                                                                  </>
                                                                                ))}
                                                                              </span>
                                                                            </td>
                                                                          </tr>
                                                                        </>
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                      {item?.referrals?.length > 0 ? (
                                                                        <>
                                                                          <tr>
                                                                            <td
                                                                              style={{
                                                                                fontSize: "14px",
                                                                                paddingTop: "12px",
                                                                              }}
                                                                            >
                                                                              <strong>Referrals</strong> :
                                                                            </td>
                                                                          </tr>
                                                                          <tr>
                                                                            <table
                                                                              style={{
                                                                                borderCollapse: "collapse",
                                                                                captionSide: "bottom",
                                                                                verticalAlign: "middle",
                                                                                color: "#000",
                                                                              }}
                                                                            >
                                                                              <tr>
                                                                                <td
                                                                                  style={{
                                                                                    padding: "10px 20px 10px 0px",
                                                                                    minWidth: "280px",
                                                                                    maxWidth: "280px",
                                                                                    fontWeight: "600",
                                                                                    border: "1px solid black",
                                                                                    paddingLeft: "10px",
                                                                                    fontWeight: "400",
                                                                                    fontSize: "14px",
                                                                                  }}
                                                                                >
                                                                                  Name
                                                                                </td>
                                                                                <td
                                                                                  style={{
                                                                                    padding: "10px 100px 10px 0px",
                                                                                    minWidth: "100px",
                                                                                    maxWidth: "100px",
                                                                                    fontWeight: "600",
                                                                                    border: "1px solid black",
                                                                                    paddingLeft: "10px",
                                                                                    fontWeight: "400",
                                                                                    fontSize: "14px",
                                                                                  }}
                                                                                >
                                                                                  Company
                                                                                </td>
                                                                              </tr>
                                                                              {item?.referrals?.map((ue, o) => (
                                                                                <tr>
                                                                                  <td
                                                                                    style={{
                                                                                      padding: "10px 20px 10px 0px",
                                                                                      minWidth: "280px",
                                                                                      maxWidth: "280px",
                                                                                      border: "1px solid black",
                                                                                      paddingLeft: "10px",
                                                                                      fontWeight: "400",
                                                                                      fontSize: "14px",
                                                                                    }}
                                                                                  >
                                                                                    {ue?.buyer_name}
                                                                                    <br />
                                                                                    {ue?.buyer_email}
                                                                                  </td>
                                                                                  <td
                                                                                    style={{
                                                                                      padding: "10px 100px 10px 0px",
                                                                                      minWidth: "100px",
                                                                                      maxWidth: "100px",
                                                                                      border: "1px solid black",
                                                                                      paddingLeft: "10px",
                                                                                      fontWeight: "400",
                                                                                      fontSize: "14px",
                                                                                    }}
                                                                                  >
                                                                                    {HTMLReactParser(
                                                                                      splitIntoLines(ue?.company),
                                                                                    )}
                                                                                  </td>
                                                                                </tr>
                                                                              ))}
                                                                            </table>
                                                                          </tr>
                                                                        </>
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                    </table>{" "}
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      width: "30%",
                                                                      textAlign: "-webkit-right",
                                                                      verticalAlign: "top",
                                                                    }}
                                                                  >
                                                                    <table style={{ width: "100%" }}>
                                                                      <tr>
                                                                        <td style={{ textAlign: "-webkit-right" }}>
                                                                          <table>
                                                                            <tr>
                                                                              <td style={{ textAlign: "end" }}>
                                                                                <a
                                                                                  class="button-hover cursor-pointer"
                                                                                  target="_blank"
                                                                                  href={`${siteUrl}listings/listing_detail.cfm/aucid/${
                                                                                    item?.auction_id
                                                                                  }/?redirect=${btoa(
                                                                                    `redirect=buyerARR`,
                                                                                  )}`}
                                                                                  style={{
                                                                                    whiteSpace: "nowrap",
                                                                                    minWidth: "fit-content",
                                                                                    display: "inline-block",
                                                                                    textAlign: "center",
                                                                                    fontSize: "12px",
                                                                                    lineHeight: "1.5",
                                                                                    fontWeight: "500",
                                                                                    backgroundColor: "gray",
                                                                                    color: "#fff",
                                                                                    borderRadius: "4px",
                                                                                    padding: "3px 3px",
                                                                                    marginLeft: "5px",

                                                                                    textDecoration: "none",
                                                                                    pointerEvents:
                                                                                      item?.wl_count > 0
                                                                                        ? "none"
                                                                                        : "auto",
                                                                                  }}
                                                                                >
                                                                                  {(!item.wl_count ||
                                                                                    item.wl_count == 0 ||
                                                                                    item.wl_count == null) &&
                                                                                  !item.wl_id
                                                                                    ? "Watch Listing"
                                                                                    : "Added to watchlist"}
                                                                                </a>
                                                                              </td>

                                                                              <td style={{ textAlign: "end" }}>
                                                                                {" "}
                                                                                <a
                                                                                  class="button-hover cursor-pointer"
                                                                                  target="_blank"
                                                                                  href={`${siteUrl}listings/listing_detail.cfm/aucid/${
                                                                                    item?.auction_id
                                                                                  }/?redirect=${btoa(
                                                                                    `page=buyerARR&action=askQuestion`,
                                                                                  )}`}
                                                                                  style={{
                                                                                    whiteSpace: "nowrap",
                                                                                    minWidth: "fit-content",
                                                                                    display: "inline-block",
                                                                                    textAlign: "center",
                                                                                    fontSize: "12px",
                                                                                    lineHeight: "1.5",
                                                                                    fontWeight: "500",
                                                                                    backgroundColor: "#47ad1d",
                                                                                    color: "#fff",
                                                                                    borderRadius: "4px",
                                                                                    padding: "3px 3px",
                                                                                    marginLeft: "5px",
                                                                                    textDecoration: "none",
                                                                                  }}
                                                                                >
                                                                                  Ask a Question
                                                                                </a>
                                                                              </td>
                                                                            </tr>
                                                                          </table>
                                                                          <table>
                                                                            <tr>
                                                                              <td style={{ textAlign: "end" }}>
                                                                                {" "}
                                                                                <a
                                                                                  class="button-hover cursor-pointer"
                                                                                  target="_blank"
                                                                                  href={`${siteUrl}listings/listing_detail.cfm/aucid/${
                                                                                    item?.auction_id
                                                                                  }/?redirect=${btoa(
                                                                                    `page=buyerARR&action=share`,
                                                                                  )}`}
                                                                                  style={{
                                                                                    whiteSpace: "nowrap",
                                                                                    minWidth: "fit-content",
                                                                                    display: "inline-block",
                                                                                    textAlign: "center",
                                                                                    fontSize: "12px",
                                                                                    lineHeight: "1.5",
                                                                                    fontWeight: "500",
                                                                                    backgroundColor: "#f2b61c",
                                                                                    color: "#fff",
                                                                                    borderRadius: "4px",
                                                                                    padding: "3px 3px",
                                                                                    marginLeft: "5px",
                                                                                    marginTop: "5px",
                                                                                    textDecoration: "none",
                                                                                  }}
                                                                                >
                                                                                  Share with Buyers
                                                                                </a>
                                                                              </td>
                                                                              {item?.detail?.bc_id_name ? (
                                                                                <td style={{ textAlign: "end" }}>
                                                                                  <a
                                                                                    class="button-hover cursor-pointer"
                                                                                    target="_blank"
                                                                                    href={`${siteUrl}listings/listing_detail.cfm/aucid/${
                                                                                      item?.auction_id
                                                                                    }/?redirect=${btoa(
                                                                                      `page=buyerARR&action=increaseOffer`,
                                                                                    )}`}
                                                                                    style={{
                                                                                      whiteSpace: "nowrap",
                                                                                      minWidth: "fit-content",
                                                                                      display: "inline-block",
                                                                                      textAlign: "center",
                                                                                      fontSize: "12px",
                                                                                      lineHeight: "1.5",
                                                                                      fontWeight: "500",
                                                                                      backgroundColor: "green",
                                                                                      color: "#fff",
                                                                                      borderRadius: "4px",
                                                                                      padding: "3px 3px",
                                                                                      marginLeft: "5px",
                                                                                      marginTop: "5px",
                                                                                      textDecoration: "none",
                                                                                    }}
                                                                                  >
                                                                                    Place a Bid
                                                                                  </a>
                                                                                </td>
                                                                              ) : (
                                                                                ""
                                                                              )}
                                                                            </tr>
                                                                          </table>
                                                                        </td>
                                                                      </tr>
                                                                      <tr>
                                                                        <td>
                                                                          <table
                                                                            style={{
                                                                              width: "100%",
                                                                            }}
                                                                            cellspacing="0"
                                                                            cellpadding="0"
                                                                          >
                                                                            {item?.reserve && item?.reserve > 0 ? (
                                                                              <tr>
                                                                                <td
                                                                                  class="m-pl-0"
                                                                                  colspan="2"
                                                                                  style={{
                                                                                    paddingTop: "10px",
                                                                                  }}
                                                                                >
                                                                                  <table
                                                                                    width="100%"
                                                                                    cellspacing="0"
                                                                                    cellpadding="0"
                                                                                  >
                                                                                    <tr>
                                                                                      <td
                                                                                        style={{
                                                                                          fontWeight: "500",
                                                                                          fontSize: "12px",
                                                                                        }}
                                                                                      >
                                                                                        Proposed Asking Price History
                                                                                      </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                      <td
                                                                                        className="ps-map"
                                                                                        style={{
                                                                                          border: "1px solid black",
                                                                                          borderRadius: "4px",
                                                                                          padding: "16px 12px",
                                                                                        }}
                                                                                      >
                                                                                        <img src={item?.chartUrl} />
                                                                                      </td>
                                                                                    </tr>
                                                                                  </table>
                                                                                </td>
                                                                              </tr>
                                                                            ) : (
                                                                              ""
                                                                            )}
                                                                            {item?.detail?.world_map_image &&
                                                                            (item?.detail?.world_map_image?.startsWith(
                                                                              "worldMap",
                                                                            ) ||
                                                                              item?.detail?.world_map_image ===
                                                                                "data_not_found_in_GA") ? (
                                                                              <>
                                                                                <tr>
                                                                                  <td
                                                                                    style={{
                                                                                      fontWeight: "500",
                                                                                      fontSize: "12px",
                                                                                      paddingTop: "10px",
                                                                                    }}
                                                                                  >
                                                                                    Activity Per Country
                                                                                  </td>
                                                                                </tr>
                                                                                <tr>
                                                                                  <td
                                                                                    className="ps-map"
                                                                                    style={{
                                                                                      border: "1px solid black",
                                                                                      borderRadius: "4px",
                                                                                      padding: "16px 12px",
                                                                                    }}
                                                                                  >
                                                                                    {item?.detail?.world_map_image ===
                                                                                    "data_not_found_in_GA" ? (
                                                                                      <img
                                                                                        src={
                                                                                          "https://s3.amazonaws.com/uploads-dev.salvex.com/worldMaps/world_map_default.jpeg"
                                                                                        }
                                                                                      />
                                                                                    ) : (
                                                                                      <img
                                                                                        src={
                                                                                          process.env.REACT_APP_MODE ===
                                                                                          "development"
                                                                                            ? "https://s3.amazonaws.com/uploads.salvex.com/" +
                                                                                              item?.detail
                                                                                                ?.world_map_image
                                                                                            : "https://s3.amazonaws.com/uploads.salvex.com/" +
                                                                                              item?.detail
                                                                                                ?.world_map_image
                                                                                        }
                                                                                      />
                                                                                    )}
                                                                                    {/* <WorldMap
                                                                                        googleAnalyticData={
                                                                                          item?.googleAnalytic
                                                                                        }
                                                                                        id={item?.auction_id}
                                                                                      /> */}
                                                                                  </td>
                                                                                </tr>
                                                                              </>
                                                                            ) : (
                                                                              ""
                                                                            )}
                                                                          </table>
                                                                        </td>
                                                                      </tr>
                                                                    </table>{" "}
                                                                  </td>
                                                                </tr>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {/* All 3 Section ends here */}
                        {/* Preferences Start */}
                        {preferenceAuctionData?.length > 0 ? (
                          <>
                            <tr>
                              <td
                                class="xs-p-15"
                                style={{
                                  padding: "8px 32px",
                                  backgroundColor: "#47ad1d",
                                  color: "#fff",
                                }}
                              >
                                <table width="100%" cellspacing="0" cellpadding="0">
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "18px",
                                        lineHeight: "1.6",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Items that match your preferences
                                    </td>
                                    <td style={{ textAlign: "end" }}>
                                      <a
                                        class="button-hover cursor-pointer"
                                        target="_blank"
                                        href={`${siteUrl}search`}
                                        style={{
                                          whiteSpace: "nowrap",
                                          minWidth: "fit-content",
                                          display: "inline-block",
                                          textAlign: "center",
                                          fontSize: "12px",
                                          lineHeight: "1.5",
                                          fontWeight: "500",
                                          backgroundColor: "green",
                                          color: "#fff",
                                          borderRadius: "4px",
                                          padding: "5px 7px",
                                          marginTop: "5px",
                                          marginRight: "10px",
                                          textDecoration: "none",
                                        }}
                                      >
                                        Search Listings
                                      </a>
                                      <a
                                        class="button-hover cursor-pointer"
                                        target="_blank"
                                        href={`${siteUrl}my-account/category-preferences/`}
                                        style={{
                                          whiteSpace: "nowrap",
                                          minWidth: "fit-content",
                                          display: "inline-block",
                                          textAlign: "center",
                                          fontSize: "12px",
                                          lineHeight: "1.5",
                                          fontWeight: "500",
                                          backgroundColor: "green",
                                          color: "#fff",
                                          borderRadius: "4px",
                                          padding: "5px 7px",
                                          marginTop: "5px",
                                          textDecoration: "none",
                                        }}
                                      >
                                        Change Preferences
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan={"3"}
                                      style={{
                                        fontSize: "14px",
                                        lineHeight: "1.6",
                                        fontWeight: "700",
                                      }}
                                    >
                                      These are listings which match your category and region preferences
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>

                            {preferenceAuctionData?.length > 0 ? (
                              <tr>
                                <td class="xs-p-15" style={{ padding: "20px 32px" }}>
                                  <table cellspacing="0" cellpadding="0" style={{ minWidth: "100%" }}>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <table
                                            style={{
                                              borderCollapse: "collapse",
                                              marginBottom: "1rem",
                                              width: "100%",
                                              captionSide: "bottom",
                                              verticalAlign: "middle",
                                              color: "#000",
                                            }}
                                          >
                                            <thead>
                                              <tr>
                                                <th
                                                  style={{ width: "10%", border: "1px solid #000 ", padding: ".7rem" }}
                                                >
                                                  ID{" "}
                                                </th>
                                                <th
                                                  style={{ width: "35%", border: "1px solid #000 ", padding: ".7rem" }}
                                                >
                                                  Title{" "}
                                                </th>
                                                <th
                                                  style={{ width: "20%", border: "1px solid #000 ", padding: ".7rem" }}
                                                >
                                                  Location
                                                </th>
                                                <th
                                                  style={{
                                                    width: "35%",
                                                    textAlign: "end",
                                                    border: "1px solid #000 ",
                                                    padding: ".7rem",
                                                  }}
                                                >
                                                  Pricing
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {preferenceAuctionData?.map((it) => {
                                                return (
                                                  <tr>
                                                    <td style={{ border: "1px solid #000 ", padding: ".7rem" }}>
                                                      <span> {it?.auction_id}</span>
                                                    </td>
                                                    <td style={{ border: "1px solid #000 ", padding: ".7rem" }}>
                                                      <span style={{ fontWeight: "700" }}> {it?.title}</span>
                                                    </td>
                                                    <td style={{ border: "1px solid #000 ", padding: ".7rem" }}>
                                                      {" "}
                                                      <span
                                                        dangerouslySetInnerHTML={{
                                                          __html: it?.location?.toString(),
                                                        }}
                                                      />
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "-webkit-right",
                                                        border: "1px solid #000 ",
                                                        padding: ".7rem",
                                                      }}
                                                    >
                                                      <table>
                                                        <tbody>
                                                          {it?.reserve && it?.reserve > 0 ? (
                                                            <tr>
                                                              <td style={{ textAlign: "-webkit-right" }}>
                                                                {it?.reduced_reserve && it?.reduced_reserve > 0
                                                                  ? "New Asking Price"
                                                                  : "Asking Price"}{" "}
                                                                :{" "}
                                                                <span style={{ fontWeight: "700" }}>
                                                                  {"$" + it?.reserve_formatted}
                                                                </span>{" "}
                                                              </td>
                                                            </tr>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {it?.reduced_reserve && it?.reduced_reserve > 0 ? (
                                                            <tr>
                                                              <td style={{ textAlign: "-webkit-right" }}>
                                                                Price Reduction :{" "}
                                                                <span style={{ fontWeight: "700" }}>
                                                                  {" "}
                                                                  {"$" + it?.reduced_reserve_formatted}
                                                                </span>
                                                                <br />
                                                                <span>
                                                                  {" "}
                                                                  {it?.reduced_reserve_date
                                                                    ? `(${it?.reduced_reserve_date})`
                                                                    : ""}
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {it?.wholesale && it?.wholesale > 0 ? (
                                                            <tr>
                                                              <td style={{ textAlign: "-webkit-right" }}>
                                                                Wholesale Value :{" "}
                                                                <span style={{ fontWeight: "700" }}>
                                                                  {" "}
                                                                  {"$" + it?.wholesale_formatted}
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <a
                                            class="button-hover cursor-pointer"
                                            target="_blank"
                                            href={`${siteUrl}my-account/category-preferences/`}
                                            style={{
                                              minWidth: "100%",
                                              display: "inline-block",
                                              textAlign: "center",
                                              fontSize: "16px",
                                              lineHeight: "1.5",
                                              backgroundColor: "#47ad1d",
                                              fontWeight: "500",
                                              color: "#fff",
                                              borderRadius: "4px",
                                              padding: "10px 15px",
                                              textDecoration: "none",
                                            }}
                                          >
                                            Change Category and Region Preferences
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {/* Preferences ends */}
                        {!isNoContentLoaded ? (
                          <>
                            <tr>
                              <td class="xs-p-15" style={{ padding: "0px 32px" }}>
                                <table style={{ width: "100%" }} cellspacing="0" cellpadding="0"></table>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: "0 32px 0px" }}>
                                <table
                                  style={{
                                    width: "100%",
                                    borderTop: "1px solid #e4e4e4",
                                    paddingTop: "15px",
                                  }}
                                  cellspacing="0"
                                  cellpadding="0"
                                >
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "14px",
                                        lineHeight: "1.5",
                                        paddingTop: "15px",
                                        paddingBottom: "15px",
                                      }}
                                    >
                                      This message is intended only for the use of the individual to whom it is
                                      addressed and contains information that is privileged, confidential and exempt
                                      from disclosure under applicable law. If you are not the intended recipient, or
                                      the employee or agent responsible for delivering this message to the intended
                                      recipient, you are hereby notified that any unauthorized disclosure,
                                      dissemination, distribution or copying of this communication is strictly
                                      prohibited. If you have received this communication in error, or are not sure
                                      whether it is privileged, please immediately notify us. Thank you.
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: "0 32px 30px" }}>
                                <table
                                  style={{
                                    width: "100%",
                                    borderTop: "1px solid #e4e4e4",
                                    paddingTop: "15px",
                                  }}
                                  cellspacing="0"
                                  cellpadding="0"
                                >
                                  <tr>
                                    <td
                                      style={{
                                        paddingTop: "15px",
                                        fontSize: "14px",
                                        lineHeight: "1.5",
                                        paddingBottom: "10px",
                                      }}
                                    >
                                      {" "}
                                      If you want to change the frequency of receiving this report,{" "}
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          color: "#47ad1d",
                                          textDecoration: "underline",
                                          display: "inline",
                                        }}
                                        target="_blank"
                                        href={`${siteUrl}members/buyer-report-preferences/?token=${token}`}
                                      >
                                        click here to set up your preferences.{" "}
                                      </a>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td style={{ fontSize: "14px", lineHeight: "1.5" }}>
                                      Questions? Contact us at{" "}
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          color: "#47ad1d",
                                          textDecoration: "underline",
                                        }}
                                        href="mailto:sales@salvex.com"
                                      >
                                        sales@salvex.com
                                      </a>
                                      , or call us at{" "}
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          color: "#47ad1d",
                                          textDecoration: "underline",
                                        }}
                                        href="tel:+17132299000"
                                      >
                                        +1 713.229.9000
                                      </a>
                                      <br />
                                      440 Louisiana Street, Suite 550, Houston, TX 77002
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          color: "#47ad1d",
                                          textDecoration: "underline",
                                        }}
                                        href={
                                          process.env.REACT_APP_MODE === "development"
                                            ? "https://newdev.salvex.com/terms-and-conditions"
                                            : "https://salvex.com/terms-and-conditions"
                                        }
                                        target="_blank"
                                      >
                                        Click here
                                      </a>{" "}
                                      to visit salvex terms and conditions.
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        ) : (
          <tr>
            <td class="xs-p-0" align="center" valign="top" style={{ padding: "5px 0" }}>
              <table
                class="w-full-mobile"
                cellspacing="0"
                cellpadding="0"
                style={{ width: "800px", minHeight: "70vh", background: "#fff" }}
              >
                <tbody>
                  <tr>
                    <td align="center" valign="top">
                      <table width="100%" cellspacing="0" cellpadding="0">
                        <tr>
                          <td class="xs-p-15" style={{ padding: "15px 32px" }}>
                            <table width="100%" cellspacing="0" cellpadding="0">
                              <tr>
                                <td
                                  style={{
                                    fontSize: "20px",
                                    lineHeight: "1.6",
                                    fontWeight: "700",
                                    textAlign: "center",
                                    paddingTop: "34vh",
                                  }}
                                >
                                  Sorry, No records found. Email cannot be sent!
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        )}
      </table>
    </>
  );
};

const mapStateToProps = (state) => ({
  primarySellerData: primarySellerData(state),
});

const mapDispatchToProps = (dispatch) => ({
  sendBuyerEmailReport: (email, data, callback) => dispatch(sendBuyerEmailReport(email, data, callback)),
  changeWeeklyReportListingStatus: (id, callback) => dispatch(changeWeeklyReportListingStatus(id, callback)),
  updateBuyerReportEmailFrequency: (id, data, callback) =>
    dispatch(updateBuyerReportEmailFrequency(id, data, callback)),

  //Buyer ARR Report
  getBuyerArrListings: (id, callback) => dispatch(getBuyerArrListings(id, callback)),
  getBuyerInvoicesandPending: (id, callback) => dispatch(getBuyerInvoicesandPending(id, callback)),
  getBuyerOtherListings: (id, callback) => dispatch(getBuyerOtherListings(id, callback)),
  getBuyerOtherPreferences: (id, callback) => dispatch(getBuyerOtherPreferences(id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(BuyerARR));
