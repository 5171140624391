import React, { useEffect, useState } from "react";
import DeleteModal from "../../../layouts/modals/deleteModalPics";
import DeleteListing from "../../../layouts/modals/deleteModal";
import UploadImages from "../../../layouts/modals/uploadImages";

import ImageLargeModal from "../../../layouts/modals/imageGalleryModal";
import { connect } from "react-redux";
import {
  detailListing,
  getCategoryPreferenaces,
  getListings,
  getSellerMotivation,
  updateTitle,
} from "../../../store/listings";
import ListingTitleComponent from "../../../layouts/modals/listingTitle/listingTitle";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import moment from "moment";
import publicDoc from "../../../include/images/file-public.svg";
import privateDoc from "../../../include/images/file-private.svg";
import icon1 from "../../../include/images/ptm-rb-icon-1.svg";
import refIcon from "../../../include/images/refferal.svg";
import icon21 from "../../../include/images/ptm-rb-icon-2.svg";
import icon3 from "../../../include/images/ptm-rb-icon-3.svg";
import icon4 from "../../../include/images/file-featured-1.svg";
import icon2 from "../../../include/images/icon__2.svg";
import {
  deleteAuction,
  duplicateListing,
  getListingProductDetail,
  getMoreDetails,
  getOtherDescp,
  getProductCount,
  productCondition,
  productDetails,
} from "../../../store/listingProduct";
import {
  getCountries,
  getProductLocation,
  getStates,
  productLocationDetails,
  updateLocationSecurity,
} from "../../../store/productLocation";
import { getStrategicTeam } from "../../../store/strategicTeam";
import Skeleton from "react-loading-skeleton";
import {
  setAIDescription,
  setAILead,
  setCategoryModal,
  setConditionModal,
  setDescription,
  setFeaturedNotes,
  setLocationModal,
  setMoreDetailsModal,
  setPrivateDocModal,
  setPublicDocModal,
  setQtyPricingModal,
  setShowAICarbon,
  setShowAIMfr,
  setStrategicModal,
} from "../../../store/modal";
import { primarySellerData, saveAuctionNote, updateDonationStatus } from "../../../store/primarySeller";
import { getBidLog, getLatestActivity, getPriceInfo } from "../../../store/latestActivity";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import green from "../../../include/images/User_Green.svg";
import red from "../../../include/images/User_Red.svg";
import purple from "../../../include/images/User_Purple.svg";
import yellow from "../../../include/images/User_yellow.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomerChat from "../../../layouts/modals/customerChat/customerChat";
import { getChats, loadAuctionChat, loadChatMassages } from "../../../store/chats";
import ChatMessages from "../../../layouts/modals/customerChat/chatMessages";
import OverallDetailModal from "../../../layouts/modals/productDetails/overallDetailModal";
import { getAuctionType, getManufacturers, getMisc, getOverallDetail, updateOverall } from "../../../store/misc";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import PendingTasks from "../../../layouts/modals/taskModal/pendingTasks";
import copy from "../../../include/images/copy.svg";
import { deleteTask, getPendingTasks, getTasks, updateTaskStatus } from "../../../store/tasks";
import { ei } from "../../../store/ei";
import { capitalizeFirstLetter } from "../../../utils.js/conversion";
import RemoveDonationModal from "../../../layouts/modals/removeDonationModal";
import { getCommentsAndNotes } from "../../../store/commentsAndNotes";
import ConfirmModal from "../../../layouts/modals/confirmModal/confirmModal";
import { getProfile } from "../../../utils.js/localStorageServices";

const TitleBar = ({
  refStart,
  refStop,
  loadingMain,
  auctionId,
  listingDetail,
  getMediaData,
  componentRef2,
  primaryImage,
  setSelectedImage,
  setStartIndex,
  handlePrimaryPicture,
  popoverClick,
  setOptionType,
  optionType,
  loadingTitleUpdate,
  setTitle,
  setOptionTypeId,
  setUploadImageModal,
  newListingImages,
  toggleImageGalleryModal,
  uploadImageModal,
  categoryName,
  onChangeImageUpload,
  primaryPicture,
  handleSaveTitle,
  title,
  optionTypeId,
  handleImageUploader,
  imageUploaded,
  deleteHandler,
  progress,
  loadingUpload,
  setEditedFile,
  loadingDelete,
  imageGalleryModal,
  toggleUploadImageModal,
  imgData,
  selectedImage,
  handleEditImageUploader,
  setImageUploaded,
  setProgress,
  componentRef,
  startIndex,
  data,
  count,
  showMessages,
  toggleMessages,
  toggleChat,
  loadingChats,
  selectedChat,
  showChats,
  setSelectedChat,
  ...props
}) => {
  const profile = getProfile();
  const [showTitle, setShowTitle] = useState(false);
  const [listingTitle, setListingTitle] = useState("");
  const [titleType, setTitleType] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOverallDetail, setShowOverallDetail] = useState(false);
  const [primaryCategory, setPrimaryCategory] = useState([]);
  const [categoryDropdown, setCategoryDropdown] = useState(null);
  const [select, setSelect] = useState();
  const [checkList, setCheckList] = useState([]);
  const [subCat, setSubCat] = useState();
  const [errors, setErrors] = useState({});
  const [showPendingTasks, setShowPendingTasks] = useState(false);
  const togglePendingTaks = () => {
    setShowPendingTasks(!showPendingTasks);
  };
  const [overallData, setOverallData] = useState({
    ai_description: "",
    mfr_description: "",
    co_description: "",
    category_id: 0,
    seller_asking_price: 0,
    quantity: 0,
    unit: "",
    condition: "",
    conditionType: "",
    mfr_id: 0,
    asking_price: 0,
    asking_price_feature: false,
    wholesale_value: 0,
    wholesale_value_feature: false,
    c_name: "",
    c_phone: "",
    c_fax: "",
    c_email: "",
    address_1: "",
    city: "",
    zip: "0",
    state_id: 0,
    country_id: 0,
    map_address: "",
    map_zip: "",
    geo_long: 0,
    geo_lat: 0,
    description: "",
    at_id: 0,
  });
  const [progressCount, setProgressCount] = useState(0);
  const [showConfirmDuplicate, setShowConfirmDuplicate] = useState(false);
  const [deleteListing, setDeleteListing] = useState(false);

  const [donationModal, setDonationModal] = useState(false);
  const toggleDonationModal = () => {
    setDonationModal(!donationModal);
  };
  const handleDonationStatus = () => {
    const payload = { for_donation: false };
    refStart();
    setLoading(true);
    props?.updateDonationStatus(auctionId, payload, (res) => {
      if (res && res.status === 200) {
        props?.getBidLog(auctionId, (res) => {});
        props?.getCommentsAndNotes(auctionId, (res) => {});
        props?.detailListings(auctionId, (res) => {});
        refStop();
        setLoading(false);
        toggleDonationModal();
      } else {
        refStop();
        setLoading(false);
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something Went Wrong"} />);
      }
    });
  };

  const conditionOptions = [
    { name: "New", value: "New" },
    { name: "New Surplus", value: "New Surplus" },
    { name: "Used", value: "Used" },
    { name: "Damaged", value: "Damaged" },
    { name: "Scrap", value: "Scrap" },

    { name: "Factory New", value: "Factory New" },
    { name: "Overhauled", value: "Overhauled" },
    { name: "Serviceable", value: "Serviceable" },
    { name: "As Removed", value: "As Removed" },
    { name: "Repairable", value: "Repairable" },
    { name: "Repaired", value: "Repaired" },
    { name: "Inspected", value: "Inspected" },
    { name: "Core", value: "Core" },

    { name: "Beyond Economical Repair", value: "Beyond Economical Repair" },
    { name: "Rebuilt", value: "Rebuilt" },
    { name: "Modified", value: "Modified" },

    { name: "Tested", value: "Tested" },
    { name: "Unserviceable", value: "Unserviceable" },

    { name: "Disposal", value: "Disposal" },
    { name: "Disposal/Free", value: "Disposal/Free" },

    { name: "Others", value: "Others" },
  ];

  const manufactureOptions =
    props.getMisc &&
    props.getMisc.manufacturers &&
    props.getMisc.manufacturers.data &&
    props.getMisc.manufacturers.data.length > 0 &&
    props.getMisc.manufacturers.data.map((item) => ({
      name: `${item.Description} ${item.ra_users ? `(${item.ra_users})` : ""}`,
      value: item.ID,
    }));

  const typeCondition = props?.getMisc?.overallDetail?.data?.conditionType;

  const validate = () => {
    const error = { ...errors };
    if (!primaryCategory?.firstLevel?.name && !primaryCategory?.firstLevel?.active) {
      error.firstLevel = "Category is Required";
    } else {
      delete error.firstLevel;
    }

    if (
      primaryCategory?.firstLevel?.name &&
      !primaryCategory?.secondLevel?.name &&
      !primaryCategory?.secondLevel?.active
    ) {
      error.secondLevel = "Category Level 2 is Required";
    } else {
      delete error.secondLevel;
    }

    if (
      (primaryCategory?.secondLevel?.name == "Aircraft Parts" ||
        primaryCategory?.secondLevel?.name == "Complete Aircrafts") &&
      !primaryCategory?.thirdLevel?.active
    ) {
      error.thirdLevel = "Category Level 3 is Required";
    } else {
      delete error.thirdLevel;
    }
    const isEmpty = Object.values(error).every((x) => x === null || x === "");
    if (!isEmpty) {
      setErrors(error);
      return false;
    } else {
      setErrors(error);
      return true;
    }
  };

  const flattenCategories = (categories) => {
    let flatMap = [];

    const flatten = (category) => {
      flatMap.push(category);

      if (category.subCategories && category.subCategories.length > 0) {
        category.subCategories.forEach((subCategory) => {
          flatten(subCategory);
        });
      }
    };

    categories?.forEach((category) => {
      flatten(category);
    });

    return flatMap;
  };

  // const categories = props?.getMisc?.categories?.data;
  const categories = props.getListings.categoryDrpDown.data?.categories;
  const auctionTypeOptions = props?.getMisc?.auctionType?.data;
  const countryOptions = props?.productLocationDetails?.countries?.data;
  const stateOptions = props?.productLocationDetails?.states?.data;
  const categoryOptions = flattenCategories(categories);
  const categoryDropDownData =
    (props.getListings && props.getListings.categoryDrpDown && props.getListings.categoryDrpDown.data?.categories) ||
    [];
  const categoryData = (props.getListings && props.getListings.category && props.getListings.category.data) || {};

  const toggleOverall = () => {
    setShowOverallDetail(!showOverallDetail);
  };

  const fetchOverallDetail = () => {
    refStart();
    props?.getOverallDetail(auctionId, (res) => {
      if (res.status === 200) {
        const val = res?.data?.data;
        const data = { ...overallData };
        data.ai_description = val?.ai_description ? val?.ai_description : "";
        data.mfr_description = val?.mfr_description ? val?.mfr_description : "";
        data.co_description = val?.co_description ? val?.co_description : "";
        data.category_id = val?.category_id ? val?.category_id : 0;
        data.seller_asking_price = val?.seller_reserve ? val?.seller_reserve : 0;
        data.quantity = val?.quantity ? val?.quantity : 0;
        data.unit = val?.unit ? val?.unit : "";
        data.condition = data?.condition ? val?.condition : "";
        data.conditionType = val?.conditionType ? val?.conditionType : "";
        data.mfr_id = val?.mfr ? val?.mfr : 0;
        data.asking_price = val?.p1001 ? val?.p1001 : 0;
        data.description = val?.details ? val?.details : "";
        data.asking_price_feature = val?.asking_price_feature ? val?.asking_price_feature : false;
        data.wholesale_value = val?.p1003 ? val?.p1003 : 0;
        data.wholesale_value_feature = val?.wholesale_value_feature ? val?.wholesale_value_feature : false;
        data.loc_sec = val?.loc_sec;
        data.c_name = val?.c_name ? val?.c_name : "";
        data.c_phone = val?.c_phone ? val?.c_phone : "";
        data.c_fax = val?.c_fax ? val?.c_fax : "";
        data.c_email = val?.c_email ? val?.c_email : "";
        data.address_1 = val?.address_1 ? val?.address_1 : "";
        data.city = val?.city ? val?.city : "";
        data.zip = val?.zip ? val?.zip : "0";
        data.state_id = val?.state_id ? val?.state_id : 0;
        data.country_id = val?.country_id ? val?.country_id : 0;
        data.map_address = val?.map_address ? val?.map_address : "";
        data.map_zip = val?.map_zip ? val?.map_zip : "";
        data.geo_long = val?.geo_long ? val?.geo_long : 0;
        data.geo_lat = val?.geo_lat ? val?.geo_lat : 0;
        data.end_date = val?.end_date ? val?.end_date : "";

        data.at_id = val.at_id ? val.at_id : 0;
        setOverallData(data);
        refStop();
        toggleOverall();
      } else {
        refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const dateTime = (w) => {
    const d = new Date();
    let diff = d.getTimezoneOffset();

    const date = moment(w).add({ minute: diff }).format("L");
    const hour = moment(w).add({ minute: diff }).format("H");
    const min = moment(w).add({ minute: diff }).format("m");
    const type = moment(w).add({ minute: diff }).format("A");

    const data = {
      date,
      hour,
      min,
      type,
    };

    return data;
  };

  //Handle Update Overall Data
  const handleUpdateOverall = () => {
    if (validate()) {
      setLoading(true);
      refStart();
      var aEndDate;
      if (overallData?.end_date) {
        aEndDate = dateTime(overallData?.end_date);
      }

      const payload = { ...overallData };
      payload.date_scheduler = "";
      payload.override_scheduler = false;

      delete payload.end_date;

      delete payload.loc_sec;

      payload.end_date_1 = overallData?.end_date && aEndDate ? aEndDate?.date : "";
      payload.end_date_2 = overallData?.end_date && aEndDate ? aEndDate.hour : "";
      payload.end_date_3 = overallData?.end_date && aEndDate ? aEndDate.min : "";
      payload.end_date_4 = overallData?.end_date && aEndDate ? aEndDate.type : "";

      props?.updateOverall(auctionId, payload, (res) => {
        if (res.status === 200) {
          refStop();
          setLoading(false);
          toggleOverall();
          props?.getProductLocation(auctionId, (res) => {});
          props?.productDetails(auctionId, (res) => {});
          props.getPriceInfo(auctionId, (res) => {});
          props.getCategoryPreferences(auctionId, (res) => {});
          props?.productCondition(auctionId, (res) => {});
          props.getMoreDetails(auctionId, (res) => {});
          props.getSellerMotivation(auctionId, (res) => {});
          props.getOtherDescp(auctionId, (res) => {});
          toast(<AlertSuccess message={"Information Saved"} />);
        } else {
          refStop();
          setLoading(false);
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  };

  const history = useHistory();
  const toggleShowTitle = () => {
    setShowTitle(!showTitle);
  };

  useEffect(() => {
    if (showTitle && titleType == "title") {
      setListingTitle(props.getListings?.detail?.detail?.title);
    } else if (showTitle && titleType == "notes_to_seller") {
      setListingTitle(props.getListings?.detail?.detail?.note_to_seller);
    }
  }, [showTitle]);

  const handleChangeTitle = () => {
    if (titleType == "title") {
      setLoading(true);
      const id = auctionId;
      const data = {
        fld: "title",
        val: listingTitle,
      };
      refStart();
      props?.updateTitle(id, data, (res) => {
        if (res && res.status == 200) {
          props?.detailListings(id, (res) => {
            if (res && res.status == 200) {
              setLoading(false);
              toggleShowTitle();
              setTitleType("");
              refStop();
            }
          });
        } else {
          setLoading(false);
          refStop();
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        }
      });
    } else if (titleType == "notes_to_seller") {
      setLoading(true);
      const id = auctionId;
      const data = {
        note: listingTitle ? listingTitle : "",
      };
      refStart();
      props?.saveAuctionNote(id, data, (res) => {
        if (res && res.status == 200) {
          props?.detailListings(id, (res) => {
            if (res && res.status == 200) {
              setLoading(false);
              refStop();
              toggleShowTitle();
              setTitleType("");
            }
          });
        } else {
          setLoading(false);
          refStop();
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        }
      });
    }
  };

  const chatList = (props.getChatList && props.getChatList.auctionChats) || [];
  const selectedMessages = (props.getChatList && props.getChatList.chatMassages) || [];

  const details = props.getListings && props.getListings.detail;
  const { detail } = details;
  const imgValue = props.getListings && props.getListings.detail && props.getListings.detail.detail;

  const d = new Date();
  let diff = d.getTimezoneOffset();

  const region = props?.productLocationDetails?.productLocation?.data?.location1;
  const startegicTeam = props?.getStrategicTeam?.strategicTeam?.detail;
  const tagDetails = props?.getListings?.sellerMotivation?.data;
  const conditionType = props?.getListingProductDetail?.condition?.detail?.conditionType;
  // const manufacturer = props?.getListingProductDetail?.mfrAndModel?.data?.mfr?.Description;
  const manufacturer = props?.getListings?.category?.data?.m_description;
  const model = props?.getListingProductDetail?.mfrAndModel?.data?.model?.description;
  const primarySellerData = props?.primarySellerData?.primarySeller?.data;
  const media = getMediaData;
  const category = props?.getListings?.category?.data?.cat_des;
  const moreDetails = props?.getListingProductDetail?.moreDetails?.data;
  const priceInfo = props.getLatestActivity?.priceInfo || {};
  const description = props.getListingProductDetail && props.getListingProductDetail.description;
  const AIDescription =
    props.getListingProductDetail &&
    props.getListingProductDetail.otherDescription &&
    props.getListingProductDetail.otherDescription.data &&
    props.getListingProductDetail.otherDescription.data.ai_description;
  const AIMfr =
    props.getListingProductDetail &&
    props.getListingProductDetail.otherDescription &&
    props.getListingProductDetail.otherDescription.data &&
    props.getListingProductDetail.otherDescription.data.mfr_description;
  const AICarbornFootPrints =
    props.getListingProductDetail &&
    props.getListingProductDetail.otherDescription &&
    props.getListingProductDetail.otherDescription.data &&
    props.getListingProductDetail.otherDescription.data.co_description;
  const AILeads =
    props.getListingProductDetail &&
    props.getListingProductDetail.otherDescription &&
    props.getListingProductDetail.otherDescription.data &&
    props.getListingProductDetail.otherDescription.data.ai_lead;
  const locationDetails =
    props &&
    props.productLocationDetails &&
    props.productLocationDetails.productLocation &&
    props.productLocationDetails.productLocation.data;
  const latestBids = props.getLatestActivity?.latestBids || {};

  const condition =
    props.getListingProductDetail &&
    props.getListingProductDetail.condition &&
    props.getListingProductDetail.condition.detail;

  useEffect(() => {
    setProgressCount(0);
    if (media?.listingImages?.pics?.length > 0) {
      setProgressCount((pre) => pre + 1);
    }
    if (media?.privateDocuments?.docs?.length > 0) {
      setProgressCount((pre) => pre + 1);
    }
    if (
      locationDetails?.location1 &&
      locationDetails?.location2 &&
      locationDetails?.location3 &&
      locationDetails?.location4
    ) {
      setProgressCount((pre) => pre + 1);
    }
    if (moreDetails?.at_id > 0) {
      setProgressCount((pre) => pre + 1);
    }
    if (detail && priceInfo?.auction_details?.quantity && detail?.unit) {
      setProgressCount((pre) => pre + 1);
    }
    if (category) {
      setProgressCount((pre) => pre + 1);
    }
    if (manufacturer) {
      setProgressCount((pre) => pre + 1);
    }
    if (conditionType) {
      setProgressCount((pre) => pre + 1);
    }
    if (AIDescription?.split("")?.length > 0) {
      setProgressCount((pre) => pre + 1);
    }
    if (AIMfr?.split("")?.length > 0) {
      setProgressCount((pre) => pre + 1);
    }
    if (AICarbornFootPrints?.split("")?.length > 0) {
      setProgressCount((pre) => pre + 1);
    }
    if (priceInfo?.auction_prices?.find((item) => item.description === "Asking Price")?.p_value > 0) {
      setProgressCount((pre) => pre + 1);
    }
    if (priceInfo?.auction_details?.seller_reserve > 0) {
      setProgressCount((pre) => pre + 1);
    }
    if (priceInfo?.auction_prices?.find((item) => item.description === "Original Wholesale Value")?.p_value > 0) {
      setProgressCount((pre) => pre + 1);
    }
    if (startegicTeam?.d?.comm_member_1) {
      setProgressCount((pre) => pre + 1);
    }
  }, [
    media,
    locationDetails,
    moreDetails,
    detail,
    priceInfo,
    category,
    manufacturer,
    conditionType,
    AIDescription,
    AIMfr,
    AICarbornFootPrints,
    startegicTeam,
  ]);

  useEffect(() => {
    if (showOverallDetail && categoryDropDownData) {
      const data =
        categoryDropDownData &&
        categoryDropDownData.length > 0 &&
        categoryDropDownData.map((item) => ({
          name: item.description,
          value: item.category_id,
        }));
      setCategoryDropdown(data);

      const sub =
        categoryDropDownData &&
        categoryDropDownData.length > 0 &&
        categoryDropDownData.map((item) => ({
          subCategories: item.subCategories,
          value: item.category_id,
        }));
      setSubCat(sub);
    }
  }, [showOverallDetail, categoryDropDownData]);

  useEffect(() => {
    if (props.getListings && props.getListings.category && props.getListings.category.data) {
      const a = {};
      const treeLength = props.getListings.category.data?.categoryTree?.length;
      const treeData = props.getListings.category.data?.categoryTree;
      if (treeLength == 1) {
        setSelect({ name: treeData[0]?.description, value: treeData[0]?.category_id });
        setPrimaryCategory({ firstLevel: { active: true } });
      } else if (treeLength == 2) {
        const item = categoryDropDownData?.filter((oi) => oi?.category_id == treeData[1]?.category_id)[0]
          ?.subCategories;
        setSelect({ name: treeData[0]?.description, value: treeData[0]?.category_id });
        setPrimaryCategory({
          firstLevel: { name: treeData[1]?.description, value: treeData[1]?.category_id, subCategories: item },
          secondLevel: { active: true },
        });
      } else {
        const item = categoryDropDownData?.filter((oi) => oi?.category_id == treeData[2]?.category_id)[0]
          ?.subCategories;
        const item1 = item?.filter((oi) => oi?.category_id == treeData[1]?.category_id)[0]?.subCategories;
        setSelect({ name: treeData[0]?.description, value: treeData[0]?.category_id });
        setPrimaryCategory({
          firstLevel: { name: treeData[2]?.description, value: treeData[2]?.category_id, subCategories: item },
          secondLevel: { name: treeData[1]?.description, value: treeData[1]?.category_id, subCategories: item1 },
          thirdLevel: { active: true },
        });
      }
    }

    const a =
      categoryData && categoryData.category && categoryData.category.length > 0
        ? categoryData.category.map((item) => item.category_id)
        : [];
    setCheckList(a);
  }, [showOverallDetail]);
  const aviationType = props?.getListings?.detail?.detail?.aviationType;

  //Ei Related data;
  const speciesData = props?.ei?.auctionSpecies?.data;
  const productLocation = props?.ei?.auctionSpecies?.data?.productLocation;
  const savedSpecies = props?.ei?.auctionSpecies?.savedSpecies;
  const regions = props?.ei?.region?.data ? props?.ei?.region?.data : [];
  const mainRegions = speciesData?.regions?.find((val) => val?._id === savedSpecies?.region);
  const locations = productLocation?.location2?.split(", ");
  const mainRegion = regions?.find(
    (val) =>
      val?.countryName ===
      (locations && locations.length > 1 ? locations[1] : locations && locations.length > 0 ? locations[0] : undefined),
  );
  const subRegion = regions?.find((val) => val?.state === (locations && locations.length > 0 ? locations[0] : ""));

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        return toast(<AlertSuccess message={"Copied to clipboard!"} />);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleDuplicate = () => {
    refStart();
    setLoading(true);
    const payload = {};
    props?.duplicateListing(auctionId, payload, (res) => {
      if (res && res.status === 200) {
        setShowConfirmDuplicate(false);
        setLoading(false);
        refStop();
        const id = res?.data?.data?.new_auction_id;
        window.open(`/listing-detail?id=${id}`);
      } else {
        refStop();
        setLoading(false);
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };

  const deleteListingHandler = () => {
    refStart();
    setLoading(true);
    const payload = {};
    props?.deleteAuction(auctionId, payload, (res) => {
      if (res && res.status === 200) {
        setLoading(false);
        refStop();
        window.location.href = "/";
      } else {
        refStop();
        setLoading(false);
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };

  return (
    <>
      <div class="page-row d-flex flex-nowrap">
        <div class="page-left-column d-flex flex-column justify-content-between">
          <div
            class="page-w-row overflow-hidden d-flex flex-wrap cursor-pointer"
            onClick={() => toggleUploadImageModal()}
          >
            <div class="page-w-left-column position-relative">
              <div class="page-w-image">
                <img
                  // style={{ objectFit: "contain", height: "40px", width: "40px" }}
                  src={
                    imgValue?.primary_picture
                      ? `${imgValue.primary_picture}_mp${
                          imgValue.jpeg > 0 ? ".jpeg" : imgValue.jpg > 0 ? ".jpg" : imgValue.png > 0 ? ".png" : ""
                        }`
                      : "https://media.salvex.com/auction/p/1830012/183001141_641791_mp.jpg"
                  }
                  alt=""
                />
              </div>
            </div>
            <div class={`${media?.listingImages?.pics?.length ? "" : "rsp"} page-w-right-column`}>
              {media?.listingImages?.pics?.length ? media?.listingImages?.pics?.length : "0"}
            </div>
          </div>
          <div
            class="page-w-row overflow-hidden d-flex flex-wrap cursor-pointer"
            onClick={() => props?.setPublicDocModal(true)}
          >
            <div class="page-w-left-column position-relative">
              <div class="page-w-icon w-100 h-100 d-flex align-items-center justify-content-center">
                <img src={publicDoc} alt="" />
              </div>
            </div>
            <div class={`page-w-right-column ${media?.listingDocuments?.docs?.length > 0 ? "" : "ptm-red-bg"} `}>
              {media?.listingDocuments?.docs?.length ? media?.listingDocuments?.docs?.length : "0"}
            </div>
          </div>
          <div
            class="page-w-row overflow-hidden d-flex flex-wrap cursor-pointer"
            onClick={() => props?.setPrivateDocModal(true)}
          >
            <div class="page-w-left-column position-relative">
              <div class="page-w-icon w-100 h-100 d-flex align-items-center justify-content-center">
                <img src={privateDoc} alt="" />
              </div>
            </div>
            <div className={`page-w-right-column ${media?.privateDocuments?.docs?.length > 0 ? "" : "ptm-red-bg"} `}>
              {media?.privateDocuments?.docs?.length ? media?.privateDocuments?.docs?.length : "0"}
            </div>
          </div>
        </div>
        <div class="page-middle-column flex-grow-1">
          <div class="pc-content-box">
            <div className="d-flex align-items-center flex-wrap">
              <div class="pc-heading cursor-pointer mb-10 flex-wrap">
                <span
                  onClick={() => {
                    setTitleType("title");
                    toggleShowTitle();
                  }}
                >
                  {detail && detail.auction_id ? detail.auction_id : ""} -{" "}
                  {detail && detail.title
                    ? detail.title?.length > 70
                      ? detail.title?.substring(0, 70) + "..."
                      : detail.title
                    : ""}
                </span>
                {(detail?.auction_id || detail?.title) && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip style={{ position: "fixed" }}>
                        <span>Copy</span>
                      </Tooltip>
                    }
                  >
                    <div
                      className="cursor-pointer d-inline ms-1"
                      onClick={() => copyToClipboard(`${detail?.auction_id} - ${detail?.title}`)}
                    >
                      <img src={copy} alt="" />
                    </div>
                  </OverlayTrigger>
                )}

                <div
                  class="page-cat-item cursor-pointer d-inline "
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    setTitleType("notes_to_seller");
                    toggleShowTitle();
                  }}
                >
                  <div class={`page-cat-box  d-inline bsp`}>Seller Notes</div>
                </div>
                {detail?.status_id != 2 && detail?.status_id != 1 ? (
                  <div
                    class="page-cat-item cursor-pointer d-inline "
                    style={{ marginLeft: "20px" }}
                    onClick={() => {
                      if (process.env.REACT_APP_MODE == "development") {
                        window.open(`https://www.newdev.salvex.com/listings/listing_detail.cfm/aucid/${auctionId}`);
                      } else {
                        window.open(`https://www.salvex.com/listings/listing_detail.cfm/aucid/${auctionId}`);
                      }
                    }}
                  >
                    <div class={`page-cat-box  d-inline ysp`}>Public View</div>
                  </div>
                ) : (
                  ""
                )}
                <a
                  href="javascript:void(0)"
                  class={`btn btn-gray btn-secondary`}
                  style={{ marginLeft: "20px", minWidth: "fit-content", padding: "5px" }}
                  onClick={() => setShowConfirmDuplicate(true)}
                >
                  Duplicate
                </a>
                {profile?.accessLevels?.system_admin ? (
                  <a
                    href="javascript:void(0)"
                    class={`btn btn-delete btn-secondary`}
                    style={{ marginLeft: "20px", minWidth: "fit-content", padding: "5px", border: "1px solid red" }}
                    onClick={() => setDeleteListing(true)}
                  >
                    Delete This Listing
                  </a>
                ) : (
                  ""
                )}
                {!loadingMain && detail?.for_donation ? (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip style={{ position: "fixed" }}>
                        <div>
                          <span>
                            Region:{" "}
                            {mainRegions?.countryName
                              ? mainRegions?.countryName
                              : mainRegion?.countryName
                              ? mainRegion?.countryName
                              : "N/A"}
                          </span>
                        </div>
                        {(mainRegions?.countryName === "United States" ||
                          mainRegion?.countryName === "United States") && (
                          <div>
                            <span>
                              Sub Region:{" "}
                              {mainRegions?.state ? mainRegions?.state : subRegion?.state ? subRegion?.state : "N/A"}
                            </span>
                          </div>
                        )}
                        <div>
                          <span>
                            Category:{" "}
                            {speciesData?.speciesCategoryName
                              ? speciesData?.speciesCategoryName?.charAt(0)?.toUpperCase() +
                                speciesData?.speciesCategoryName?.slice(1)
                              : "N/A"}
                          </span>
                        </div>
                        <div>
                          <span>Animal: {speciesData?.name ? capitalizeFirstLetter(speciesData?.name) : "N/A"}</span>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="page-cat-item cursor-pointer d-inline " style={{ marginLeft: "20px" }}>
                      <div class={`page-cat-box  d-inline rsp`} onClick={toggleDonationModal}>
                        Donated
                      </div>
                    </div>
                  </OverlayTrigger>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div class="page-cat-list d-flex flex-wrap">
              <div
                class="page-cat-item cursor-pointer"
                style={{ width: "fit-content" }}
                onClick={() => props?.setLocation(true)}
              >
                <div
                  class={`page-cat-box ${
                    moreDetails?.loc_sec === 0
                      ? locationDetails?.location1
                        ? "gsp"
                        : "rsp"
                      : moreDetails?.loc_sec === 1
                      ? locationDetails?.location2
                        ? "gsp"
                        : "rsp"
                      : moreDetails?.loc_sec === 2
                      ? locationDetails?.location3
                        ? "gsp"
                        : "rsp"
                      : moreDetails?.loc_sec === 3
                      ? locationDetails?.location4
                        ? "gsp"
                        : "rsp"
                      : "rsp"
                  }`}
                >
                  Location: {locationDetails?.location3 ? locationDetails?.location3?.replaceAll("<br>", ", ") : "TBD"}
                  {/* {moreDetails?.loc_sec === 0
                    ? locationDetails?.location1
                      ? locationDetails?.location1
                      : "TBD"
                    : moreDetails?.loc_sec === 1
                    ? locationDetails?.location2
                      ? locationDetails?.location2
                      : "TBD"
                    : moreDetails?.loc_sec === 2
                    ? locationDetails?.location3
                      ? locationDetails?.location3
                      : "TBD"
                    : moreDetails?.loc_sec === 3
                    ? locationDetails?.location4
                      ? locationDetails?.location4?.replaceAll("<br>", ", ")
                      : "TBD"
                    : "TBD"} */}
                </div>
              </div>
              {tagDetails &&
                tagDetails.more_details &&
                tagDetails.more_details.length > 0 &&
                tagDetails.more_details.map((item, i) =>
                  item ? (
                    <div class="page-cat-item cursor-pointer" onClick={() => props?.setMoreDetails(true)}>
                      <div class={`page-cat-box ${i === 0 ? "bg-purple title-status-text " : ""}`}>
                        {moment(item).isValid()
                          ? moment(item).add({ minute: diff }).format("MM/DD/YYYY h:mm a")
                          : item
                          ? i === 0
                            ? "Status: " + item
                            : i === 2
                            ? "Sale Type: " + item
                            : item
                          : ""}
                      </div>
                    </div>
                  ) : i === 0 ? (
                    // Remove this i===0 condition from here if need.
                    <div
                      class="page-cat-item cursor-pointer bg-purple title-status-text "
                      onClick={() => props?.setMoreDetails(true)}
                    >
                      <div class="page-cat-box">Status: Pending</div>
                    </div>
                  ) : (
                    ""
                  ),
                )}
              {latestBids?.qtyInfo?.name01 ? (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip style={{ position: "fixed" }}>
                      <span>
                        <small>
                          Updated By {latestBids?.qtyInfo?.name01} at {latestBids?.qtyInfo?.last_qty_confirmation_date}
                        </small>
                      </span>
                    </Tooltip>
                  }
                >
                  <div class="page-cat-item cursor-pointer" onClick={() => props?.setPricingModal(true)}>
                    <div
                      class={`page-cat-box ${priceInfo?.auction_details?.quantity ? "gsp" : "rsp"}`}
                      style={{
                        padding:
                          priceInfo?.auction_details?.quantity && props?.getListings?.detail?.detail?.qty_confirmed
                            ? "2.5px 4px"
                            : "",
                      }}
                    >
                      {priceInfo?.auction_details?.quantity
                        ? `Quantity : ${Math.round(priceInfo?.auction_details?.quantity)} ${
                            priceInfo?.auction_details?.unit ? priceInfo?.auction_details?.unit : "Units"
                          }`
                        : "Quantity : TBD"}{" "}
                      {priceInfo?.auction_details?.quantity && props?.getListings?.detail?.detail?.qty_confirmed ? (
                        <img src={icon2} style={{ width: "16px" }} alt="" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </OverlayTrigger>
              ) : (
                <div class="page-cat-item cursor-pointer" onClick={() => props?.setPricingModal(true)}>
                  <div class={`page-cat-box ${priceInfo?.auction_details?.quantity ? "gsp" : "rsp"}`}>
                    {detail && priceInfo?.auction_details?.quantity
                      ? `Quantity : ${Math.round(priceInfo?.auction_details?.quantity)} ${
                          detail?.unit ? detail?.unit : "Units"
                        }`
                      : "Quantity : TBD"}{" "}
                    {priceInfo?.auction_details?.quantity && props?.getListings?.detail?.detail?.qty_confirmed ? (
                      <img src={icon2} alt="" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
              {/* <div class="page-cat-item cursor-pointer" onClick={() => props?.scrollToView("primarySeller")}>
                  <div class="page-cat-box">
                    {detail && priceInfo?.auction_details?.quantity && detail?.unit
                      ? `Seller :  ${primarySellerData?.user?.name} `
                      : "Seller : TBD"}
                    {primarySellerData?.verification?.verification_status == "red" ? (
                      <img src={red} alt="" />
                    ) : primarySellerData?.verification?.verification_status == "yellow" ? (
                      <img src={yellow} alt="" />
                    ) : primarySellerData?.verification?.verification_status == "purple" ? (
                      <img src={purple} alt="" />
                    ) : primarySellerData?.verification?.verification_status == "green" ? (
                      <img src={green} alt="" />
                    ) : (
                      ""
                    )}
                  </div>
                </div> */}
            </div>
            <div class="page-cat-list d-flex flex-wrap">
              {
                <div class="page-cat-item cursor-pointer" onClick={() => props?.setMoreDetails(true)}>
                  <div class={`page-cat-box d-flex align-items-center ${moreDetails?.bigdeal == true ? "gsp" : ""}`}>
                    Big Deal : {moreDetails?.bigdeal == false ? "No" : moreDetails?.bigdeal == true ? "Yes" : "TBD"}
                  </div>
                </div>
              }
              {category && (
                <div class="page-cat-item cursor-pointer" onClick={() => props?.setConditionModal(true)}>
                  <div class={`page-cat-box d-flex align-items-center ${category ? "gsp" : "rsp"}`}>
                    {moment(category).isValid()
                      ? moment(props.categoryName).add({ minute: diff }).format("MM/DD/YYYY h:mm a")
                      : "Category: " + category}
                  </div>
                </div>
              )}
              {!loadingMain && props?.isAviationCategory && (
                <div class="page-cat-item cursor-pointer" onClick={() => props?.setConditionModal(true)}>
                  <div class={`page-cat-box d-flex align-items-center ${props?.aviationModel ? "gsp" : "rsp"}`}>
                    {props?.aviationModel ? "Aviation Model : " + props?.aviationModel : "Aviation Type : TBD"}
                  </div>
                </div>
              )}
              <div class="page-cat-item cursor-pointer" onClick={() => props?.setConditionModal(true)}>
                <div class={`page-cat-box ${manufacturer ? "gsp" : "rsp"}`}>
                  {manufacturer ? `Mfr : ${manufacturer}` : "Mfr : TBD"}
                </div>
              </div>

              <div class="page-cat-item cursor-pointer" onClick={() => props?.setConditionModal(true)}>
                <div class={`page-cat-box ${conditionType ? "gsp" : "rsp"}`}>
                  {conditionType ? `Condition Type : ${conditionType}` : " Condition Type : TBD"}
                </div>
              </div>
              <div class="page-cat-item cursor-pointer" onClick={() => props?.setLocation(true)}>
                <div class={`page-cat-box ${locationDetails?.loading_costs ? "gsp" : "rsp"}`}>
                  {locationDetails?.loading_costs
                    ? `Loading Costs : ${
                        locationDetails?.loading_costs?.length > 20
                          ? locationDetails?.loading_costs?.substring(0, 20) + "..."
                          : locationDetails?.loading_costs
                      }`
                    : " Loading Costs : TBD"}
                </div>
              </div>
              <div class="page-cat-item cursor-pointer" onClick={() => props?.setDescriptionModal(true)}>
                <div class={`page-cat-box ${description?.split("")?.length > 0 ? "gsp" : "rsp"}`}>Description</div>
              </div>
            </div>
            <div class="page-cat-list d-flex flex-wrap">
              <div class="page-cat-item">
                <div class="page-cat-box">
                  Date Created :{" "}
                  {moreDetails?.date_created ? moment(moreDetails?.date_created).format("DD MMM YYYY") : "TBD"}
                </div>
              </div>
              <div class="page-cat-item cursor-pointer" onClick={() => props?.setMoreDetails(true)}>
                <div class={`page-cat-box ${moreDetails?.end_date ? "gsp" : "rsp"}`}>
                  Auction End Date :{" "}
                  {moreDetails?.end_date ? moment(moreDetails?.end_date).format("DD MMM YYYY") : "TBD"}
                </div>
              </div>
              <div class="page-cat-item cursor-pointer" onClick={() => props?.setMoreDetails(true)}>
                <div class={`page-cat-box ${moreDetails?.due_date ? "gsp" : "rsp"}`}>
                  Bid Date to Seller :{" "}
                  {moreDetails?.due_date ? moment(moreDetails?.due_date).format("DD MMM YYYY") : "TBD"}
                </div>
              </div>
              <div class="page-cat-item cursor-pointer" onClick={() => props?.setAiDescp(true)}>
                <div class={`page-cat-box ${AIDescription?.split("")?.length > 0 ? "gsp" : "rsp"}`}>AI Description</div>
              </div>
              <div class="page-cat-item cursor-pointer" onClick={() => props?.setAiMfr(true)}>
                <div class={`page-cat-box ${AIMfr?.split("")?.length > 0 ? "gsp" : "rsp"}`}>AI Manufacturer</div>
              </div>
              <div class="page-cat-item cursor-pointer" onClick={() => props?.setAiCarbon(true)}>
                <div class={`page-cat-box ${AICarbornFootPrints?.split("")?.length > 0 ? "gsp" : "rsp"}`}>
                  AI Carbon Footprint
                </div>
              </div>
              <div class="page-cat-item cursor-pointer" onClick={() => props?.setAiLead(true)}>
                <div class={`page-cat-box ${AILeads?.split("")?.length > 0 ? "gsp" : "rsp"}`}>AI Leads</div>
              </div>
            </div>
          </div>
        </div>
        <div class="page-right-column d-flex">
          <div class="page-progress align-self-center">
            <div class="new-countdown-timer">
              <svg
                class="circle-icon"
                width="91"
                height="91"
                viewBox="0 0 91 91"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="5.875"
                  y="5.65625"
                  width="80.125"
                  height="80.125"
                  rx="40.0625"
                  stroke={
                    (progressCount * 100) / 15 >= 80
                      ? "#47AD1D"
                      : (progressCount * 100) / 15 >= 50 && (progressCount * 100) / 15 < 80
                      ? "#f2b61c"
                      : (progressCount * 100) / 15 < 50
                      ? "red"
                      : ""
                  }
                  stroke-width="11"
                  stroke-dasharray="251"
                  stroke-dashoffset={2.51 * (100 - (progressCount * 100) / 15)}

                  // stroke-dasharray="250" - Initial Values
                  // stroke-dashoffset="60" - Inital Values
                />
                <text
                  x={(progressCount * 100) / 15 == "100" ? "13" : (progressCount * 100) / 15 > 0 ? "18" : "22"}
                  y="56"
                >
                  {progressCount ? Math.round((progressCount * 100) / 15) + "%" : "0%"}
                </text>
              </svg>
            </div>
          </div>
          <div class="page-icon-wrap">
            <ul class="page-button-list h-100 d-flex flex-wrap" style={{ minWidth: "152px" }}>
              <li class="page-button-item w-50 h-50">
                <div class="page-button-box w-100 h-100 d-flex align-items-center justify-content-center cursor-pointer">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip style={{ position: "fixed" }}>
                        <span>Broker Referrals </span>
                      </Tooltip>
                    }
                  >
                    <div class="button-holder position-relative" onClick={() => props?.scrollToView("brokerRef")}>
                      <div class=" d-flex align-items-center justify-content-center">
                        <img src={refIcon} alt="" />
                      </div>

                      {/* <div class="button-icon d-flex align-items-center justify-content-center">
                      <img src={icon1} alt="" />
                    </div> */}
                      {count?.brokerActivity > 0 && (
                        <div
                          class="button-notify-count d-flex align-items-center justify-content-center"
                          style={{ padding: "9px" }}
                        >
                          {count?.brokerActivity}
                        </div>
                      )}
                    </div>
                  </OverlayTrigger>
                </div>
              </li>
              <li class="page-button-item w-50 h-50">
                <div
                  class="page-button-box w-100 h-100 d-flex align-items-center justify-content-center cursor-pointer"
                  onClick={toggleChat}
                >
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip style={{ position: "fixed" }}>
                        <span>Customer Chats</span>
                      </Tooltip>
                    }
                  >
                    <div class="button-holder position-relative">
                      <div class=" d-flex align-items-center justify-content-center">
                        <img src={icon21} alt="" />
                      </div>
                      {count?.unreadQuestionCount > 0 && (
                        <div
                          class="button-notify-count d-flex align-items-center justify-content-center"
                          style={{ padding: "9px" }}
                        >
                          {count?.unreadQuestionCount}
                        </div>
                      )}
                    </div>
                  </OverlayTrigger>
                </div>
              </li>
              <li class="page-button-item w-50 h-50">
                <div class="page-button-box w-100 h-100 d-flex align-items-center justify-content-center cursor-pointer">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip style={{ position: "fixed" }}>
                        <span>Tasks</span>
                      </Tooltip>
                    }
                  >
                    <div class="button-holder position-relative" onClick={() => props?.scrollToView("completedTasks")}>
                      <div class=" d-flex align-items-center justify-content-center">
                        <img src={icon1} alt="" />
                      </div>
                      {count?.pendingTask > 0 && (
                        <div
                          class="button-notify-count d-flex align-items-center justify-content-center"
                          style={{ padding: "9px" }}
                        >
                          {count?.pendingTask}
                        </div>
                      )}
                    </div>
                  </OverlayTrigger>
                </div>
              </li>
              <li class="page-button-item w-50 h-50">
                <div class="page-button-box w-100 h-100 d-flex align-items-center justify-content-center cursor-pointer">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip style={{ position: "fixed" }}>
                        <span>Featured Notes</span>
                      </Tooltip>
                    }
                  >
                    <div class="button-holder position-relative" onClick={() => props?.setShowFeatured(true)}>
                      <div class=" d-flex align-items-center justify-content-center">
                        <img src={icon4} alt="" />
                      </div>
                      {count?.featuredCommentAndNote > 0 && (
                        <div
                          class="button-notify-count d-flex align-items-center justify-content-center"
                          style={{ padding: "9px" }}
                        >
                          {count?.featuredCommentAndNote}
                        </div>
                      )}
                    </div>
                  </OverlayTrigger>
                </div>
              </li>
            </ul>
          </div>
          <div class="page-intro-wrap d-flex">
            <div
              class="page-intro-item d-flex align-items-center justify-content-center ptm-red-bg cursor-pointer"
              onClick={fetchOverallDetail}
            >
              <p>Missing Info</p>
            </div>
            <a
              href={
                process.env.REACT_APP_MODE == "production"
                  ? `https://admin.salvex.com/admin/_listings/views/_full.cfm?aucID=${auctionId}`
                  : `https://admin-dev.salvex.com/admin/_listings/views/_full.cfm?aucID=${auctionId}`
              }
              target="_blank"
              class="page-intro-item d-flex align-items-center justify-content-center ptm-green-bg"
            >
              <p>Old Admin Panel</p>
            </a>
          </div>
        </div>
      </div>
      <OverallDetailModal
        show={showOverallDetail}
        onHide={toggleOverall}
        auctionId={auctionId}
        data={overallData}
        setData={setOverallData}
        loading={loading}
        handleUpdate={handleUpdateOverall}
        conditionOptions={conditionOptions}
        categoryOptions={categoryOptions}
        manufactureOptions={manufactureOptions}
        auctionTypeOptions={auctionTypeOptions}
        countryOptions={countryOptions}
        stateOptions={stateOptions}
        getAuctionType={props?.getAuctionType}
        getCountries={props?.getCountries}
        getStates={props?.getStates}
        primaryCategory={primaryCategory}
        setPrimaryCategory={setPrimaryCategory}
        select={select}
        setSelect={setSelect}
        categoryDropdown={categoryDropdown}
        categoryDropDownData={categoryDropDownData}
        setCategoryDropdown={setCategoryDropdown}
        checkList={checkList}
        setCheckList={setCheckList}
        subCat={subCat}
        setSubCat={setSubCat}
        typeCondition={typeCondition}
        errors={errors}
        getLocSec={props?.getMoreDetails}
        updateLocSec={props?.updateLocSec}
        locationDetails={locationDetails}
      />
      <UploadImages
        uploadImageModal={uploadImageModal}
        setUploadImageModal={setUploadImageModal}
        listingImages={data}
        setSelectedImage={setSelectedImage}
        onChangeImageUpload={onChangeImageUpload}
        setOptionType={setOptionType}
        setOptionTypeId={setOptionTypeId}
        setTitle={setTitle}
        title={title}
        handleSaveTitle={handleSaveTitle}
        optionType={optionType}
        selectedImage={selectedImage}
        toggleUploadImageModal={toggleUploadImageModal}
        optionTypeId={optionTypeId}
        handleImageUploader={handleImageUploader}
        imageUploaded={imageUploaded}
        deleteHandler={deleteHandler}
        progress={progress}
        loadingMain={false}
        loadingUpload={loadingUpload}
        loadingDelete={loadingDelete}
        loadingTitleUpdate={loadingTitleUpdate}
        setEditedFile={setEditedFile}
        handleEditImageUploader={handleEditImageUploader}
        setImageUploaded={setImageUploaded}
        handlePrimaryPicture={handlePrimaryPicture}
        setProgress={setProgress}
        toggleImageGalleryModal={toggleImageGalleryModal}
        setStartIndex={setStartIndex}
        primaryPicture={primaryPicture}
        componentRef={componentRef}
      />
      <ImageLargeModal
        show={imageGalleryModal}
        onHide={toggleImageGalleryModal}
        items={imgData}
        startIndex={startIndex}
        title={listingDetail && listingDetail.detail && listingDetail.detail.title}
      />
      <DeleteModal
        optionType={optionType}
        selectedImage={selectedImage}
        setOptionType={setOptionType}
        deleteHandler={deleteHandler}
        uploadImageModal={uploadImageModal}
        loadingDelete={loadingDelete}
      />
      <RemoveDonationModal
        show={donationModal}
        onHide={toggleDonationModal}
        loadingDelete={loading}
        deleteHandler={handleDonationStatus}
      />
      <ListingTitleComponent
        show={showTitle}
        onHide={toggleShowTitle}
        setTitle={setListingTitle}
        title={listingTitle}
        handleChangeTitle={handleChangeTitle}
        loading={loading}
        titleType={titleType}
      />
      <PendingTasks
        show={showPendingTasks}
        onHide={togglePendingTaks}
        refStart={refStart}
        refStop={refStop}
        auctionId={auctionId}
        data={props?.getTasks?.pendingTasks?.data}
        getPendingTasks={props?.getPendingTasks}
        updateTaskStatus={props?.updateTaskStatus}
        deleteTask={props?.deleteTask}
        getProductCount={props?.getProductCount}
      />
      {showConfirmDuplicate && (
        <ConfirmModal
          show={showConfirmDuplicate}
          onHide={() => setShowConfirmDuplicate(!showConfirmDuplicate)}
          confirmHandler={handleDuplicate}
          loading={loading}
          message={"Are you sure you want to duplicate this listing?"}
        />
      )}
      {deleteListing && (
        <DeleteListing
          show={deleteListing}
          onHide={() => setDeleteListing((prev) => !prev)}
          deleteMessage={"Are you sure you want to delete this Listing? THIS ACTION CANNOT BE REVERSED."}
          deleteHandler={deleteListingHandler}
          loadingDelete={loading}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateTitle: (id, data, callback) => dispatch(updateTitle(id, data, callback)),
  detailListings: (params, callback) => dispatch(detailListing(params, callback)),
  setMoreDetails: (data) => dispatch(setMoreDetailsModal(data)),
  setLocation: (data) => dispatch(setLocationModal(data)),
  setStrategicTeam: (data) => dispatch(setStrategicModal(data)),
  setConditionModal: (data) => dispatch(setConditionModal(data)),
  setPricingModal: (data) => dispatch(setQtyPricingModal(data)),
  setCategoryModal: (data) => dispatch(setCategoryModal(data)),
  setPrivateDocModal: (data) => dispatch(setPrivateDocModal(data)),
  setPublicDocModal: (data) => dispatch(setPublicDocModal(data)),
  setDescriptionModal: (data) => dispatch(setDescription(data)),
  setAiDescp: (data) => dispatch(setAIDescription(data)),
  setAiMfr: (data) => dispatch(setShowAIMfr(data)),
  setAiCarbon: (data) => dispatch(setShowAICarbon(data)),
  setAiLead: (data) => dispatch(setAILead(data)),
  setShowFeatured: (data) => dispatch(setFeaturedNotes(data)),
  getAuctionChats: (id, callback) => dispatch(loadAuctionChat(id, callback)),
  getOverallDetail: (id, callback) => dispatch(getOverallDetail(id, callback)),
  updateOverall: (id, data, callback) => dispatch(updateOverall(id, data, callback)),
  getAuctionType: (callback) => dispatch(getAuctionType(callback)),
  getCountries: (callback) => dispatch(getCountries(callback)),
  getProductLocation: (id, callback) => dispatch(getProductLocation(id, callback)),
  getPriceInfo: (id, callback) => dispatch(getPriceInfo(id, callback)),
  productCondition: (id, callback) => dispatch(productCondition(id, callback)),
  getCategoryPreferences: (id, callback) => dispatch(getCategoryPreferenaces(id, callback)),
  getMoreDetails: (id, callback) => dispatch(getMoreDetails(id, callback)),
  getSellerMotivation: (id, callback) => dispatch(getSellerMotivation(id, callback)),
  getOtherDescp: (id, callback) => dispatch(getOtherDescp(id, callback)),
  productDetails: (id, callback) => dispatch(productDetails(id, callback)),
  saveAuctionNote: (id, data, callback) => dispatch(saveAuctionNote(id, data, callback)),
  getPendingTasks: (params, callback) => dispatch(getPendingTasks(params, callback)),
  updateTaskStatus: (params, data, callback) => dispatch(updateTaskStatus(params, data, callback)),
  deleteTask: (params, taskId, callback) => dispatch(deleteTask(params, taskId, callback)),
  getProductCount: (id, callback) => dispatch(getProductCount(id, callback)),
  getCommentsAndNotes: (id, callback) => dispatch(getCommentsAndNotes(id, callback)),

  loadMassages: (userId, auctionId, callback) => dispatch(loadChatMassages(userId, auctionId, callback)),
  updateLocSec: (id, data, callback) => dispatch(updateLocationSecurity(id, data, callback)),
  getBidLog: (params, callback) => dispatch(getBidLog(params, callback)),
  updateDonationStatus: (id, data, callback) => dispatch(updateDonationStatus(id, data, callback)),
  getStates: (callback) => dispatch(getStates(callback)),
  duplicateListing: (id, data, callback) => dispatch(duplicateListing(id, data, callback)),
  deleteAuction: (id, data, callback) => dispatch(deleteAuction(id, callback)),
});

const mapStateToProps = (state) => ({
  getListings: getListings(state),
  aviationModel: getListings(state)?.aviationModel,
  getChatList: getChats(state),
  getListingProductDetail: getListingProductDetail(state),
  productLocationDetails: productLocationDetails(state),
  getStrategicTeam: getStrategicTeam(state),
  primarySellerData: primarySellerData(state),
  getMisc: getMisc(state),
  getLatestActivity: getLatestActivity(state),
  getTasks: getTasks(state),
  ei: ei(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(TitleBar);
