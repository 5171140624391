import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import bellSound from "../include/sound/bell.mp3";
import useWebSocket, { ReadyState } from "react-use-websocket";

//Images
import logo from "../include/images/logo.svg";
import searchIcon from "../include/images/search.svg";
import notificationIcon from "../include/images/message-circle.svg";
import {
  checkToken,
  clearAllToken,
  getProfile,
  setLogOutRedirect,
  setRedirectUrl,
} from "../utils.js/localStorageServices";
import { getChats, loadChatList, updateUnreadCount } from "../store/chats";
import { connect } from "react-redux";
import ScrollButton from "../common/scrollTop/scrollTop";
import { questionDetails } from "../store/questions";

const Header = (props) => {
  const [search, setSearch] = useState("");
  const history = useHistory();
  const [unreadCount, setUnreadCount] = React.useState(0);

  useEffect(() => {
    if (!checkToken()) {
      const a = window.location.href;
      setRedirectUrl(a);
      // props.history.push("/signin");
    }
  }, []);

  useEffect(() => {
    if (checkToken()) {
      let adminUnreadCount = 0;

      if (props.getChats.chats) {
        props.getChats.chats.forEach((chat) => {
          if (chat.adminUnreadCount) adminUnreadCount = adminUnreadCount + chat.adminUnreadCount;
        });
        setUnreadCount(adminUnreadCount);
      }
    }
  }, [props.getChats.chats]);

  const member = getProfile();

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `${process.env.REACT_APP_WS_URL}?token=${btoa(`${member?.user_id}:admin`)}`,
    { shouldReconnect: (closeEvent) => true },
  );

  useEffect(() => {
    if (lastMessage !== null && checkToken()) {
      const data = JSON.parse(lastMessage.data);
      if (data?.type === "message") {
        const audio = new Audio(bellSound);
        audio.play();
        const mess = data.data;
        props.updateUnreadCount(data?.data);
        //push to list
      } else {
        props.loadChat(data?.user_id, (res) => {});
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    if (checkToken()) {
      props.loadChat(member?.user_id);
    }
  }, []);
  const unreadQuestionsCount = props?.questionDetails?.allUnreadCount?.data;

  return (
    <>
      <header id="header">
        <nav class="navbar navbar-expand-xl">
          <div class="container-fluid">
            <div class="nav-inside d-flex align-items-center justify-content-between">
              <a class="navbar-brand" href="javascript:void(0)">
                <img src={logo} alt="" className="cursor-pointer" onClick={() => history.push("/")} />
              </a>
              <button
                class="navbar-toggler collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mainNav"
                aria-controls="mainNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="mainNav" style={{ marginRight: "15px" }}>
                <div class="navbar-inside w-100 d-flex align-items-center header-top-bar">
                  <div class="header-search-box position-relative" style={{ marginLeft: "auto" }}>
                    <input
                      class="w-100"
                      type="search"
                      placeholder="Enter Auction ID or Keywords"
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                      onKeyDown={(k) => {
                        if (k.key === "Enter") {
                          history.push("/", { search });
                        }
                      }}
                    />
                    <img class="search__icon" src={searchIcon} alt="" onClick={() => history.push("/", { search })} />
                  </div>
                </div>
              </div>
              <div class="header-right d-flex flex-wrap">
                <div class="header-icon-item" onClick={() => history.push("/chats")}>
                  {/* Notification to be included in future */}
                  <div class="header-notify-box w-100 h-100 position-relative">
                    {unreadQuestionsCount ? <span class="notify-count">{unreadQuestionsCount}</span> : ""}
                    <img src={notificationIcon} alt="" />
                  </div>
                </div>
                <Dropdown bsPrefix="header-name-box fw-medium dropdown cursor-pointer">
                  <Dropdown.Toggle as="span" id="dropdown-basic" className="dropdown-toggle">
                    {" "}
                    Welcome, {member && member.name01}!
                  </Dropdown.Toggle>

                  <Dropdown.Menu bsPrefix="dropdown-menu dropdown-menu-end">
                    <Dropdown.Item
                      href="/signin"
                      onClick={() => {
                        const a = window.location.href;
                        setLogOutRedirect(a);
                        clearAllToken();
                      }}
                    >
                      Sign Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <ScrollButton />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadChat: (userId, callback) => dispatch(loadChatList(userId, callback)),
  updateUnreadCount: (data) => dispatch(updateUnreadCount(data)),
});

const mapStateToProps = (state) => ({
  getChats: getChats(state),
  questionDetails: questionDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Header));
